function Title({ word }) {
  const title = () => (
    <div className="relative flex justify-center stats-font text-center ">
      <span className="absolute fontRed z-20 font-extrabold sm:text-3xl md:sm:text-4xl lg:text-5xl text-white">
        {word}
      </span>
      <span
        data-content={`${word}`}
        className="customShadow z-10 font-extrabold sm:text-3xl md:sm:text-4xl lg:text-5xl select-none"
      >
        {word}
      </span>
    </div>
  );

  return <div>{title()}</div>;
}

export default Title;
