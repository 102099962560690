import parse from "html-react-parser";

const Location = ({ location }) => {
  return (
    <div className="location sm:w-[300px] sm:h-[400px] lg:w-[500px] lg:h-[500px] justify-center items-center text-center font-Poppins flex sm:flex-col rounded-md relative">
      <a
        target="_blank"
        className="text-cyan-400 font-bold hover:underline transition-all mb-2"
        href={`${location?.link}`}
      >
        {location?.name}
      </a>
      {parse(`${location?.embed}`)}
      <div className="mt-4 p-2">
        <a
          target="_blank"
          className="text-cyan-400 font-bold hover:underline transition-all"
          href={`${location?.link}`}
        >
          {location?.address}
        </a>
        {location?.desc !== "" && (
          <div className="text-white font-bold">Note : {location?.desc}</div>
        )}
      </div>
    </div>
  );
};

export default Location;
