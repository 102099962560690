import { useState, useEffect, useContext } from "react";
import Title from "./component/Title";
import Location from "./component/Location";
import GlobalContext from "./GlobalContext";
import Axios from "axios";
import { useParams } from "react-router-dom";

const LocationPage = () => {
  const { id } = useParams();
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [location, setLocation] = useState(null);

  Axios.defaults.withCredentials = true;

  const fetchLocation = () => {
    Axios.get(url + "/locations/" + id).then((response) => {
      setLocation(response.data);
    });
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  return (
    <div className="min-h-screen">
      {location && (
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="Location"></Title>
          </div>

          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              My Location
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div className="w-full h-full flex justify-center ">
              <div className="sm:max-w-[600px] lg:max-w-[1200px] flex overflow-x-auto gap-10 scrollbar-thin scrollbar-thumb-slate-500">
                <Location location={location}></Location>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationPage;
