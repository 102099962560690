import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from '@mui/material/TableFooter';
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Moment from "react-moment";
import Button from '@mui/material/Button';
import _ from 'lodash';

import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  ColumnDef,
  flexRender,ColumnSizing
} from "@tanstack/react-table";

type PlayerGame = {
    _id:string,
    playerID:{
        _id:string,
        firstname:string,
        lastname:string
    },
    gameID:{
        _id:string
    },
    teamID:{
      _id:string,
      name:string
    },
    pts:number,
    fgm:number,
    threes:number,
    twos:number,
    freeThrows:number,
    freeThrowsAttempts:number,
    fouls:number,
    techs:number,
    unsportmanlike:number,
    bestDefender:number,
    win:number,
    ghost:Boolean
  
};

const GamesList = ({ onData, prop , playerGames}) => {

  const data = playerGames;

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo<ColumnDef<PlayerGame>[]>(
    () => [
      
        {
            header: "Info",
            columns: [
                {
                    header: "Full Name",
                    accessorFn: (row) =>
                      `${row.playerID.firstname} ${row.playerID.lastname}`,
                    cell: (info) => (
                      <Button variant="contained" color="inherit" sx={{bgcolor:"black",width:"100%", color:"white", fontWeight:"600", ":hover":{bgcolor:"#bf0202"}}} href={`/players/${info.row.original.playerID._id}`}>
                        {info.getValue()}
                      </Button>
                    ),
                    footer: info => (<div>Total</div>),
                    sortDescFirst: true,
                  },
                  {
                    id: "_id",
                    header: "⭐",
                    accessorFn: (row) => `${row.playerID._id}`,
                    cell: (info) => (
                      <button onClick={()=>{onData(true); prop(info.getValue())}}>
                        <RememberMeIcon />
                      </button>
                    ),
                    footer: info => (<div></div>),
                    enableSorting: false,
                    enableColumnFilter: false,
                  },
            ],
          },
          {
            header: "Stats",
            columns: [
              {
                header: "PTS",
                accessorKey: "pts",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                footer: info => <span>{_.sum(_.map(data, d => d.pts))}</span>,
              },
              {
                header: "FGM",
                accessorKey: "fgm",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                footer: info => <span>{_.sum(_.map(data, d => d.fgm))}</span>,
              },
              {
                header: "3PM",
                accessorKey: "threes",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                footer: info => <span>{_.sum(_.map(data, d => d.threes))}</span>,
              },
              {
                header: "FTM",
                accessorKey: "freeThrows",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                footer: info => <span>{_.sum(_.map(data, d => d.freeThrows))}</span>,
              },
              {
                header: "FTA",
                accessorKey: "freeThrowsAttempts",
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                footer: info => <span>{_.sum(_.map(data, d => d.freeThrowsAttempts))}</span>,
              },
              {
                header: "FT%",
                accessorFn: (row) => `${row.freeThrowsAttempts > 0?  (row.freeThrows / row.freeThrowsAttempts)*100 : 0}`,
                cell: (info) => (
                  <span>{Number(info.getValue()).toFixed(1)}%</span>
                ),
                enableColumnFilter: false,
                sortDescFirst: true,
                footer: info => <span>{(_.sum(_.map(data, d => d.freeThrowsAttempts > 0 ?  (d.freeThrows / d.freeThrowsAttempts)*100 : 0))).toFixed(1)}%</span>,
              },
              {
                header: "Fouls",
                accessorFn: (row) => `${row.fouls}`,
                cell: (info) => (
                  <span>{info.getValue()}</span>
                ),
                enableColumnFilter: false,
                sortDescFirst: true,
                footer: info => <span>{_.sum(_.map(data, d => d.fouls))}</span>,
              },
            ],
          },
    ],
    []
  );

  function LocalTable({
    data,
    columns,
  }: {
    data: PlayerGame[];
    columns: ColumnDef<PlayerGame>[];
  }) {
    const table = useReactTable({
      data,
      columns,
      // Pipeline
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel()
      //
    });

    return (
      <Box sx={{ width: "100%" }}>

        <TableContainer>
          <div className="boxbox">
            <Table>
              <TableHead >
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow  key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableCell
                          key={header.id}
                          colSpan={header.colSpan}
                          sx={{ fontFamily: "Poppins", fontWeight: "bold", width: header.getSize }}
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none flex-col flex align-center items-center sortLabel"
                                    : "inline-block flex justify-center ",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}{" "}
                                {header.column.getCanSort() &&
                                  header.column.getIsSorted() !== "asc" &&
                                  header.column.getIsSorted() !== "desc" && (
                                    <div className={`inline-block`}>
                                      <ExpandMoreSharpIcon
                                        className={` inline-block sort sortHover`}
                                      />
                                    </div>
                                  )}
                                {(header.column.getIsSorted() === "asc" ||
                                  header.column.getIsSorted() === "desc") && (
                                  <div className={`inline-block`}>
                                    <ExpandMoreSharpIcon
                                      className={` inline-block sort ${
                                        header.column.getIsSorted() === "asc"
                                          ? "sortFlip"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody  component={Paper}>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id} id={row.getValue("_id")}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            sx={{ textAlign: "center", fontFamily: "Poppins",  padding:"4px"}}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter className="bgRed">
          {table.getFooterGroups().map(footerGroup => (
            <TableRow  key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                header.column.columnDef.footer ?
                <TableCell key={header.id} sx={{ textAlign: "center", fontFamily: "Poppins",  padding:"10px", fontWeight: "bold", fontSize:"14px", color:"white"}}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </TableCell>:null
              ))}
            </TableRow>
          ))}
        </TableFooter>
        </Table>
          </div>
        </TableContainer>
      </Box>
    );
  }

  return (
    <>
      <LocalTable {...{ data, columns }} />
    </>
  );
};

export default GamesList;