import { useState, useEffect, useContext } from "react";
import Title from "./component/Title";
import GlobalContext from "./GlobalContext";
import Axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbar } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import { FormHelperText, OutlinedInput } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Rules from "./component/Rules";
import TermsAndConditions from "./component/TermsAndConditions";
import Confirmation from "./component/Confirmation";
import RegisterEmail from "./component/emails/registrations";
import { render } from "@react-email/render";
import RegisterEmailReceive from "./component/emails/registrationsReception";

const dayjs = require("dayjs");

function Registrations() {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [seasonDivisionsData, setSeasonDivisionsData] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [loading, setLoading] = useState(false);
  const [birth, setBirth] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState("");
  const [emPhone, setEmPhone] = useState("");
  const [jersey, setJersey] = useState("");
  const [status, setStatus] = useState("");
  const [newPlayer, setNewPlayer] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const [matureError, setMatureError] = useState(null);
  const [agreement, setAgreement] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [seasonID, setSeasonID] = useState("");
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState({
    newPlayer: null,
    divisionID: "",
    seasonID: "",
    firstname: "",
    lastname: "",
    birth: "",
    gender: "",
    email: "",
    postalCode: "",
    phone: "",
    work: "",
    status: "",
    jersey: "",
    team: "",
    emName: "",
    emRelation: "",
    emPhone: "",
    termsAgreed: false,
  });

  Axios.defaults.withCredentials = true;

  const emailHtml = render(<RegisterEmail registration={formData} />);
  const receiveEmailHtml = render(
    <RegisterEmailReceive registration={formData} />
  );

  Axios.defaults.withCredentials = true;

  const fetchRegistrationsSeason = async () => {
    await Axios.get(url + "/seasons/registrations/season")
      .then(async (response) => {
        if (response.data) {
          formData.seasonID = response.data._id;
          setSeasonID(response.data._id);
          await Axios.get(url + "/seasonDivisions/season/" + response.data._id)
            .then(async (response) => {
              setSeasonDivisionsData(response.data);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleEmailChange = async (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);

    handleValidation();

    setTimeout(() => {
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(inputValue)) {
        setError(true);
      } else {
        setError(false);
      }
    }, 1200); // Simulating a 500ms debounce
  };

  const handleValidation = () => {
    setLoading(true);

    // Simulate an asynchronous validation process
    setTimeout(() => {
      setLoading(false);
    }, 1500); // Simulating a 2-second validation delay
  };

  useEffect(() => {
    fetchRegistrationsSeason();
  }, []);

  const handlePhoneNumberChange = (event) => {
    // Remove non-numeric characters from input
    const rawInput = event.target.value.replace(/\D/g, "");
    const name = event.target.name;

    // Ensure only the first 10 digits are considered
    const trimmedInput = rawInput.slice(0, 10);

    // Format phone number with spaces after the third and sixth digit
    let formattedNumber = "";
    for (let i = 0; i < trimmedInput.length; i++) {
      if (i === 3 || i === 6) {
        formattedNumber += " ";
      }
      formattedNumber += trimmedInput[i];
    }
    if (name === "phone") {
      setPhone(formattedNumber);
    } else if (name === "emPhone") {
      setEmPhone(formattedNumber);
    }
  };

  useEffect(() => {
    if (birth) {
      setErrorDate({});
    }
  }, [birth]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your logic here to handle the form data (e.g., send it to a server)
    // Reset the form fields
    if (birth === null || birth === "") {
      setErrorDate({
        helperText: "Input is required",
        error: true,
      });
    } else {
      setErrorDate({});
      var startDate = new Date(birth);
      var currentDate = new Date();
      const mature =
        (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
          (currentDate.getMonth() - startDate.getMonth()) >=
        215;

      if (!mature) {
        setMatureError({
          helperText: "Must be over 18.",
          error: true,
        });
      } else {
        setMatureError({});
        formData.birth = birth;
        formData.termsAgreed = agreement;
        setLoadingForm(true);
        postRegister(formData);
        setTimeout(() => {
          setLoadingForm(false);
        }, 3000);
      }
    }
  };

  const sendEmail = async (mailOptions) => {
    await Axios.post(url + "/emails", { mailOptions }).then((response) => {});
  };

  const receiveEmail = async (mailOptions) => {
    await Axios.post(url + "/emails", { mailOptions }).then((response) => {});
  };

  const postRegister = async (data) => {
    await Axios.post(url + "/registrations", data)
      .then(async (response) => {
        const mailOptions = {
          from: "info@afterworkleagues.com",
          to: data.email,
          subject: "Registration request Afterwork - ",
          html: emailHtml,
        };
        sendEmail(mailOptions);

        const mailOptions2 = {
          from: "info@afterworkleagues.com",
          to: "info@afterworkleagues.com",
          subject: "Registration request Afterwork - ",
          html: receiveEmailHtml,
        };
        receiveEmail(mailOptions2);
        setCompleted(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Update the corresponding form field in the state
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const handleAgreement = (value) => {
    setAgreement(value);
  };

  return (
    <div className="container ">
      <div className="bgRed fade-in sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
        <Title word="Join Us!" />
      </div>
      {seasonID === "" ? (
        <div className="content fade-in my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:px-0 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <Title word={"Comeback"}></Title>
          <Title word={"soon"}></Title>
        </div>
      ) : loadingForm ? (
        <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
          <CircularProgress sx={{ color: "white" }}></CircularProgress>
        </div>
      ) : (
        <div className="content fade-in my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:px-0 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
            {seasonDivisionsData[0]?.seasonID.name}
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
          </div>
          {completed ? (
            <Confirmation email={formData.email}></Confirmation>
          ) : (
            <div className="flex sm:flex-col lg:flex-row justify-between w-full p-4 h-fit border-2 border-white">
              <form
                onSubmit={handleSubmit}
                className="flex sm:flex-col lg:flex-row m-1 p-4 w-full gap-8"
              >
                <div className="flex flex-col sm:w-full lg:w-1/2 gap-4 font-Poppins">
                  <h1 className="sm:mx-1 m-3 text-lg">PLAYER INFORMATION</h1>
                  <FormControl>
                    <FormLabel required>New Player?</FormLabel>
                    <RadioGroup
                      row
                      name="newPlayer"
                      value={newPlayer}
                      onChange={(e) => {
                        setNewPlayer(e.target.value);
                        handleChange(e);
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio required={true} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio required={true} />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <InputLabel required id="division-label">
                      Division
                    </InputLabel>
                    <Select
                      labelId="division-label"
                      label={"Division"}
                      onChange={(e) => {
                        setSelectedDivision(e.target.value);
                        handleChange(e);
                      }}
                      value={selectedDivision}
                      name="divisionID"
                      required
                    >
                      {seasonDivisionsData?.map((seasonDivision, index) => (
                        <MenuItem
                          value={seasonDivision.divisionID._id}
                          key={index}
                        >
                          {seasonDivision.divisionID.titleEn} (
                          {seasonDivision.divisionID.price}$)
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    id="standard-basic"
                    label="First Name"
                    name="firstname"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    id="standard-basic"
                    label="Last Name"
                    name="lastname"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                  <div className="flex gap-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        onChange={(e) => {
                          // @ts-ignore
                          setBirth(e?.$d ? e.$d : null);
                        }}
                        maxDate={dayjs(Date.now())}
                        slotProps={{
                          textField: {
                            required: true,
                            label: "Birthdate",
                            name: "birth",
                            ...errorDate,
                          },
                        }}
                      />
                    </LocalizationProvider>

                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel required id="Gender-label">
                        Sex At Birth
                      </InputLabel>
                      <Select
                        labelId="Gender-label"
                        label={"*Sex At Birth"}
                        value={gender}
                        onChange={(e) => {
                          setGender(e.target.value);
                          handleChange(e);
                        }}
                        required
                        name="gender"
                      >
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <FormControl>
                    <InputLabel id="email-label" required>
                      Email
                    </InputLabel>
                    <OutlinedInput
                      id="standard-basic"
                      label="Email"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        handleEmailChange(e);
                        handleChange(e);
                      }}
                      error={Boolean(error) && email !== ""}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          {email === "" ? (
                            <div></div>
                          ) : loading ? (
                            <CircularProgress size={20} />
                          ) : error ? (
                            <CloseIcon color="error"></CloseIcon>
                          ) : (
                            <DoneIcon></DoneIcon>
                          )}
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <TextField
                    id="standard-basic"
                    label="Postal Code"
                    variant="outlined"
                    name="postalCode"
                    onChange={handleChange}
                  />
                  <TextField
                    id="standard-basic"
                    label="Phone Number"
                    value={phone}
                    name="phone"
                    variant="outlined"
                    onChange={(e) => {
                      handlePhoneNumberChange(e);
                      handleChange(e);
                    }}
                    required
                  ></TextField>
                  <TextField
                    id="standard-basic"
                    label="Work Area"
                    variant="outlined"
                    name="work"
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col sm:w-full lg:w-1/2 gap-4 font-Poppins border-">
                  <h1 className="lg:m-2 sm:hidden lg:block select-none text-transparent text-lg">
                    {" "}
                    no title
                  </h1>
                  <FormControl>
                    <FormLabel required>Status</FormLabel>
                    <RadioGroup
                      row
                      name="status"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        handleChange(e);
                      }}
                    >
                      <FormControlLabel
                        value="Alone"
                        control={<Radio required={true} />}
                        label="Alone"
                      />
                      <FormControlLabel
                        value="Part of a team"
                        control={<Radio required={true} />}
                        label="Part of a team"
                      />
                    </RadioGroup>
                  </FormControl>
                  {status === "Part of a team" && (
                    <TextField
                      className="fade-in"
                      id="standard-basic"
                      label="Team Name"
                      name="team"
                      onChange={handleChange}
                      variant="outlined"
                      required
                    />
                  )}

                  <FormControl>
                    <InputLabel id="jersey-label">Jersey Size *</InputLabel>
                    <Select
                      labelId="jersey-label"
                      label={"Jersey Size *"}
                      onChange={(e) => {
                        setJersey(e.target.value);
                        handleChange(e);
                      }}
                      value={jersey}
                      name="jersey"
                      required
                    >
                      <MenuItem value={"(S)"}>(S)</MenuItem>
                      <MenuItem value={"(M)"}>(M)</MenuItem>
                      <MenuItem value={"(L)"}>(L)</MenuItem>
                      <MenuItem value={"(XL)"}>(XL)</MenuItem>
                      <MenuItem value={"(2XL)"}>(2XL)</MenuItem>
                      <MenuItem value={"(3XL)"}>(3XL)</MenuItem>
                      <MenuItem value={"(4XL)"}>(4XL)</MenuItem>
                    </Select>
                  </FormControl>
                  <h1 className="m-[14px] text-lg">
                    MEDICAL/EMERGENCY CONTACT INFORMATION
                  </h1>

                  <TextField
                    id="standard-basic"
                    label="Full Name"
                    name="emName"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    id="standard-basic"
                    label="Relationship To Player"
                    name="emRelation"
                    variant="outlined"
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    id="standard-basic"
                    label="Phone Number"
                    name="emPhone"
                    value={emPhone}
                    variant="outlined"
                    onChange={(e) => {
                      handleChange(e);
                      handlePhoneNumberChange(e);
                    }}
                    required
                  />
                  <div className="p-1  border-[4px] border-black bg-red-500">
                    <div className="sm:py-1 lg:py-4 sm:px-1 lg:px-8 border-[2px] border-black lg:text-left sm:text-center text-sm bg-black">
                      <h1 className="font-semibold px-2">Important Note</h1>
                      <p className="sm:px-2 lg:px-4 py-2">
                        PAIEMENT MUST BE DONE BY INTERAC TRANSFER AT
                        Info@afterworkleagues.com
                      </p>
                      <p className="px-4">Question: League name</p>
                      <p className="px-4">Answer: Afterwork</p>
                    </div>
                  </div>

                  <div className="flex sm:flex-col lg:flex-row p-4 justify-center items-center gap-8">
                    <Rules />
                    <TermsAndConditions agreed={handleAgreement} />
                  </div>
                  <p className="text-center text-red-600 font-bold">
                    {errorDate?.helperText && "Birthdate is Required *"}
                    {matureError?.helperText}
                  </p>

                  <div className="flex justify-center">
                    <Button
                      sx={{
                        width: "80%",
                        fontWeight: 800,
                        border: "solid 4px black",
                        background: "white",
                        color: "black",
                        fontFamily: "Poppins",
                        "&:hover": { backgroundColor: "#e0e0e0" },
                      }}
                      type="submit"
                      variant="contained"
                      size="large"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Registrations;
