import {
  Body,
  Link,
  Container,
  Column,
  Head,
  Heading,
  Html,
  Img,
  Row,
  Section,
  Text,Font, Preview
} from '@react-email/components';
import * as React from 'react';




export const ContactEmail = ({formData}) => {

  const formattedDate = new Intl.DateTimeFormat('en', {
    dateStyle: 'long',
    timeStyle: 'short',
  }).format(Date.now());

  return (
    <Html style={{background: '#232323', width:"100%"}}>
      <Head ><Font
        fontFamily="Poppins"
        fallbackFontFamily="Arial"
        webFont={{
          url: "https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
          format: "woff2",
        }}
        fontWeight={400}
        fontStyle="normal"
      /></Head>
      <Body style={{background: '#232323', width:"100%", fontFamily:"Arial"}}>
        <Container style={{background:"#232323"}}>
          <Section style={{background:"#232323", paddingBottom:"20px"}}>
            
          </Section>
          <Section style={{ border:"solid 4px #ab0000",background:"black", paddingBottom:"20px"}}>
            
            <Preview>Afterwork</Preview>
          <Section style={content}>
          <Text style={{textAlign:"end",padding: "0px 8px"}}>
                  <b>Date: </b>
                  {formattedDate}
                </Text>
            <Row style={{ ...boxInfos, paddingBottom: '0' }}>
              <Column>
                <Heading
                  style={{
                    fontSize: 32,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  Contact Form,
                </Heading>
                <Text style={{ ...paragraph, marginTop: -5 }}>
                  <b>Name : {formData?.name}</b>
                </Text>
                <Text style={{ ...paragraph, marginTop: -5 }}>
                  <b>Phone : {formData?.phone}</b>
                </Text>
                <Text style={{ ...paragraph, marginTop: -5 }}>
                  <b>Email : {formData?.email}</b>
                </Text>
                <Text style={{ ...paragraph, marginTop: -5 }}>
                  <b>Subject : {formData?.subject}</b>
                </Text>
                <Text style={{ ...paragraph, marginTop: -5 }}>
                  <b>Message : {formData?.message}</b>
                </Text>
              </Column>
            </Row>
            
            
          </Section><Section><Text style={{padding:"30px",textAlign: 'center',
            fontSize: 12,
            color: 'rgb(255,255,255, 0.7)',}}>More than a league.</Text>

          </Section>
          </Section>
          
        
          
        </Container>
      </Body>
    </Html>
  );
};

export default ContactEmail;

const paragraph = {
  fontSize: 16,
};

const logo = {
  borderBottom: "solid 2px #ab0000",
  background:"#232323",
  display:"flex",
  justifyContent:"center",
};




const content = {
  border: '1px solid rgb(0,0,0, 0.1)',
  borderRadius: '3px',
  background:"black",
  color:"white",
  overflow: 'hidden',
};

const boxInfos = {
  padding: '20px 40px',
};
