import { useState, useEffect, useContext } from "react";
import Title from "../component/Title";
import GlobalContext from "../GlobalContext";
import Axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";

function RegistrationsAdmin() {
  const [loginStatus, setLoginStatus] = useState(false);
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [seasonsData, setSeasonData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [registrationsData, setRegistrationsData] = useState([]);
  const [divisionPlayersData, setDivisionPlayersData] = useState([]);

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    fetchSeasons();
    Axios.get(url + "/users/login", {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setLoginStatus(response.data.LoggedIn ? true : false);
    });
  }, []);

  const fetchSeasons = async () => {
    await Axios.get(url + "/seasons")
      .then(async (response) => {
        const seasonID = response.data[0]._id;
        setSeasonData(response.data);
        setSelectedSeason(seasonID);
        fetchRegistrations(seasonID);
        fetchPlayers(seasonID);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchPlayers = async (seasonID) => {
    await Axios.get(url + "/players/season/" + seasonID)
      .then(async (response) => {
        setDivisionPlayersData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchRegistrations = async (seasonID) => {
    await Axios.get(url + "/registrations/season/" + seasonID)
      .then((response) => {
        setRegistrationsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSeasonChange = (e) => {
    setSelectedSeason(e.target.value);
    setSelectedSeason(e.target.value);
    fetchRegistrations(e.target.value);
    fetchPlayers(e.target.value);
  };

  return (
    <div>
      <div className="container">
        <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
          <Title word="Registrations" />
        </div>
        <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <div>
            <FormControl sx={{ m: 1, minWidth: 300 }}>
              <InputLabel id="season-label">Season</InputLabel>
              <Select
                labelId="season-label"
                label={"Season"}
                onChange={handleSeasonChange}
                value={selectedSeason}
              >
                {seasonsData.map((season, index) => (
                  <MenuItem value={season._id} key={index}>
                    {season.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
            Pending (
            {
              registrationsData.filter((registration) => {
                return (
                  registration.accepted === false &&
                  registration.refused === false
                );
              }).length
            }
            )
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
          </div>
          <div>
            {divisionPlayersData.map((divisionPlayers, index) =>
              registrationsData
                .filter((registration) => {
                  return (
                    registration.divisionID._id ===
                      divisionPlayers.divisionID &&
                    registration.accepted === false &&
                    registration.refused === false
                  );
                })
                .map((registration, index) => (
                  <div key={index}>
                    <RegisterRequest
                      registration={registration}
                      players={divisionPlayers}
                      refresh={fetchSeasons}
                    ></RegisterRequest>
                  </div>
                ))
            )}
          </div>

          <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
            Accepted (
            {
              registrationsData.filter((registration) => {
                return registration.accepted === true;
              }).length
            }
            )
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
          </div>
          <div>
            {divisionPlayersData.map((division, index) =>
              registrationsData
                .filter((registration) => {
                  return (
                    registration.divisionID._id === division.divisionID &&
                    registration.accepted === true
                  );
                })
                .map((registration, index) => (
                  <div key={index}>
                    <RegisterRequest
                      registration={registration}
                      refresh={fetchSeasons}
                    ></RegisterRequest>
                  </div>
                ))
            )}
          </div>
          <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
            Refused(
            {
              registrationsData.filter((registration) => {
                return (
                  registration.refused === true &&
                  registration.accepted === false
                );
              }).length
            }
            )
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
          </div>
          <div>
            {divisionPlayersData.map((divisionPlayers, index) =>
              registrationsData
                .filter((registration) => {
                  return (
                    registration.divisionID._id ===
                      divisionPlayers.divisionID &&
                    registration.refused === true &&
                    registration.accepted === false
                  );
                })
                .map((registration, index) => (
                  <div key={index}>
                    <RegisterRequest
                      registration={registration}
                      players={divisionPlayers}
                      refresh={fetchSeasons}
                    ></RegisterRequest>
                  </div>
                ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const RegisterRequest = ({ registration, players = null, refresh = null }) => {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [teamsData, setTeamsData] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [newPlayer, setNewPlayer] = useState(
    Boolean(registration.newPlayer) || false
  );

  Axios.defaults.withCredentials = true;

  const { enqueueSnackbar } = useSnackbar();

  const handleResponse = (msg, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  const fetchTeams = async (seasonID, divisionID) => {
    await Axios.get(
      url + "/teamSeasons/season/" + seasonID + "/division/" + divisionID
    )
      .then(async (response) => {
        setTeamsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const loadPlayers = () => {
    // @ts-ignore
    const playersData = players.players.map((player) => ({
      label: player.firstname + " " + player.lastname,
      value: player._id,
    }));

    setPlayersData(playersData);
  };

  useEffect(() => {
    fetchTeams(registration.seasonID, registration.divisionID._id);
    if (players !== null && players.players.length !== 0) {
      loadPlayers();
    }
  }, []);

  const handlePlayerSelect = (value) => {
    if (value !== null) {
      const selectedOption = playersData.find(
        (option) => option.value === value
      );
      setSelectedPlayer(selectedOption);
    } else setSelectedPlayer(null);
  };

  const FindMostSimilarObject = ({
    arrayOfObjects,
    targetObject,
    selected,
  }) => {
    const specifiedProperties = ["firstname", "lastname", "_id"];
    const findMostSimilar = (objects, target) => {
      let maxSimilarityCount = 0;
      let mostSimilarObject = null;

      objects.forEach((obj) => {
        let similarityCount = 0;
        let similarProperties = {};

        similarProperties.firstname = obj.firstname;
        similarProperties.lastname = obj.lastname;
        similarProperties._id = obj._id;
        // Compare all other properties
        for (const prop in target) {
          if (obj.hasOwnProperty(prop) && obj[prop] === target[prop]) {
            similarityCount++;
            similarProperties[prop] = obj[prop];
          }
        }

        if (similarityCount > maxSimilarityCount) {
          maxSimilarityCount = similarityCount;
          mostSimilarObject = similarProperties;
        }
      });

      return mostSimilarObject;
    };

    const mostSimilarObject = findMostSimilar(arrayOfObjects, targetObject);

    return (
      <div className="my-4">
        <div className="flex ">
          <button
            className="font-extrabold text-cyan-300 hover:text-white transition-all duration-500"
            onClick={(e) => {
              selected(mostSimilarObject._id);
            }}
          >
            Is this "{mostSimilarObject.firstname} {mostSimilarObject.lastname}
            "?
          </button>
        </div>
        Has Same
        <ul className="text-red-600 flex flex-row gap-2 max-w-[300px] flex-wrap">
          {Object.entries(mostSimilarObject)
            .filter(([key, value], index) => !specifiedProperties.includes(key))
            .map(([key, value], index) => (
              <li key={index}>
                <strong>{key}* </strong>
              </li>
            ))}
        </ul>
      </div>
    );
  };

  const handleAccept = async () => {
    await Axios.patch(url + "/registrations/accept/" + registration._id)
      .then(async (response) => {})
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleRefuse = async () => {
    await Axios.patch(url + "/registrations/refuse/" + registration._id)
      .then(async (response) => {
        handleResponse("Refused Player :(", "warning");
        refresh();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const postNewPlayer = async () => {
    await Axios.post(url + "/players/new", registration)
      .then((response) => {
        handleResponse("Succesfully Added New Player 🙂", "success");
        if (selectedTeam !== "0") {
          postPlayerTeam(response.data._id);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const postPlayerTeam = async (playerID) => {
    await Axios.post(url + "/playerteams", {
      playerID: playerID,
      seasonID: registration.seasonID,
      teamID: selectedTeam,
    })
      .then((response) => {})
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const patchPlayer = async () => {
    await Axios.patch(
      url + "/players/renew/" + selectedPlayer.value,
      registration
    )
      .then((response) => {
        handleResponse("Succesfully Renewed Player :)", "success");
        if (selectedTeam !== "0") {
          postPlayerTeam(selectedPlayer.value);
        }
        console.log(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleAcceptRequest = () => {
    if (selectedTeam === "" || (!newPlayer && selectedPlayer === null)) {
      handleResponse("Request not Complete!", "error");
    } else {
      if (!newPlayer) {
        patchPlayer();
      } else {
        postNewPlayer();
      }
      handleAccept();
      refresh();
    }
  };

  return (
    <div className="flex">
      <Accordion sx={{ border: "solid 1px white", width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontFamily={"Poppins"}>
            {registration.firstname} {registration.lastname} (
            {registration.divisionID.titleEn})
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Accordion sx={{ border: "none", m: 0 }}>
            <AccordionSummary
              sx={{
                background: "rgb(30 41 59 / var(--tw-bg-opacity));",
                border: "none",
                m: 0,
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                fontFamily={"Poppins"}
                color={"white"}
                fontWeight={"600"}
              >
                Player Informations
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <div className="w-full h-full bg-slate-700 p-8 text-white font-Poppins">
                <div className="grid grid-cols-3 gap-2 w-full font-extralight">
                  <div className="flex flex-col">
                    <h1 className="font-normal">Last Name</h1>
                    <p>{registration.lastname}</p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">First Name</h1>
                    <p>{registration.firstname}</p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">Gender</h1>
                    <p>{registration.gender}</p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">Jersey</h1>
                    <p>{registration.jersey}</p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">Phone</h1>
                    <p>{registration.phone}</p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">Postal Code</h1>
                    <p>{registration.postalCode}</p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">Birth</h1>
                    <p>
                      {new Date(registration.birth).toLocaleDateString("en-US")}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">Work</h1>
                    <p>{registration.work}</p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">Email</h1>
                    <p>{registration.email}</p>
                  </div>
                </div>
                <div className="w-full border-b-4 pt-4 border-white rounded-full"></div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: "none", m: 0 }}>
            <AccordionSummary
              sx={{
                background: "rgb(30 41 59 / var(--tw-bg-opacity));",
                border: "none",
                m: 0,
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            >
              <Typography
                fontFamily={"Poppins"}
                color={"white"}
                fontWeight={"600"}
              >
                Emergency Informations
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <div className="w-full h-full bg-slate-700 p-8 text-white font-Poppins">
                <div className="grid grid-cols-3 gap-2 w-full font-extralight">
                  <div className="flex flex-col">
                    <h1 className="font-normal">Name</h1>
                    <p>{registration.emName}</p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">Relation</h1>
                    <p>{registration.emRelation}</p>
                  </div>
                  <div className="flex flex-col">
                    <h1 className="font-normal">Phone</h1>
                    <p>{registration.emPhone}</p>
                  </div>
                </div>
                <div className="w-full border-b-4 pt-4 border-white rounded-full"></div>
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="w-full h-full bg-slate-700 p-8 text-white font-Poppins">
            <h1 className=" font-bold pt-4 pb-2">
              Terms and TermsAndConditions Agreed :{" "}
              {registration.termsAgreed ? "Yes" : "No"}
            </h1>
            <div className="w-full border-b-4 pt-4 border-white rounded-full"></div>
            <h1 className="text-lg font-bold py-4">Team</h1>
            <div className="grid grid-cols-3 font-extralight">
              <div className="flex flex-col">
                <h1 className="font-normal">Status</h1>
                <p>{registration.status}</p>
              </div>
              <div>
                {registration.status === "Part of a team" && (
                  <div className="flex flex-col">
                    <h1 className="font-normal">Team</h1>
                    <p>{registration.team}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex mt-4">
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel sx={{ color: "greenyellow" }} id="team-label">
                  Team
                </InputLabel>
                <Select
                  labelId="team-label"
                  label={"Team"}
                  onChange={(e) => setSelectedTeam(e.target.value)}
                  value={selectedTeam}
                >
                  <MenuItem value={"0"} key={0}>
                    No Team
                  </MenuItem>
                  {teamsData.map((team, index) => (
                    <MenuItem value={team.teamID._id} key={index + 1}>
                      {team.teamID.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-full border-b-4 pt-4 border-white rounded-full"></div>
            <h1 className="text-lg font-bold py-4">Player Profile</h1>
            <FormControl>
              <RadioGroup
                row
                className="grid grid-cols-3"
                name="status"
                value={newPlayer}
                onChange={(e) => {
                  setNewPlayer(e.target.value === "true");
                }}
              >
                <FormControlLabel
                  value={"false"}
                  control={
                    <Radio
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontFamily: "Poppins" }}>
                      Returning Player
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={"true"}
                  control={
                    <Radio
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ fontFamily: "Poppins" }}>
                      New Player
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
            {!newPlayer && (
              <div className="fade-in">
                <Autocomplete
                  id="tags-standard"
                  options={playersData}
                  value={selectedPlayer}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, selectedValue) =>
                    option.value === selectedValue.value
                  }
                  onChange={(event, value) => {
                    value !== null && handlePlayerSelect(value.value);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li
                        className="font-Poppins"
                        {...props}
                        key={option.value}
                      >
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                          }}
                        >
                          {registration.divisionID.titleEn} Players
                        </Typography>
                      }
                      sx={{
                        width: "300px",
                        borderRadius: "4px",
                      }}
                    />
                  )}
                />
                {players !== null && players.players.length !== 0 && (
                  <FindMostSimilarObject
                    // @ts-ignore
                    arrayOfObjects={players.players}
                    targetObject={registration}
                    selected={handlePlayerSelect}
                  />
                )}
              </div>
            )}
            {registration.accepted ? (
              <div></div>
            ) : registration.refused ? (
              <div>
                <div className="w-full border-b-4 py-4 border-white rounded-full"></div>
                <div className="mt-8 flex justify-end">
                  <Button
                    sx={{
                      width: "200px",
                      fontWeight: 800,
                      border: "solid 4px black",
                      background: "white",
                      color: "black",
                      fontFamily: "Poppins",
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                    className="sm-shadow"
                    type="submit"
                    variant="contained"
                    size="large"
                    onClick={handleAcceptRequest}
                  >
                    Accept
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full border-b-4 py-4 border-white rounded-full"></div>
                <div className="mt-8 flex justify-end gap-2">
                  <Button
                    sx={{
                      width: "200px",
                      fontWeight: 800,
                      border: "solid 4px black",
                      background: "white",
                      color: "black",
                      fontFamily: "Poppins",
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                    className="sm-shadow"
                    type="submit"
                    variant="contained"
                    size="large"
                    onClick={handleAcceptRequest}
                  >
                    Accept
                  </Button>{" "}
                  <Button
                    sx={{
                      width: "100px",
                      fontWeight: 800,
                      border: "solid 4px black",
                      background: "white",
                      color: "black",
                      fontFamily: "Poppins",
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                    className="sm-shadow"
                    type="submit"
                    variant="contained"
                    size="large"
                    onClick={handleRefuse}
                  >
                    Refuse
                  </Button>
                </div>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default RegistrationsAdmin;
