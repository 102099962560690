import {
  useState,
  useCallback,
  useEffect,
  useContext,
  memo,
  useMemo,
} from "react";
import Title from "../component/Title";
import Event from "../component/Event";
import GlobalContext from "../GlobalContext";
import Axios from "axios";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Modal from "@mui/material/Modal";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
const options = { month: "long", day: "numeric", year: "numeric" };
const options2 = { weekday: "short", month: "short", day: "numeric" };

const GamesAdmin = memo(() => {
  const [loginStatus, setLoginStatus] = useState(false);
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [seasonsData, setSeasonsData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [seasonData, setSeasonData] = useState(null);
  const [divisionsData, setDivisionsData] = useState([]);
  const [event, setEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [editedEvents, setEditedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [dots, setDots] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => !prevDots);
    }, 5000); // Change the interval as needed (e.g., switch every 1000 milliseconds)

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback((value) => {
    setOpen(true);
    setEvent(value);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setEvent(null);
  }, []);

  Axios.defaults.withCredentials = true;

  const fetchSeasons = useCallback(async () => {
    try {
      const response = await Axios.get(url + "/seasons");
      setSeasonsData(response.data);
      setSelectedSeason(response.data[0]?._id);
      setSeasonData(response.data[0]);
      fetchDivisions(response.data[0]._id);
      fetchEvents(response.data[0]._id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [url]);

  const fetchLocations = () => {
    Axios.get(url + "/locations").then((response) => {
      setLocations(response.data);

      fetchSeasons();
    });
  };

  const fetchDivisions = useCallback(
    async (seasonID) => {
      try {
        const response = await Axios.get(
          url + "/seasonDivisions/season/" + seasonID
        );
        const divisions = response.data.map((seasonDivision, index) => {
          return {
            _id: seasonDivision.divisionID._id,
            title: seasonDivision.divisionID.titleEn,
          };
        });
        setDivisionsData(divisions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [url]
  );

  const fetchEvents = async (seasonID) => {
    try {
      await Axios.get(url + "/games/season/" + seasonID).then((response) => {
        setEvents([]);
        setLoading(true);
        if (response.data.length > 0) {
          const savedEvents = JSON.parse(localStorage.getItem("savedEvents"));

          const events = response.data.map((event) => {
            //look into saved array if there is a copy of an event and .saved = copy
            const saved = savedEvents
              ? savedEvents.find((obj) => obj._id === event._id)
              : null;
            return {
              _id: event._id,
              title:
                event.type === "Game"
                  ? event.guessTeamID.name + " @" + event.localTeamID.name
                  : "Gala",
              date: event.date,
              time: event.time,
              divisionID: event.divisionID,
              seasonID: event.seasonID,
              localTeam: event.localTeamID,
              guessTeam: event.guessTeamID,
              localTeamPTS: event.localTeamPTS,
              guessTeamPTS: event.guessTeamPTS,
              locationID: event.locationID,
              status: event.status,
              type: event.type,
              gameType: event.gameType,
              desc: event.desc,
              winner: event.winner,
              backgroundColor:
                event.gameType === "Free"
                  ? "#5CD85A"
                  : event.divisionID
                  ? event.divisionID.color
                  : "#ffffff",
              saved: saved,
            };
          });

          setEvents(events);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1200);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEventSubmit = () => {
    fetchEvents(selectedSeason);
  };

  const handleSeasonChange = (e) => {
    setSelectedSeason(e.target.value);
    fetchDivisions(e.target.value);
  };

  useEffect(() => {
    fetchLocations();
    Axios.get(url + "/users/login", {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setLoginStatus(response.data.LoggedIn ? true : false);
    });
  }, [fetchSeasons, url]);

  const getWeekNumberContent = useCallback(
    (arg) => {
      const startDate = new Date(seasonData?.from);
      const currentWeek = Math.ceil(
        (arg.date - startDate) / (7 * 24 * 60 * 60 * 1000) + 1
      );
      return currentWeek.toString();
    },
    [seasonData]
  );

  const fullCalendarProps = useMemo(
    () => ({
      plugins: [dayGridPlugin],
      initialView: "dayGridMonth",
      defaultAllDay: true,
      height: "auto",

      weekNumbers: true,
      weekNumberClassNames: "weekNumbers",
      weekText: "W ",
      validRange: {
        start: seasonData?.from,
        end: seasonData?.to,
      },
      weekNumberContent: getWeekNumberContent,
      weekNumberCalculation: "local",
      expandRows: true,
      eventClick: (e) => {
        handleOpen(e.event);
      },
      events: { events },
    }),
    [getWeekNumberContent, handleOpen, seasonData, events]
  );

  const handleEventEdit = (index, updatedEvent) => {
    setEditedEvents((prevEditedEvents) => {
      const newEditedEvents = [...prevEditedEvents];
      newEditedEvents[index] = updatedEvent;
      const statsJson = JSON.stringify(newEditedEvents);
      localStorage.setItem("savedEvents", statsJson);

      return newEditedEvents;
    });
  };

  const eventsByDate = {};

  events.forEach((event) => {
    const eventDate = event.date; // Make sure event.date is a valid date string

    if (!eventsByDate[eventDate]) {
      eventsByDate[eventDate] = [];
    }

    eventsByDate[eventDate].push(event);
  });

  function isDateInCurrentWeek(date) {
    // Get the current date
    const currentDate = new Date();

    // Calculate the start of the current week (Sunday)
    const currentWeekStart = new Date(currentDate);
    currentWeekStart.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate the end of the current week (Saturday)
    const currentWeekEnd = new Date(currentWeekStart);
    currentWeekEnd.setDate(currentWeekStart.getDate() + 6);

    // Check if the given date is within the current week
    return (
      new Date(date) >= currentWeekStart && new Date(date) <= currentWeekEnd
    );
  }

  const regularEventsByDate = {};
  const specialEventsByDate = {};

  events.forEach((event, index) => {
    const eventDate = event.date; // Make sure event.date is a valid date string

    if (["F", "FFT", "CAN"].some((str) => str === event.status)) {
      if (!specialEventsByDate[eventDate]) {
        specialEventsByDate[eventDate] = [];
      }
      specialEventsByDate[eventDate].push({ ...event, index });
    } else {
      if (!regularEventsByDate[eventDate]) {
        regularEventsByDate[eventDate] = [];
      }
      regularEventsByDate[eventDate].push({ ...event, index });
    }
  });

  const renderedRegularEvents = Object.entries(regularEventsByDate)
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .map(([date, events]) => (
      <div key={date} className="my-4 zoom">
        <Accordion sx={{ backgroundColor: "transparent" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="large" />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h2
              style={{
                color: isDateInCurrentWeek(date) ? "cyan" : "white",
              }}
              className="font-extrabold h-full  w-full text-8xl mr-4 italic text-right sm-shadow font-family"
            >
              {new Date(date).toLocaleDateString("en-US", options2)}
            </h2>
          </AccordionSummary>
          <AccordionDetails>
            {events.map((event) => (
              <Event
                key={event.index} // Use the actual event index or generate a unique identifier
                index={event.index}
                event={event}
                locations={locations}
                minDate={seasonData.from}
                maxDate={seasonData.to}
                season={selectedSeason}
                divisions={divisionsData}
                refresh={handleEventSubmit}
                onEdit={handleEventEdit}
              ></Event>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
    ));

  const renderedSpecialEvents = Object.entries(specialEventsByDate)
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .map(([date, events]) => (
      <div key={date} className="my-4 zoom">
        <Accordion sx={{ backgroundColor: "transparent" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <h2 className="font-extrabold w-full text-8xl italic mr-4 text-right sm-shadow font-family">
              {new Date(date).toLocaleDateString("en-US", options2)}
            </h2>
          </AccordionSummary>
          <AccordionDetails>
            {events.map((event) => (
              <Event
                key={event.index} // Use the actual event index or generate a unique identifier
                index={event.index}
                event={event}
                locations={locations}
                minDate={seasonData.from}
                maxDate={seasonData.to}
                season={selectedSeason}
                divisions={divisionsData}
                refresh={handleEventSubmit}
                onEdit={handleEventEdit}
              ></Event>
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
    ));

  return (
    <div className="min-h-screen">
      {loginStatus && (
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="Games"></Title>
          </div>

          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              Schedule
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="season-label">Season</InputLabel>
                <Select
                  labelId="season-label"
                  label={"Season"}
                  onChange={handleSeasonChange}
                  value={selectedSeason}
                >
                  {seasonsData.map((season, index) => (
                    <MenuItem value={season._id} key={index}>
                      {season.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="block w-full">
              {seasonData && <FullCalendar {...fullCalendarProps} />}
            </div>

            <div className="w-full ">
              <ul className="flex flex-col h-fit gap-2 m-8">
                <div className="flex justify-center">
                  <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
                    Create Event
                    <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
                  </div>
                </div>
                <Event
                  locations={locations}
                  minDate={seasonData?.from}
                  maxDate={seasonData?.to}
                  season={selectedSeason}
                  divisions={divisionsData}
                  refresh={handleEventSubmit}
                ></Event>
                <div className="flex justify-center">
                  <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
                    Edit Games
                    <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
                  </div>
                </div>
                {loading ? (
                  <div className="flex justify-center">
                    <CircularProgress></CircularProgress>
                  </div>
                ) : events.length === 0 ? (
                  <div className="text-center"> No Games Yet</div>
                ) : (
                  <div className="w-full ">
                    {renderedRegularEvents}
                    <div className=" my-16 w-full font-bold  text-center text-7xl">
                      {" "}
                      Closed Games
                    </div>
                    <div>{renderedSpecialEvents}</div>
                  </div>
                )}
              </ul>
            </div>
          </div>
          <div className="absolute w-screen h-screen ">
            {event && (
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <div
                  className={`pt-10 flex relative zoom sm:h-[80vh] sm:max-h-[600px]  lg:max-h-[800px] md:h-[80vh] max-w-[95vw]  sm:aspect-[6/8] flex-col  border-[${event.backgroundColor}] rounded-lg sm-shadow text-black bgBlack `}
                  style={{ border: "solid 8px " + event.backgroundColor }}
                >
                  <div className="w-[100%] h-[100%] absolute  top-0 overflow-hidden  border-b-0 border-black rounded-t-xl">
                    <img
                      src="../assets/img/AW_allblack.png"
                      className="object-cover h-[100%] selector absolute left-1/2 translate-x-[-50%]"
                    ></img>
                  </div>
                  {event._def.extendedProps.type === "Game" && (
                    <div
                      className={`absolute top-0 left-0 p-2 text-black  rounded-br-xl flex gap-1 z-10`}
                      style={{ backgroundColor: "" + event.backgroundColor }}
                    >
                      <p>{event?.start.toLocaleDateString("en-US", options)}</p>{" "}
                      -
                      <p className="font-bold">
                        {event._def.extendedProps.time}
                      </p>
                    </div>
                  )}
                  {event._def.extendedProps.type === "Game" && (
                    <div className="w-full h-fit font-extrabold sm:text-3xl mt-8 lg:text-4xl flex flex-col justify-center items-center text-white z-10">
                      {" "}
                      <div className="w-full font-extrabold sm:text-sm lg:text-base  flex justify-center items-center text-white z-10">
                        {" "}
                        {event._def.extendedProps.divisionID.titleEn.toUpperCase()}
                      </div>
                      {event._def.extendedProps.gameType.toUpperCase()}
                      <div className="w-full font-extrabold sm:text-sm lg:text-base  flex justify-center items-center text-white z-10">
                        {" "}
                        {event._def.extendedProps.status.toUpperCase()}
                      </div>
                    </div>
                  )}
                  {event._def.extendedProps.type === "Game" && (
                    <div className=" flex sm:flex-col  duration-75 w-full h-[85%] justify-evenly">
                      {/* Guess team */}
                      <div className="  w-full relative flex justify-center items-center">
                        <div className="w-3/4 relative pb-1  ">
                          <a
                            href={
                              "/team/" + event._def.extendedProps.guessTeam?._id
                            }
                          >
                            <Title
                              word={event._def.extendedProps.guessTeam?.name}
                            />
                          </a>
                          <span className="absolute w-[100%] top-0 left-[-50%]  text-center translate-x-[50%] translate-y-[-100%] text-white text-sm z-40">
                            Visitor
                          </span>
                          <span className="absolute w-fit bottom-0 left-1/2  text-center translate-x-[-50%] translate-y-[80%] font-bold text-white text-2xl z-40">
                            {["F", "FFT", "CAN"].some(
                              (str) => str === event?._def.extendedProps.status
                            ) && event._def.extendedProps.guessTeamPTS}
                            {event._def.extendedProps.winner ===
                            event._def.extendedProps.guessTeam?._id ? (
                              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white z-40"></span>
                            ) : (
                              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-transparent z-40"></span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="sm:w-full relative flex justify-center items-center text-white">
                        {event._def.extendedProps.status === "FFT" ||
                        event._def.extendedProps.status === "F" ? (
                          dots ? (
                            <div className="fade-in opacity-0">
                              <Button
                                size="large"
                                className="sm:h-12 lg:h-12 sm:w-44 lg:w-64 border-transparent"
                                onClick={() =>
                                  navigate(
                                    `/game/${event._def.extendedProps?._id}`
                                  )
                                }
                                sx={{
                                  border: "solid 2px transparent",
                                  "&:hover": {
                                    color: "white",
                                    backgroundColor: "black",
                                    border: "solid 2px white",
                                  },
                                }}
                              >
                                <div className="dot-bouncing lg:text-3xl">
                                  <span className="sm-shadow"></span>
                                  <span className="sm-shadow"></span>
                                  <span className="sm-shadow"></span>
                                </div>
                              </Button>
                            </div>
                          ) : (
                            <div className="fade-in">
                              <Button
                                size="large"
                                variant="contained"
                                color="inherit"
                                sx={{
                                  fontWeight: 800,
                                  color: "black",
                                  backgroundColor: "white",
                                  "&:hover": {
                                    color: "white",
                                    backgroundColor: "black",
                                    border: "solid 2px white",
                                  },
                                }}
                                className="sm-shadow sm:h-12 lg:h-12 sm:w-44 lg:w-64 text-black"
                                onClick={() =>
                                  navigate(
                                    `/game/${event._def.extendedProps?._id}`
                                  )
                                }
                              >
                                See More
                              </Button>
                            </div>
                          )
                        ) : (
                          <div className="relative flex  justify-center items-center stats-font text-center ">
                            <span className="absolute fontRed z-20 font-extrabold sm:text-3xl md:sm:text-4xl lg:text-5xl text-white">
                              vs
                            </span>
                            <span
                              data-content={`vs`}
                              className="customShadow z-10 font-extrabold sm:text-3xl md:sm:text-4xl lg:text-5xl select-none"
                            >
                              vs
                            </span>
                          </div>
                        )}
                      </div>
                      {/* Local team */}
                      <div className=" w-full relative flex justify-center items-center">
                        <div className="w-3/4 relative pb-1  ">
                          <a
                            href={
                              "/team/" + event._def.extendedProps.localTeam?._id
                            }
                          >
                            <Title
                              word={event._def.extendedProps.localTeam?.name}
                            />
                          </a>
                          <span className="absolute w-[100%] top-0 left-[-50%]  text-center translate-x-[50%] translate-y-[-100%] text-white text-sm z-40">
                            Home
                          </span>
                          <span className="absolute w-fit bottom-0 left-1/2  text-center translate-x-[-50%] translate-y-[80%] font-bold text-white text-2xl z-40">
                            {["F", "FFT", "CAN"].some(
                              (str) => str === event?._def.extendedProps.status
                            ) && event._def.extendedProps.localTeamPTS}
                            {event._def.extendedProps.winner ===
                            event._def.extendedProps.localTeam?._id ? (
                              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white z-40"></span>
                            ) : (
                              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-transparent z-40"></span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {event._def.extendedProps.type === "Gala" && (
                    <div className="sm:mt-12 lg:mt-24">
                      <Title word={event._def.extendedProps.type} />
                    </div>
                  )}
                  {event._def.extendedProps.type === "Gala" && (
                    <div className="flex flex-col h-full justify-center items-center text-white z-10">
                      <div
                        className="text-center block w-3/4 sm:text-base lg:text-lg"
                        dangerouslySetInnerHTML={{
                          __html: event._def.extendedProps.desc.replace(
                            /\n/g,
                            "<br>"
                          ),
                        }}
                      ></div>
                    </div>
                  )}
                  <div className="flex w-full h-[15%]">
                    <a
                      href={
                        "/location/" + event._def.extendedProps.locationID?._id
                      }
                      className="text-white text-xl w-full flex items-center justify-center pb-4 z-10"
                    >
                      <LocationOnIcon className="sm-shadow" />{" "}
                      {event._def.extendedProps.locationID?.name}
                    </a>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default GamesAdmin;
