import { useEffect, useState, useContext } from "react";
import React from "react";
import Axios from "axios";
import Title from "./component/Title";
import GlobalContext from "./GlobalContext";
import { useNavigate } from "react-router-dom";

function Admin() {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const navigate = useNavigate();

  const [loginStatus, setLoginStatus] = useState(false);

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    if (localStorage.refresh) {
      Axios.get(url + "/users/login", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          setLoginStatus(response.data.LoggedIn ? true : false);
        })
        .catch((err) => {
          return navigate("/login");
        });
    } else {
      setLoginStatus(false);
      return navigate("/login");
    }
  }, []);

  const logout = () => {
    Axios.post(
      url + "/users/logout",
      {},
      {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        return navigate("/login");
      })
      .catch(() => {});
    setLoginStatus(false);
  };

  return (
    <div>
      <div className="container">
        <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
          <Title word="Admin" />
        </div>
        {loginStatus && (
          <div className="content relative my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="flex flex-col gap-2 font-bold font-Poppins text-lg text-center text-white">
              <a
                className="hover:scale-110 duration-150 transition-all"
                href="/admin/seasons"
              >
                Seasons
              </a>
              <a
                className="hover:scale-110 duration-150 transition-all"
                href="/admin/divisions"
              >
                Divisions
              </a>
              <a
                className="hover:scale-110 duration-150 transition-all"
                href="/admin/teams"
              >
                Teams
              </a>
              <a
                className="hover:scale-110 duration-150 transition-all"
                href="/admin/players"
              >
                Players
              </a>
              <a
                className="hover:scale-110 duration-150 transition-all"
                href="/admin/registrations"
              >
                Registrations
              </a>
              <a
                className="hover:scale-110 duration-150 transition-all"
                href="/admin/games"
              >
                Games
              </a>
              <a
                className="hover:scale-110 duration-150 transition-all"
                href="/photos"
              >
                Photos
              </a>
              <a
                className="hover:scale-110 duration-150 transition-all"
                href="/admin/locations"
              >
                Locations
              </a>
              <a
                className="hover:scale-110 duration-150 transition-all"
                href="/admin/FAQ"
              >
                FAQ
              </a>
            </div>
            <button onClick={logout} className="absolute m-4 bottom-0 right-0">
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Admin;
