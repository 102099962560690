import { useState, useEffect, useContext } from "react";
import Title from "../component/Title";
import GlobalContext from "../GlobalContext";
import Axios from "axios";
import Division from "../component/Division";
import { useSnackbar } from "notistack";
import { Button, TextField } from "@mui/material";
import { MuiColorInput } from "mui-color-input";

const DivisionsAdmin = () => {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [loginStatus, setLoginStatus] = useState(false);
  const [divisions, setDivisions] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [titleEn, setTitleEn] = useState("");
  const [price, setPrice] = useState(0);
  const [color, setColor] = useState("#ffffff");

  const { enqueueSnackbar } = useSnackbar();

  const handleResponse = (msg, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  Axios.defaults.withCredentials = true;

  const fetchDivisions = () => {
    Axios.get(url + "/divisions").then((response) => {
      setDivisions(response.data);
    });
  };

  const postDivision = async () => {
    await Axios.post(url + "/divisions", {
      titleEn: titleEn,
      price: price ? price : 0,
      color: color,
    })
      .then((response) => {
        fetchDivisions();
        handleResponse("Successfully Created Division.", "success");
      })
      .catch((err) => {
        handleResponse("Error Creating Division.", "error");
      });
  };

  const handleColor = (color) => {
    setColor(color);
  };

  const onSubmit = () => {
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
      handleResponse("Successfully Edited Division.", "success");
    }, 1500);
  };

  useEffect(() => {
    Axios.get(url + "/users/login", {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setLoginStatus(response.data.LoggedIn ? true : false);
    });
    fetchDivisions();
  }, []);

  return (
    <div className="min-h-screen">
      {loginStatus && (
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="Divisions"></Title>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              Create Divisions
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div className="flex gap-4 flex-col ">
              <div className="flex sm:flex-col lg:flex-row my-4 gap-2">
                <TextField
                  label="Title"
                  onChange={(e) => setTitleEn(e.target.value)}
                  value={titleEn}
                ></TextField>
                <TextField
                  onWheel={(e) => e.target.blur()}
                  label="Price"
                  type="number"
                  onChange={(e) => setPrice(parseInt(e.target.value))}
                  value={price}
                ></TextField>
                <MuiColorInput
                  label="Color"
                  value={color}
                  onChange={handleColor}
                />
              </div>
            </div>
            <Button variant="outlined" onClick={postDivision}>
              ADD
            </Button>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              Edit Divisions
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div className="flex gap-4 flex-col ">
              {divisions.map((division, index) => (
                <div key={index}>
                  <Division division={division} submit={submit}></Division>
                </div>
              ))}
              <Button onClick={onSubmit} disabled={submit} variant="outlined">
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DivisionsAdmin;
