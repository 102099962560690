import React, { useContext, useState, useEffect } from "react";
import Title from "./component/Title";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Rules from "./component/Rules";
import { useNavigate } from "react-router-dom";
import GlobalContext from "./GlobalContext";
import Axios from "axios";
import Location from "./component/Location";

function About() {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [locations, setLocations] = useState([]);
  const navigate = useNavigate();

  const handleClick = () => {
    // Replace '/destination' with the path of the page you want to redirect to
    navigate("/registrations");
  };

  const fetchLocations = () => {
    Axios.get(url + "/locations").then((response) => {
      setLocations(response.data);
    });
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div>
      <div className="container ">
        <div className="content min-h-[80vh] relative justify-start items-center pb-12 my-8 sm:w-[95%] lg:w-[80%] black-bg  bg-opacity-50  border-black border-8 overflow-hidden">
          <div className="sm:h-[300px] lg:h-[500px] w-full flex justify-center items-center relative flex-col  z-10 border-b-8 border-black">
            <div className="flex w-[25%] justify-evenly gap-4">
              <Title word="ABOUT"></Title> <Title word="US"></Title>
            </div>{" "}
            <div className="w-[60%] h-[14px] bg-white border-4 border-black rounded-md underLine-shadow mt-4 mb-10 z-10"></div>
            <div
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/20230921_223705.jpg)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="absolute w-full h-full flex"
            ></div>
          </div>
          <div className="sm:py-16 sm:px-8 lg:py-24 lg:px-48 h-fit w-full text-white text-center font-Poppins">
            <h1 className="text-3xl font-extrabold m-2">Welcome!!</h1>
            <p>
              AfterWork Basket-ball is a basketball league perfect for workers
              looking for teambuilding who want to practice their favorite sport
              with their colleagues and compete.
            </p>
            <p>
              It's also a great way to spend some quality time with a group of
              friends after the workday is over.
            </p>
            <p>Any collaboration is good as long as it's fun</p>
            <h1 className="font-bold text-xl m-2">
              There are 2 levels of competition :
            </h1>{" "}
            <h2 className="font-bold text-2xl m-2">REGULAR</h2>{" "}
            <p>
              For those seeking pleasure through an activity that contributes to
              the maintenance of form
            </p>
            <h2 className="font-bold text-2xl m-2">ADVANCED</h2>
            <p>For those more driven by challenge and rivalry!</p>{" "}
            <h1 className="font-bold text-xl m-2">The season includes :</h1>{" "}
            <li>10 games with referee</li>{" "}
            <li>Playoffs for qualifying teams</li>
            <li>1 Jersey</li> <li>individual (pts) and team stats (ranking)</li>{" "}
            <li>Website with pics and more</li>{" "}
            <li>Price for the winning team</li>{" "}
            <p className="mt-8 font-semibold">
              *Verify with your employer, fees for physical activities might be
              reimbursed.*
            </p>
          </div>
        </div>
        <div
          id="ourTeam"
          className="content min-h-[80vh] relative justify-start items-center pb-12 my-8 sm:w-[95%] lg:w-[80%] black-bg  bg-opacity-50  border-black border-8 overflow-hidden"
        >
          <div className="sm:h-[300px] lg:h-[500px] w-full flex justify-center items-center relative flex-col  z-10 border-b-8 border-black">
            <div className="flex w-[25%] justify-center gap-4">
              <Title word="OUR"></Title> <Title word="TEAM"></Title>
            </div>{" "}
            <div className="w-[60%] h-[14px] bg-white border-4 border-black rounded-md underLine-shadow mt-4 mb-10 z-10"></div>
            <div className="absolute w-full h-full flex bgBlack overflow-hidden">
              <div
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/AW_RED.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
                className="absolute w-full h-full flex lg-shadow"
              ></div>
            </div>
          </div>
          <div className="relative text-center w-fit sm:my-8 lg:my-12 text-4xl font-extrabold stats-font p-2">
            Owners
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
          </div>
          <div className=" sm:px-8 lg:px-auto h-fit w-full sm:justify-center items-center text-white text-center font-Poppins flex sm:flex-col lg:flex-row lg:justify-start">
            <div className="sm:w-full lg:w-1/2 h-auto p-8 flex justify-center flex-col items-center">
              <div
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/255572653_10159432105798609_6138976772273701773_n.jpg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
                className="w-full aspect-square sm-shadow max-w-[400px]"
              ></div>
              <div className="p-2 mt-6 my-2 relative">
                <span className="absolute w-[100%] h-1 top-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
                <Title word={"Ronald"}></Title>
                <Title word={"Celestin"}></Title>
                <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
              </div>
              <div>
                <h1 className="text-xl">514 966-8470</h1>
              </div>
            </div>
            <div className="sm:w-full lg:w-1/2 h-auto p-8 flex justify-center flex-col items-center">
              <div
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/152548122_10165027922120048_5532932566238864430_n.jpg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
                className="w-full aspect-square sm-shadow max-w-[400px]"
              ></div>
              <div className="p-2 mt-6 my-2 relative">
                <span className="absolute w-[100%] h-1 top-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
                <Title word={"Donald"}></Title>
                <Title word={"Maisonneuve"}></Title>
                <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
              </div>
              <div>
                <h1 className="text-xl">514 677-8648</h1>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="content min-h-fit relative justify-start items-center pb-12 my-8 sm:w-[95%] lg:w-[80%] black-bg  bg-opacity-50  border-black border-8 overflow-hidden">
          <div className="sm:h-[300px] lg:h-[500px] w-full flex justify-center items-center relative flex-col  z-10 border-b-8 border-black">
            <div className="flex w-[25%] justify-evenly gap-4">
              <Title word="OUR"></Title> <Title word="Rules"></Title>
            </div>{" "}
            <div className="w-[60%] h-[14px] bg-white border-4 border-black rounded-md underLine-shadow mt-4 mb-10 z-10"></div>
            <div className="absolute w-full h-full flex bgBlack overflow-hidden">
              <div
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/AW.png)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
                className="absolute w-full h-full flex lg-shadow"
              ></div>
            </div>
          </div>
          <div className="relative text-center w-fit sm:my-8 lg:my-12 text-4xl font-extrabold stats-font p-2">
            Rules
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
          </div>
          <div className=" h-fit w-full justify-center items-center text-white text-center font-Poppins flex sm:flex-col lg:flex-row">
            <Rules />
          </div>
        </div>
        <div className="content min-h-[80vh] relative justify-start items-center pb-12 my-8 sm:w-[95%] lg:w-[80%] black-bg  bg-opacity-50  border-black border-8 overflow-hidden">
          <div className="sm:h-[300px] lg:h-[500px] mb-4 w-full flex justify-center items-center relative flex-col  z-10 border-b-8 border-black">
            <div className="flex w-[25%] justify-center gap-4">
              <Title word="Find"></Title> <Title word="us"></Title>
            </div>{" "}
            <div className="w-[60%] h-[14px] bg-white border-4 border-black rounded-md underLine-shadow mt-4 mb-10 z-10"></div>
            <div className="absolute w-full h-full flex bgBlack overflow-hidden">
              <div
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/reinemarie.jpg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="absolute top-1/2 -translate-y-1/2 w-full h-full flex lg-shadow"
              ></div>
            </div>
          </div>
          <LocationOnIcon
            sx={{ color: "white", fontSize: "64px", marginBottom: "8px" }}
          />
          <div className="relative text-center w-fit sm:mb-8 lg:mb-12  text-4xl font-extrabold stats-font p-2 text-white">
            Locations
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
          </div>
          <div className="w-full h-full flex justify-center ">
            <div className="sm:max-w-[600px] lg:max-w-[1200px] flex overflow-x-auto gap-10 scrollbar-thin scrollbar-thumb-slate-500">
              {locations.map((location) => (
                <div className="sm:mx-5 lg:mx-12">
                  <Location location={location}></Location>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="content min-h-[80vh] relative justify-start items-center pb-12 my-8 sm:w-[95%] lg:w-[80%] black-bg  bg-opacity-50  border-black border-8 overflow-hidden">
          <div className="sm:h-[300px] lg:h-[500px] w-full flex justify-center items-center relative flex-col  z-10 border-b-8 border-black">
            <div className="flex justify-center w-[100%]">
              <Title word="Interested ?"></Title>
            </div>{" "}
            <div className="w-[60%] h-[14px] bg-white border-4 border-black rounded-md underLine-shadow mt-4 mb-10 z-10"></div>
            <div className="absolute w-full h-full flex bgBlack overflow-hidden">
              <div
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/interet.jpg)`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="absolute top-1/2 -translate-y-1/2 w-full h-full flex lg-shadow"
              ></div>
            </div>
          </div>

          <div className="w-full h-full p-16">
            <div className="py-12 h-fit w-full justify-center items-center text-center font-Poppins flex sm:flex-col rounded-md relative">
              <button
                // @ts-ignore
                style={{ "--b": "8px", "--s": "12px" }}
                className="button sm:scale-50 lg:scale-100 font-extrabold"
                onClick={handleClick}
              >
                REGISTER
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
