import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Input,
  IconButton,
  Checkbox,
  Autocomplete,
  TextField,
  OutlinedInput,
  CircularProgress,
  Collapse,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Axios from "axios";
import GlobalContext from "../GlobalContext";

const GameStatsCalculator = ({ game, onSubmit, onEdit }) => {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [localTeamPlayers, setLocalTeamPlayers] = useState([]);
  const [guessTeamPlayers, setGuessTeamPlayers] = useState([]);
  const [splitButtonsLocal, setSplitButtonsLocal] = useState(false);
  const [splitButtonsGuess, setSplitButtonsGuess] = useState(false);
  const [showLocalPlayers, setShowLocalPlayers] = useState(false);
  const [showGuessPlayers, setShowGuessPlayers] = useState(false);
  const [playersData, setPlayersData] = useState([]);
  const [localEthic, setLocalEthic] = useState(1);
  const [guessEthic, setGuessEthic] = useState(1);
  const [localScore, setLocalScore] = useState(0);
  const [guessScore, setGuessScore] = useState(0);
  const [winner, setWinner] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ghostName, setGhostName] = useState("");

  const [openGuess, setOpenGuess] = React.useState(false);
  const handleOpenGuess = () => setOpenGuess(true);
  const handleCloseGuess = () => setOpenGuess(false);

  const [openLocal, setOpenLocal] = React.useState(false);
  const handleOpenLocal = () => setOpenLocal(true);
  const handleCloseLocal = () => setOpenLocal(false);

  const handleAddPlayerClick = (team) => {
    // Toggle between showing the split buttons and the "Add Player" button
    if (team === "local") {
      setSplitButtonsLocal(true);
    } else if (team === "guess") {
      setSplitButtonsGuess(true);
    }
  };

  const handleExistingPlayerClick = async (team) => {
    if (team === "local") {
      setShowLocalPlayers(true);
    } else if (team === "guess") {
      setShowGuessPlayers(true);
    }

    if (playersData.length === 0) {
      await Axios.get(url + "/players/division/" + game.divisionID._id)
        .then((response) => {
          const players = response.data.map((player) => ({
            label: player.firstname + " " + player.lastname,
            value: player._id,
          }));
          setPlayersData(players);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    fetchPlayers();
    setTimeout(() => {
      setLoading(false);
    }, 1200);

    setExpanded(true);
  }, []);

  useEffect(() => {
    onEdit({
      gameInfo: { guessEthic, localEthic, winner, localScore, guessScore },
      guess: guessTeamPlayers,
      local: localTeamPlayers,
    });
  }, [
    localTeamPlayers,
    guessTeamPlayers,
    guessEthic,
    localEthic,
    winner,
    localScore,
    guessScore,
  ]);

  const fetchPlayers = async () => {
    try {
      const response = await Axios.get(
        url +
          "/playerTeams/team/" +
          game.localTeam._id +
          "/players/season/" +
          game.seasonID._id
      );
      const teamPlayers = response.data.map((playerTeam, index) => {
        const savedPlayer = game.saved?.local
          ? game.saved?.local.find((obj) => obj._id === playerTeam.playerID._id)
          : null;

        if (savedPlayer) {
          return savedPlayer;
        } else {
          return {
            _id: playerTeam.playerID._id,
            name:
              playerTeam.playerID.firstname +
              " " +
              playerTeam.playerID.lastname,
            quarters: [
              { enteredValue: "" },
              { enteredValue: "" },
              { enteredValue: "" },
              { enteredValue: "" },
              { enteredValue: "" },
              { enteredValue: "" },
            ],
            gp: false,
            gpForce: false,
            totalPoints: 0,
            fgm: 0,
            twos: 0,
            threes: 0,
            freeThrowsMade: 0,
            freeThrowsAttempts: 0,
            fouls: 0,
            techs: 0,
            bestDefender: false,
            ghost: false,
            visitor: false,
          };
        }
      });
      if (game.saved?.local) {
        game.saved.local.forEach((player) => {
          // Check if the player with the same _id already exists in original array
          const existsInB = teamPlayers.some((p) => p._id === player._id);

          // If not, add the missing player to array
          if (!existsInB) {
            teamPlayers.push(player);
          }
        });
      }

      setLocalTeamPlayers(teamPlayers);

      const responseGuess = await Axios.get(
        url +
          "/playerTeams/team/" +
          game.guessTeam._id +
          "/players/season/" +
          game.seasonID._id
      );

      const guessTeamsPlayers = responseGuess.data.map((playerTeam, index) => {
        const savedPlayer = game.saved?.guess
          ? game.saved?.guess.find((obj) => obj._id === playerTeam.playerID._id)
          : null;

        if (savedPlayer) {
          return savedPlayer;
        } else {
          return {
            _id: playerTeam.playerID._id,
            name:
              playerTeam.playerID.firstname +
              " " +
              playerTeam.playerID.lastname,
            quarters: [
              { enteredValue: "" },
              { enteredValue: "" },
              { enteredValue: "" },
              { enteredValue: "" },
              { enteredValue: "" },
              { enteredValue: "" },
            ],
            gp: false,
            gpForce: false,
            totalPoints: 0,
            fgm: 0,
            twos: 0,
            threes: 0,
            freeThrowsMade: 0,
            freeThrowsAttempts: 0,
            fouls: 0,
            techs: 0,
            bestDefender: false,
            ghost: false,
            visitor: false,
          };
        }
      });

      if (game.saved?.guess) {
        game.saved.guess.forEach((player) => {
          // Check if the player with the same _id already exists in original array
          const existsInB = guessTeamsPlayers.some((p) => p._id === player._id);

          // If not, add the missing player to array
          if (!existsInB) {
            guessTeamsPlayers.push(player);
          }
        });
      }

      setGuessTeamPlayers(guessTeamsPlayers);

      if (game.saved?.gameInfo) {
        setGuessEthic(game.saved?.gameInfo.guessEthic);
        setLocalEthic(game.saved?.gameInfo.localEthic);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const addPlayer = ({ setTeam, team, type, existingPlayer = null }) => {
    setTeam((prevPlayers) => [
      ...prevPlayers,
      {
        _id:
          type === "ghost"
            ? prevPlayers.length + 1
            : type === "visitor" && existingPlayer?.value,
        name:
          type === "ghost"
            ? ghostName
            : type === "visitor" && existingPlayer?.label,
        quarters: [
          { enteredValue: "" },
          { enteredValue: "" },
          { enteredValue: "" },
          { enteredValue: "" },
          { enteredValue: "" },
          { enteredValue: "" },
        ],
        gp: false,
        gpForce: false,
        totalPoints: 0,
        fgm: 0,
        twos: 0,
        threes: 0,
        freeThrowsMade: 0,
        freeThrowsAttempts: 0,
        fouls: 0,
        techs: 0,
        bestDefender: false,
        ghost: type === "ghost",
        visitor: type === "visitor",
      },
    ]);
  };

  const updateScore = (playerIndex, quarterIndex, value, setTeam) => {
    setTeam((prevPlayers) => {
      const players = [...prevPlayers];
      players[playerIndex].quarters[quarterIndex].enteredValue = value;

      let totalPoints = 0;
      let freeThrowsMade = 0;
      let freeThrowsAttempts = 0;
      let fgm = 0;
      let twos = 0;
      let threes = 0;

      let values = "";

      players[playerIndex].quarters.map((quarter, index) => {
        values += quarter.enteredValue.trim();
        if (index !== 0 && index !== 1) {
          quarter.enteredValue.split("").forEach((digit) => {
            if (digit === "0") {
              freeThrowsAttempts++;
            } else if (digit === "1") {
              freeThrowsAttempts++;
              freeThrowsMade++;
              totalPoints++;
              fgm++;
            } else if (digit === "2") {
              totalPoints += 2;
              twos++;
              fgm++;
            } else if (digit === "3") {
              totalPoints += 3;
              threes++;
              fgm++;
            }
          });
        }
      });

      if (values !== "") {
        players[playerIndex].gp = true;
      } else {
        players[playerIndex].gp = false;
      }

      players[playerIndex].totalPoints = totalPoints;
      players[playerIndex].freeThrowsMade = freeThrowsMade;
      players[playerIndex].freeThrowsAttempts = freeThrowsAttempts;
      players[playerIndex].fgm = fgm;
      players[playerIndex].twos = twos;
      players[playerIndex].threes = threes;

      if (quarterIndex === 0 || quarterIndex === 1) {
        const fouls =
          parseInt(players[playerIndex].quarters[0].enteredValue, 10) || 0; // Convert to integer or default to 0
        const techs =
          parseInt(players[playerIndex].quarters[1].enteredValue, 10) || 0; // Convert to integer or default to 0
        players[playerIndex].fouls = fouls;
        players[playerIndex].techs = techs;
        players[playerIndex].fouls += players[playerIndex].techs;
      }

      return players;
    });
  };

  const handleCheck = (playerIndex, quarterIndex, value, setTeam) => {
    setTeam((prevPlayers) => {
      const players = [...prevPlayers];
      players[playerIndex].gpForce = !players[playerIndex].gpForce;

      return players;
    });
  };

  const calculateTeamTotal = (team, teamSide) => {
    return team.reduce((total, player) => {
      return total + player.totalPoints;
    }, 0);
  };

  const handleKeyPress = (playerIndex, quarterIndex, e, teamSide, team) => {
    if (e.key === " " || e.key === "Enter") {
      e.preventDefault();
      // Move focus to the next input
      const nextQuarterIndex = (quarterIndex + 1) % 6;
      const nextPlayerIndex =
        nextQuarterIndex === 0 ? playerIndex + 1 : playerIndex;

      // Check if we are at the last player and quarter
      if (teamSide === "guess") {
        if (nextPlayerIndex < team.length) {
          const nextInput = document.getElementById(
            `input-${nextPlayerIndex}-${nextQuarterIndex}-guess`
          );
          if (nextInput) {
            nextInput.focus();
          }
        }
      } else if (teamSide === "local") {
        if (nextPlayerIndex < team.length) {
          const nextInput = document.getElementById(
            `input-${nextPlayerIndex}-${nextQuarterIndex}-local`
          );
          if (nextInput) {
            nextInput.focus();
          }
        }
      }
    }
  };

  const deletePlayer = (playerIndex, setTeam) => {
    setTeam((prevPlayers) => {
      const updatedPlayers = [...prevPlayers];
      updatedPlayers.splice(playerIndex, 1);
      return updatedPlayers;
    });
  };

  const calculateStats = async () => {
    onSubmit();
  };

  return (
    <>
      {loading ? (
        <div className="my-16">
          <CircularProgress />
        </div>
      ) : (
        <div className="relative fade-in block overflow-auto max-w-full expand delay-500">
          <Modal open={openGuess}>
            <div className="w-screen h-screen flex justify-center items-center">
              <div className="p-12 bg-black rounded-3xl border-2 flex items-center gap-4">
                <TextField
                  value={ghostName}
                  onChange={(e) => setGhostName(e.target.value)}
                ></TextField>
                <Button
                  onClick={() => {
                    addPlayer({
                      setTeam: setGuessTeamPlayers,
                      team: game.guessTeam,
                      type: "ghost",
                    });
                    handleCloseGuess();
                  }}
                  variant="contained"
                >
                  ADD
                </Button>
              </div>
            </div>
          </Modal>
          <Modal open={openLocal}>
            <div className="w-screen h-screen flex justify-center items-center">
              <div className="p-12 bg-black rounded-3xl border-2 flex items-center gap-4">
                <TextField
                  value={ghostName}
                  onChange={(e) => setGhostName(e.target.value)}
                ></TextField>
                <Button
                  onClick={() => {
                    addPlayer({
                      setTeam: setLocalTeamPlayers,
                      team: game.localTeam,
                      type: "ghost",
                    });
                    handleCloseLocal();
                  }}
                  variant="contained"
                >
                  ADD
                </Button>
              </div>
            </div>
          </Modal>
          <div className="p-4">
            <div className="flex justify-center">
              <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
                Guest/{game.guessTeam.name}
                <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
              </div>
            </div>
            <div className="flex justify-center items-center flex-col">
              <div>
                Ethic Points :{" "}
                <Checkbox
                  checked={guessEthic === 1}
                  onClick={(e) => {
                    setGuessEthic((prev) => (prev === 1 ? 0 : 1));
                  }}
                ></Checkbox>
              </div>
              {game.status === "FFT" && (
                <div>
                  Winner :{" "}
                  <Checkbox
                    checked={game.guessTeam._id === winner}
                    onClick={(e) => {
                      winner === game.guessTeam._id
                        ? setWinner("")
                        : setWinner(game.guessTeam._id);
                      guessScore === 0 &&
                      guessScore === 0 &&
                      winner !== game.guessTeam._id
                        ? setGuessScore(20)
                        : setGuessScore(0);
                    }}
                  ></Checkbox>
                </div>
              )}
              {game.status === "FFT" && (
                <div className="flex items-center gap-2">
                  Final Score :{" "}
                  <OutlinedInput
                    sx={{ width: 84 }}
                    size="small"
                    type="number"
                    onChange={(e) =>
                      setGuessScore(parseInt(e.target.value, 10))
                    }
                    value={guessScore}
                  />
                </div>
              )}
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Player</TableCell>
                  <TableCell className="w-8">GP</TableCell>
                  <TableCell className="w-8">Fouls</TableCell>
                  <TableCell className="w-8">Techs</TableCell>
                  <TableCell>Q1</TableCell>
                  <TableCell>Q2</TableCell>
                  <TableCell>Q3</TableCell>
                  <TableCell>Q4</TableCell>
                  <TableCell>FTM</TableCell>
                  <TableCell>FTA</TableCell>
                  <TableCell>Total Points</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {guessTeamPlayers.map((player, playerIndex) => (
                  <TableRow key={player._id} className="fade-in">
                    <TableCell>{player.name}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={player.gp || player.gpForce}
                        onClick={(e) => {
                          handleCheck(playerIndex, 0, e, setGuessTeamPlayers);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        autoComplete="off"
                        id={`input-${playerIndex}-0-guess`}
                        value={player.quarters[0].enteredValue}
                        onChange={(e) => {
                          updateScore(
                            playerIndex,
                            0,
                            e.target.value,
                            setGuessTeamPlayers
                          );
                        }}
                        onKeyDown={(e) =>
                          handleKeyPress(
                            playerIndex,
                            0,
                            e,
                            "guess",
                            guessTeamPlayers
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        autoComplete="off"
                        id={`input-${playerIndex}-1-guess`}
                        value={player.quarters[1].enteredValue}
                        onChange={(e) => {
                          updateScore(
                            playerIndex,
                            1,
                            e.target.value,
                            setGuessTeamPlayers
                          );
                        }}
                        onKeyDown={(e) =>
                          handleKeyPress(
                            playerIndex,
                            1,
                            e,
                            "guess",
                            guessTeamPlayers
                          )
                        }
                      />
                    </TableCell>

                    {player.quarters.slice(2).map((quarter, quarterIndex) => (
                      <TableCell key={quarterIndex + 2}>
                        <Input
                          sx={{ minWidth: "50px" }}
                          autoComplete="off"
                          id={`input-${playerIndex}-${quarterIndex + 2}-guess`}
                          value={quarter.enteredValue}
                          onChange={(e) => {
                            updateScore(
                              playerIndex,
                              quarterIndex + 2,
                              e.target.value,
                              setGuessTeamPlayers
                            );
                          }}
                          onKeyDown={(e) => {
                            handleKeyPress(
                              playerIndex,
                              quarterIndex + 2,
                              e,
                              "guess",
                              guessTeamPlayers
                            );
                          }}
                        />
                      </TableCell>
                    ))}
                    <TableCell>{player.freeThrowsMade}</TableCell>
                    <TableCell>{player.freeThrowsAttempts}</TableCell>
                    <TableCell>{player.totalPoints}</TableCell>
                    <TableCell>
                      {(player.ghost || player.visitor) && (
                        <IconButton
                          color="error"
                          size="small"
                          sx={{ border: "solid 2px #f44336" }}
                          onClick={() =>
                            deletePlayer(playerIndex, setGuessTeamPlayers)
                          }
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <tfoot className="bg-white">
                <TableRow>
                  <TableCell
                    colSpan="10"
                    className="text-black"
                    sx={{ fontWeight: "800" }}
                  >
                    Team Total:
                  </TableCell>
                  <TableCell className="text-black" sx={{ fontWeight: "800" }}>
                    {calculateTeamTotal(guessTeamPlayers, "guess")}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </tfoot>
            </Table>
            {splitButtonsGuess ? (
              <div className="fade-in">
                {showGuessPlayers ? (
                  <div className="flex items-center fade-in gap-2 m-4">
                    Existing Players :{" "}
                    <Autocomplete
                      id="players-existing"
                      options={playersData}
                      getOptionDisabled={(option) =>
                        guessTeamPlayers.some(
                          (player) => player._id === option.value
                        ) ||
                        guessTeamPlayers.some(
                          (player) => player._id === option.value
                        )
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Existing Players" />
                      )}
                      onChange={(e, value) => {
                        addPlayer({
                          setTeam: setGuessTeamPlayers,
                          team: game.guessTeam,
                          type: "visitor",
                          existingPlayer: value,
                        });
                        setSplitButtonsGuess(false);
                        setShowGuessPlayers(false);
                      }}
                    />
                  </div>
                ) : (
                  <Button
                    className="fade-in"
                    variant="outlined"
                    color="secondary"
                    sx={{ margin: 2 }}
                    onClick={(e) => handleExistingPlayerClick("guess")}
                  >
                    Existing Player
                  </Button>
                )}
                <Button
                  className="fade-in"
                  variant="outlined"
                  color="secondary"
                  sx={{ margin: 2 }}
                  onClick={(e) => {
                    setGhostName(game.guessTeam.name + " Player");
                    handleOpenGuess();
                    setSplitButtonsGuess(false);
                    setShowGuessPlayers(false);
                  }}
                >
                  Ghost Player
                </Button>
              </div>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                sx={{ margin: 2 }}
                onClick={(e) => handleAddPlayerClick("guess")}
              >
                Add Player
              </Button>
            )}
          </div>
          <div className="p-4">
            <div className="flex justify-center">
              <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
                Home/{game.localTeam.name}
                <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
              </div>
            </div>
            <div className="flex justify-center items-center flex-col">
              <div>
                Ethic Points :{" "}
                <Checkbox
                  checked={localEthic === 1}
                  onClick={(e) => {
                    setLocalEthic((prev) => (prev === 1 ? 0 : 1));
                  }}
                ></Checkbox>
              </div>
              {game.status === "FFT" && (
                <div>
                  Winner :{" "}
                  <Checkbox
                    checked={game.localTeam._id === winner}
                    onClick={(e) => {
                      winner === game.localTeam._id
                        ? setWinner("")
                        : setWinner(game.localTeam._id);
                      localScore === 0 &&
                      guessScore === 0 &&
                      winner !== game.localTeam._id
                        ? setLocalScore(20)
                        : setLocalScore(0);
                    }}
                  ></Checkbox>
                </div>
              )}
              {game.status === "FFT" && (
                <div className="flex items-center gap-2">
                  Final Score :{" "}
                  <OutlinedInput
                    sx={{ width: 84 }}
                    size="small"
                    type="number"
                    onChange={(e) =>
                      setLocalScore(parseInt(e.target.value, 10))
                    }
                    value={localScore}
                  />
                </div>
              )}
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Player</TableCell>
                  <TableCell className="w-8">GP</TableCell>
                  <TableCell className="w-8">Fouls</TableCell>
                  <TableCell className="w-8">Techs</TableCell>
                  <TableCell>Q1</TableCell>
                  <TableCell>Q2</TableCell>
                  <TableCell>Q3</TableCell>
                  <TableCell>Q4</TableCell>
                  <TableCell>FTM</TableCell>
                  <TableCell>FTA</TableCell>
                  <TableCell>Total Points</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localTeamPlayers.map((player, playerIndex) => (
                  <TableRow key={player._id} className="fade-in">
                    <TableCell>{player.name}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={player.gp || player.gpForce}
                        onClick={(e) => {
                          handleCheck(playerIndex, 0, e, setLocalTeamPlayers);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        autoComplete="off"
                        id={`input-${playerIndex}-0-local`}
                        value={player.quarters[0].enteredValue}
                        onChange={(e) => {
                          updateScore(
                            playerIndex,
                            0,
                            e.target.value,
                            setLocalTeamPlayers
                          );
                        }}
                        onKeyDown={(e) =>
                          handleKeyPress(
                            playerIndex,
                            0,
                            e,
                            "local",
                            localTeamPlayers
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        autoComplete="off"
                        id={`input-${playerIndex}-1-local`}
                        value={player.quarters[1].enteredValue}
                        onChange={(e) => {
                          updateScore(
                            playerIndex,
                            1,
                            e.target.value,
                            setLocalTeamPlayers
                          );
                        }}
                        onKeyDown={(e) =>
                          handleKeyPress(
                            playerIndex,
                            1,
                            e,
                            "local",
                            localTeamPlayers
                          )
                        }
                      />
                    </TableCell>

                    {player.quarters.slice(2).map((quarter, quarterIndex) => (
                      <TableCell key={quarterIndex + 2}>
                        <Input
                          sx={{ minWidth: "50px" }}
                          autoComplete="off"
                          id={`input-${playerIndex}-${quarterIndex + 2}-local`}
                          value={quarter.enteredValue}
                          onChange={(e) => {
                            updateScore(
                              playerIndex,
                              quarterIndex + 2,
                              e.target.value,
                              setLocalTeamPlayers
                            );
                          }}
                          onKeyDown={(e) => {
                            handleKeyPress(
                              playerIndex,
                              quarterIndex + 2,
                              e,
                              "local",
                              localTeamPlayers
                            );
                          }}
                        />
                      </TableCell>
                    ))}
                    <TableCell>{player.freeThrowsMade}</TableCell>
                    <TableCell>{player.freeThrowsAttempts}</TableCell>
                    <TableCell>{player.totalPoints}</TableCell>
                    <TableCell>
                      {(player.ghost || player.visitor) && (
                        <IconButton
                          color="error"
                          size="small"
                          sx={{ border: "solid 2px #f44336" }}
                          onClick={() =>
                            deletePlayer(playerIndex, setLocalTeamPlayers)
                          }
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <tfoot className="bg-white">
                <TableRow>
                  <TableCell
                    colSpan="10"
                    className="text-black"
                    sx={{ fontWeight: "800" }}
                  >
                    Team Total:
                  </TableCell>
                  <TableCell className="text-black" sx={{ fontWeight: "800" }}>
                    {calculateTeamTotal(localTeamPlayers, "local")}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </tfoot>
            </Table>
            {splitButtonsLocal ? (
              <div className="fade-in">
                {showLocalPlayers ? (
                  <div className="flex items-center fade-in gap-2 m-4">
                    Existing Players :{" "}
                    <Autocomplete
                      id="players-existing"
                      options={playersData}
                      getOptionDisabled={(option) =>
                        localTeamPlayers.some(
                          (player) => player._id === option.value
                        ) ||
                        guessTeamPlayers.some(
                          (player) => player._id === option.value
                        )
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Existing Players" />
                      )}
                      onChange={(e, value) => {
                        addPlayer({
                          setTeam: setLocalTeamPlayers,
                          team: game.localTeam,
                          type: "visitor",
                          existingPlayer: value,
                        });
                        setSplitButtonsLocal(false);
                        setShowLocalPlayers(false);
                      }}
                    />
                  </div>
                ) : (
                  <Button
                    className="fade-in"
                    variant="outlined"
                    color="secondary"
                    sx={{ margin: 2 }}
                    onClick={(e) => handleExistingPlayerClick("local")}
                  >
                    Existing Player
                  </Button>
                )}
                <Button
                  className="fade-in"
                  variant="outlined"
                  color="secondary"
                  sx={{ margin: 2 }}
                  onClick={(e) => {
                    setGhostName(game.localTeam.name + " Player");
                    handleOpenLocal();
                    setSplitButtonsLocal(false);
                    setShowLocalPlayers(false);
                  }}
                >
                  Ghost Player
                </Button>
              </div>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                sx={{ margin: 2 }}
                onClick={(e) => handleAddPlayerClick("local")}
              >
                Add Player
              </Button>
            )}
            <Button
              variant="outlined"
              className="w-[80%]"
              onClick={calculateStats}
              color="error"
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default GameStatsCalculator;
