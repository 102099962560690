import Slider from "react-slick";
import VanillaTilt from "vanilla-tilt";
import { useEffect, useState, useContext, useRef } from "react";
import "./PlayerCard.css";
import Axios from "axios";
import GlobalContext from "../GlobalContext";
import PersonIcon from "@mui/icons-material/Person";

function PlayerCard(prop) {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const id = prop.id;
  const [imgUrl, setImgUrl] = useState(null);

  const [example, setExample] = useState(prop.example ? prop.example : false);
  const [playerData, setPlayerData] = useState([]);
  const [playerSeasons, setPlayerSeasons] = useState([]);
  const [playerSeasonData, setPlayerSeasonData] = useState(null);
  const [lastGameData, setLastGameData] = useState([]);
  const [allTimeData, setAllTimeData] = useState(null);
  const [imageLinks, setImageLinks] = useState([]);
  const [team, setTeam] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [rank, setRank] = useState(0);
  const sliderRef = useRef(null);

  Axios.defaults.withCredentials = true;

  const fetchPlayerData = async () => {
    await Axios.get(url + "/players/" + prop.id).then((res) => {
      setPlayerData(res.data);
      fetchAllTime(res.data.divisionID._id);
    });
  };

  const fetchPlayerSeasons = async () => {
    await Axios.get(url + "/playerSeasons/all/" + prop.id).then((res) => {
      setPlayerSeasons(res.data);
    });
  };

  const fetchTeam = async () => {
    await Axios.get(url + "/playerTeams/current/player/" + id).then((res) => {
      setTeam(res.data.teamID);
    });
  };

  const playerImage = async () => {
    await Axios.get(url + "/images/playerID/" + id)
      .then((res) => {
        setImgUrl(res.data ? res.data : "");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // Example Model
  const fetchExamples = async () => {
    let links = [];
    for (let i = 1; i < 11; i++) {
      await Axios.get(url + "/images/playerID/Example" + i + ".png")
        .then((res) => {
          links.push(res.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    const preloadImages = links.map((link) => {
      const img = new Image();
      img.src = link;
      return img;
    });

    setImageLinks(preloadImages);
  };

  useEffect(() => {
    if (example && imageLinks[9]?.src) {
      const timer = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % imageLinks.length
        );
        if (sliderRef.current) {
          sliderRef.current.slickNext();
        }
      }, 2000);

      // Clear the interval when the component is unmounted or the array of imageLinks changes
      return () => clearInterval(timer);
    }
  }, [imageLinks]);

  const fetchPlayerSeasonData = async () => {
    await Axios.get(url + "/playerSeasons/current/player/" + id).then((res) => {
      setPlayerSeasonData(res.data);
    });
  };

  const fetchLastGameData = async () => {
    await Axios.get(url + "/playerGames/lastGame/player/" + id).then((res) => {
      setLastGameData(res.data);
    });
  };

  const fetchAllTime = async (divisionID) => {
    await Axios.get(
      url + "/playerAllTimes/standings/division/" + divisionID + "/player/" + id
    ).then((res) => {
      setAllTimeData(res.data.playerAllTime);
      setRank(res.data.index);
    });
  };

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const activateTilt = () => {
    // @ts-ignore
    VanillaTilt.init(document.querySelector("#my-div"), {
      max: 25,
      speed: 200,
      glare: true,
      "max-glare": 0.5,
    });
  };

  useEffect(() => {
    if (example) {
      fetchExamples();
    } else {
      playerImage();
      fetchPlayerData();
      fetchTeam();
      fetchPlayerSeasonData();
      fetchLastGameData();
      fetchPlayerSeasons();
    }

    if (
      typeof DeviceOrientationEvent !== "undefined" &&
      // @ts-ignore
      typeof DeviceOrientationEvent.requestPermission === "function"
    ) {
      // @ts-ignore
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === "granted") {
            activateTilt();
            // @ts-ignore
            document.querySelector("#my-div").pointerEvents = "none";
          } else {
            // motion and orientation access is not enabled
          }
        })
        .catch(console.error);
    } else {
      activateTilt();
      document.querySelector("#my-div").pointerEvents = "none";
    }
  }, []);

  return (
    <div className=" w-[100%] max-w-screen h-[100vh] ">
      <div className="relative w-[100%] h-[100%] left-[50%] top-[50%] ">
        <div
          className={`card-container ${
            !example && "fixed"
          } top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] `}
        >
          {" "}
          <div
            id="my-div"
            className=" card select-none sm:w-[340px] lg:w-[500px] sm:h-[625px] lg:h-[875px] left-[50vw] top-[50vh] translate-x-[50vw] flex flex-col justify-center items-center rounded-xl  "
            data-tilt
            data-tilt-gyroscope
          >
            <div className="background-animation"></div>
            <div className="w-[25%] m-2 h-[10%] logoTilt ">
              <img
                className="absolute bottom-4"
                alt="AW logo"
                src={`${process.env.PUBLIC_URL}/assets/img/AW_mini_allblack.png`}
              />
            </div>
            <div className="w-[100%] h-[25%] tilt mb-4">
              {example && imageLinks.length !== 0 ? (
                <div
                  style={{
                    backgroundImage: `url(${imageLinks[currentImageIndex]?.src})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "bottom",
                    maxHeight: "200px",
                    width: "100%",
                  }}
                  className="sm-shadow flex items-end w-fit sm:h-[150px] lg:h-[200px] absolute bottom-2 left-[50%] translate-x-[-50%] transition-all duration-500"
                />
              ) : (
                <div className="w-full h-full z-10">
                  {imgUrl === "" ? (
                    <div className="fade-in relative w-full flex justify-center  items-center h-full">
                      <PersonIcon
                        sx={{ color: "black", fontSize: "96px" }}
                        className="sm-shadow scale-[2] "
                      ></PersonIcon>
                    </div>
                  ) : (
                    <div
                      className="fade-in w-full h-full sm-shadow"
                      style={{
                        backgroundImage: `url(${imgUrl})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "bottom",
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="relative w-[100%] min-h-[11%] font-extrabold sm:text-2xl lg:text-4xl fullName flex flex-col items-center lineheight name">
              <span className="absolute top-0 grow block bg-black w-[70%] sm:min-h-[2px] sm:max-h-[2px] lg:min-h-[4px] lg:max-h-[4px] rounded-full xs-shadow"></span>

              <div className="absolute top-[50%] translate-y-[-50%] ">
                <h1 className="sm:p-1 lg:p-0 xs-shadow">
                  {example ? "PLAYER" : playerData?.firstname}
                </h1>
                <h1 className="sm:p-1 lg:p-0 xs-shadow">
                  {example ? "CARD" : playerData?.lastname}
                </h1>
              </div>
              <span className=" absolute bottom-0 grow block bg-black w-[50%] sm:min-h-[2px] sm:max-h-[2px] lg:min-h-[4px] lg:max-h-[4px] rounded-full xs-shadow"></span>
            </div>
            <div className="w-[100%] h-[60%]  z-10 tilt">
              <Slider
                {...settings}
                ref={sliderRef}
                className="w-[100%] sm:h-[260px] lg:h-[400px]"
              >
                {/* Slide 1 */}
                <div className="page sm:h-[260px] lg:h-[400px] w-[100%] justify-center items-center">
                  <div className=" w-[80%] h-[80%] flex flex-wrap flex-row ">
                    <div className="flex flex-col justify-evenly h-[40%] w-[100%]">
                      <div>
                        <h1 className="font-extrabold sm:text-4xl pt-4 lg:text-7xl text-black xxs-shadow">
                          TEAM
                        </h1>
                        <p className="font-extrabold p-0 h-auto sm:text-2xl lg:text-4xl text-white xs-shadow">
                          {example ? "MONTREAL" : team?.name.toUpperCase()}
                        </p>
                      </div>
                      <div>
                        <h1 className="font-extrabold sm:text-xl lg:text-4xl text-black ">
                          RANKING
                        </h1>
                        <h2 className="font-extrabold sm:text-2xl lg:text-4xl text-white xs-shadow">
                          {example ? "#999" : "#" + rank}
                        </h2>
                      </div>
                    </div>
                    <div className="flex flex-col sm:pt-12 justify-evenly h-[50%] w-[50%] ">
                      <div>
                        <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black">
                          DIVISION
                        </h1>
                        <h2 className="font-extrabold xs-shadow sm:text-lg lg:text-2xl text-white">
                          {example
                            ? "Avanced"
                            : playerData?.divisionID?.titleEn}
                        </h2>
                      </div>
                      <div>
                        <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black ">
                          PPG
                        </h1>
                        <h2 className="font-extrabold xs-shadow sm:text-lg lg:text-2xl text-white">
                          {example ? "22" : playerSeasonData?.ppg.toFixed(1)}
                        </h2>
                      </div>
                    </div>
                    <div className="flex flex-col sm:pt-12 justify-evenly h-[50%] w-[50%]  ">
                      <div>
                        <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black">
                          SEASONS
                        </h1>
                        <h2 className="font-extrabold sm:text-lg lg:text-2xl text-white xs-shadow">
                          {example ? "4" : playerSeasons?.length}
                        </h2>
                      </div>
                      <div>
                        <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black">
                          WINS
                        </h1>
                        <h2 className="font-extrabold xs-shadow sm:text-lg lg:text-2xl text-white">
                          {example ? "18" : allTimeData?.win}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Slide 2 */}
                {playerSeasonData ? (
                  <div className="page sm:h-[260px] lg:h-[400px] w-[100%] flex-col justify-center items-center">
                    {/* <a
                      href="#"
                      className="h-[15%] mt-2 font-extrabold sm:text-2xl lg:text-4xl text-black bg-black hover:bg-red-600 bg-opacity-10 hover:bg-opacity-80 transition focus:ring-2 focus:ring-black rounded-lg px-5 sm:py-[2px] lg:py-[4px] sm:border-2 lg:border-4 border-black   focus:outline-none"
                    >
                      LAST GAME
                    </a> */}
                    <h1 className="h-[15%] mt-2 font-extrabold sm:text-2xl lg:text-4xl text-black">
                      LAST GAME
                    </h1>
                    <div className="w-[80%] h-[100%] flex flex-col justify-center">
                      <div className=" m-0 w-[100%] h-[75%] flex flex-wrap flex-row justify-start ">
                        <div className="flex flex-col justify-around h-[75%] w-[50%]  ">
                          <div>
                            <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black lineheight">
                              RESULT
                            </h1>
                            {lastGameData?.win ? (
                              <div className=" relative flex justify-center items-center ">
                                <h2 className="font-extrabold xs-shadow z-10 sm:text-xl lg:text-3xl text-white ">
                                  Win
                                </h2>
                              </div>
                            ) : (
                              <div className=" relative flex justify-center items-center ">
                                <h2 className="font-extrabold xs-shadow z-10 sm:text-xl lg:text-3xl text-white ">
                                  Loss
                                </h2>
                              </div>
                            )}
                          </div>
                          <div>
                            <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black lineheight">
                              3PTS
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                              {example ? "2" : lastGameData?.threes}
                            </h2>
                          </div>
                        </div>
                        <div className="flex flex-col justify-evenly h-[75%] w-[50%]">
                          <div>
                            <h1 className="w-[95%] font-extrabold leading-4 sm:text-lg lg:text-3xl lineheight text-black">
                              TOTAL POINTS
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                              {example ? "22" : lastGameData?.pts}
                            </h2>
                          </div>
                          <div>
                            <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black lineheight">
                              2PTS
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white ">
                              {example ? "7" : lastGameData?.twos}
                            </h2>
                          </div>
                        </div>
                        <div className="flex flex-col justify-evenly h-[25%] w-[50%]  ">
                          <div>
                            <h1 className="font-extrabold text-black sm:text-lg lg:text-3xl lineheight">
                              FT%
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white sm-shadow">
                              {example
                                ? "100"
                                : lastGameData.freeThrowsAttempts > 0
                                ? (
                                    (lastGameData.freeThrows /
                                      lastGameData.freeThrowsAttempts) *
                                    100
                                  ).toFixed(1)
                                : 0}
                              %
                            </h2>
                          </div>
                        </div>
                        <div className="flex flex-col justify-evenly h-[25%] w-[50%]  ">
                          <div>
                            <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black lineheight">
                              FOULS
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                              {example ? "3" : lastGameData?.fouls}
                            </h2>
                          </div>
                        </div>
                      </div>
                      {/* {!lastGameData?.bestDefender ? (
                        <div className=" relative w-[100%] h-[25%] flex justify-center items-center">
                          <h1 className="bestD absolute font-shadow z-0 sm:text-3xl lg:text-5xl font-extrabold">
                            BEST DEFENDER
                          </h1>
                          <h1 className="bestD sm:text-3xl lg:text-5xl font-extrabold">
                            BEST DEFENDER
                          </h1>
                          <h1 className="clear-both whitespace-nowrap bestD-animate sm:text-3xl lg:text-5xl font-extrabold">
                            BEST DEFENDER
                          </h1>
                        </div>
                      ) : (
                        <div></div>
                      )} */}
                    </div>
                  </div>
                ) : playerSeasonData?.gamesPlayed === 0 ? (
                  <div className="page sm:h-[260px] lg:h-[400px] w-[100%] flex-col justify-center items-center">
                    <h1 className="h-[15%] mt-2 font-extrabold sm:text-2xl lg:text-4xl text-black">
                      LAST GAME
                    </h1>
                    <div className="w-[80%] h-[100%] flex justify-center items-center sm:text-6xl lg:text-7xl font-extrabold ">
                      NO GAME PLAYED
                    </div>
                  </div>
                ) : (
                  <div className="page sm:h-[260px] lg:h-[400px] w-[100%] flex-col justify-center items-center">
                    <h1 className="h-[15%] mt-2 font-extrabold sm:text-2xl lg:text-4xl text-black">
                      LAST GAME
                    </h1>
                    <div className="w-[80%] h-[100%] flex justify-center items-center sm:text-6xl lg:text-7xl font-extrabold ">
                      INACTIF
                    </div>
                  </div>
                )}
                {/* Slide 3 */}
                {playerData?.actif ? (
                  <div className="page sm:h-[260px] lg:h-[400px] w-[100%] flex-col justify-center items-center">
                    {/* <a
                      href="#"
                      className="h-[15%] mt-2 font-extrabold sm:text-2xl lg:text-4xl text-black bg-black hover:bg-red-600 bg-opacity-10 hover:bg-opacity-80 transition focus:ring-2 focus:ring-black rounded-lg px-5 sm:py-[2px] lg:py-[4px] sm:border-2 lg:border-4 border-black   focus:outline-none "
                    >
                      THIS SEASON
                    </a> */}
                    <h1 className="h-[15%] mt-2 font-extrabold sm:text-2xl lg:text-4xl text-black">
                      THIS SEASON
                    </h1>
                    <div className="w-[80%] h-[100%] flex flex-col justify-center">
                      <div className=" m-0 w-[100%] h-[75%] flex flex-wrap flex-row justify-start ">
                        <div className="flex flex-col justify-evenly h-[75%] w-[50%]  ">
                          <div>
                            <h1 className="font-extrabold text-black sm:text-lg lg:text-3xl lineheight">
                              GAMES PLAYED
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                              {example ? "8" : playerSeasonData?.gamesPlayed}
                            </h2>
                          </div>
                          <div>
                            <h1 className="font-extrabold sm:text-lg lg:text-3xl lineheight text-black">
                              3PTS
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                              {example ? "22" : playerSeasonData?.threes}
                            </h2>
                          </div>
                        </div>
                        <div className="flex flex-col justify-evenly h-[75%] w-[50%]">
                          <div>
                            <h1 className=" w-[95%] font-extrabold sm:text-lg lg:text-3xl lineheight text-black">
                              TOTAL POINTS
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                              {example ? "112" : playerSeasonData?.pts}
                            </h2>
                          </div>
                          <div>
                            <h1 className="font-extrabold sm:text-lg lg:text-3xl lineheight text-black">
                              2PTS
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                              {example ? "19" : playerSeasonData?.twos}
                            </h2>
                          </div>
                        </div>
                        <div className="flex flex-col justify-evenly h-[25%] w-[50%]  ">
                          <div>
                            <h1 className="font-extrabold sm:text-lg lg:text-3xl lineheight text-black">
                              FT%
                            </h1>

                            {example ? (
                              <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                                50%
                              </h2>
                            ) : (
                              <h2 className="font-extrabold sm:text-base lg:text-2xl text-white  xs-shadow">
                                {playerSeasonData?.freeThrowsAttempts > 0
                                  ? (
                                      (playerSeasonData?.freeThrows /
                                        playerSeasonData?.freeThrowsAttempts) *
                                      100
                                    ).toFixed(1)
                                  : 0}
                                %
                              </h2>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col justify-evenly h-[25%] w-[50%]  ">
                          <div>
                            <h1 className="font-extrabold sm:text-lg lg:text-3xl lineheight text-black">
                              FOULS
                            </h1>
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                              {example ? "12" : playerSeasonData?.fouls}
                            </h2>
                          </div>
                        </div>
                      </div>
                      {playerSeasonData?.bestDefender !== 0 ? (
                        <div className=" relative w-[100%] h-[25%] flex justify-center items-center">
                          <h1 className="bestD absolute font-shadow3 z-0 sm:text-2xl lg:text-4xl font-extrabold">
                            BEST DEFENDERS {playerSeasonData?.bestDefender} X3
                          </h1>
                          <h1 className="bestD sm:text-2xl lg:text-4xl font-extrabold">
                            BEST DEFENDERS {playerSeasonData?.bestDefender} X3
                          </h1>
                          <h1 className="clear-both whitespace-nowrap bestD-animate sm:text-2xl lg:text-4xl font-extrabold">
                            BEST DEFENDERS {playerSeasonData?.bestDefender} X3
                          </h1>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="page sm:h-[260px] lg:h-[400px] w-[100%] flex-col justify-center items-center">
                    <h1 className="h-[15%] mt-2 font-extrabold sm:text-2xl lg:text-4xl text-black">
                      THIS SEASON
                    </h1>
                    <div className="w-[80%] h-[100%] flex justify-center items-center sm:text-6xl lg:text-7xl font-extrabold ">
                      INACTIF
                    </div>
                  </div>
                )}
                {/* */}

                {/* Slide 4 */}
                <div className="page sm:h-[260px] lg:h-[400px] w-[100%] flex-col justify-center items-center">
                  {/* <a
                    href="#"
                    className="h-[15%] mt-2 font-extrabold sm:text-2xl lg:text-4xl text-black bg-black hover:bg-red-600 bg-opacity-10 hover:bg-opacity-80 transition focus:ring-2 focus:ring-black rounded-lg px-5 sm:py-[2px] lg:py-[4px] sm:border-2 lg:border-4 border-black   focus:outline-none"
                  >
                    ALL TIME
                  </a> */}
                  <h1 className="h-[15%] mt-2 font-extrabold sm:text-2xl lg:text-4xl text-black">
                    ALL TIME
                  </h1>
                  {/* <h2 className=" font-bold sm:text-xs lg:text-base text-black">
                    2016-2023
                  </h2> */}
                  <div className="w-[80%] h-[100%] flex flex-col justify-center">
                    <div className=" m-0 w-[100%] h-[75%] flex flex-wrap flex-row justify-start ">
                      <div className="flex flex-col justify-evenly h-[75%] w-[50%]  ">
                        <div>
                          <h1 className="font-extrabold sm:text-lg lg:text-3xl lineheight text-black">
                            GAMES PLAYED
                          </h1>
                          <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                            {example ? "42" : allTimeData?.gamesPlayed}
                          </h2>
                        </div>
                        <div>
                          <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black lineheight">
                            3PTS
                          </h1>
                          <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                            {example ? "102" : allTimeData?.threes}
                          </h2>
                        </div>
                      </div>
                      <div className="flex flex-col justify-evenly h-[75%] w-[50%]">
                        <div>
                          <h1 className=" w-[95%] font-extrabold sm:text-lg lg:text-3xl lineheight text-black">
                            TOTAL POINTS
                          </h1>
                          <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                            {example ? "504" : allTimeData?.pts}
                          </h2>
                        </div>
                        <div>
                          <h1 className="font-extrabold sm:text-lg lg:text-3xl text-black lineheight">
                            2PTS
                          </h1>
                          <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                            {example ? "82" : allTimeData?.twos}
                          </h2>
                        </div>
                      </div>
                      <div className="flex flex-col justify-evenly h-[25%] w-[50%]  ">
                        <div>
                          <h1 className="font-extrabold sm:text-lg lg:text-3xl lineheight text-black">
                            FT%
                          </h1>
                          {example ? (
                            <h2 className="font-extrabold xs-shadow sm:text-base lg:text-2xl text-white">
                              62%
                            </h2>
                          ) : (
                            <h2 className="font-extrabold sm:text-base lg:text-2xl text-white  xs-shadow">
                              {allTimeData?.freeThrowsAttempts > 0
                                ? (
                                    (allTimeData?.freeThrows /
                                      allTimeData?.freeThrowsAttempts) *
                                    100
                                  ).toFixed(1)
                                : 0}
                              %
                            </h2>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col justify-evenly h-[25%] w-[50%]  ">
                        <div>
                          <h1 className="font-extrabold sm:text-lg lg:text-2xl text-black lineheight">
                            FOULS
                          </h1>
                          <h2 className="font-extrabold xs-shadow  sm:text-base lg:text-2xl text-white">
                            {example ? "102" : allTimeData?.fouls}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="p-4"></div>
                    {/* {allTimeData?.championships !== 0 ? (
                      <div className=" relative w-[100%] h-[25%] flex justify-center items-center">
                        <h1 className="gold absolute font-shadow z-0  sm:text-2xl lg:text-4xl font-extrabold">
                          CHAMPIONSHIPS {allTimeData?.championships}
                        </h1>
                        <h1 className="gold sm:text-2xl lg:text-4xl z-10 font-extrabold">
                          CHAMPIONSHIPS {allTimeData?.championships}
                        </h1>
                        <h1 className="clear-both z-20 whitespace-nowrap cship-animate sm:text-2xl lg:text-4xl font-extrabold">
                          CHAMPIONSHIPS {allTimeData?.championships}
                        </h1>
                      </div>
                    ) : (
                      <div></div>
                    )} */}
                  </div>
                </div>
                {/* Slide 5 */}
                {/* <div className="page sm:h-[260px] lg:h-[400px] w-[120%] flex-col justify-center items-center">
                  <h1 className="h-[15%] mt-2 font-extrabold text-4xl text-black">
                    AWARDS
                  </h1>
                  <div className="relative w-[100%] h-[100%] flex flex-col justify-evenly ">
                    <div className="wrapper">
                      <h1 className="sm:text-[28px] lg:text-[44px] awards-lineheight xs-shadow">
                        BEST DEFENDER x2
                      </h1>
                    </div>
                    <div className="wrapper">
                      <h1 className="sm:text-[28px] lg:text-[44px] awards-lineheight xs-shadow">
                        BEST 3PT
                        <br />
                        SHOOTER MEN
                      </h1>
                    </div>
                    <div className="wrapper flex-wrap">
                      <h1 className="sm:text-[28px] lg:text-[44px] awards-lineheight xs-shadow">
                        BEST 3PT SHOOTER
                        <br /> WOMEN x3
                      </h1>
                    </div>
                    <div className="wrapper">
                      <h1 className="sm:text-[28px] lg:text-[44px] awards-lineheight xs-shadow">
                        BEST SHOOTER
                      </h1>
                    </div>
                  </div>
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerCard;
