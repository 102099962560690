// @ts-ignore
import { useState, useEffect, useContext, Component } from "react";
import Title from "../component/Title";
import GlobalContext from "../GlobalContext";
import Axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
const dayjs = require("dayjs");

// @ts-ignore
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// @ts-ignore
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function SeasonsAdmin() {
  const [loginStatus, setLoginStatus] = useState(false);
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [seasonsData, setSeasonData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [newSeason, setNewSeason] = useState("");
  const [seasonName, setSeasonName] = useState("");
  const [season, setSeason] = useState(0);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [actif, setActif] = useState(false);
  const [registrations, setRegistrations] = useState(false);
  const [submited, setSubmited] = useState(false);

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  Axios.defaults.withCredentials = true;

  const { enqueueSnackbar } = useSnackbar();

  const handleResponse = (msg, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  useEffect(() => {
    fetchSeasons();

    Axios.get(url + "/users/login", {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setLoginStatus(response.data.LoggedIn ? true : false);
    });
  }, []);

  const loadSeason = (season) => {
    setSeasonName(season.name);
    setFrom(season.from);
    setTo(season.to);
    setSeason(season.season);
    setActif(season.actif);
    setRegistrations(season.registrations);
  };

  const handleSeasonChange = (e, index) => {
    loadSeason(seasonsData[index]);
    fetchDivisions(e.target.value);
  };

  const handlePostSeason = () => {
    Axios.post(url + "/seasons", {
      name: newSeason,
    })
      // @ts-ignore
      .then((res) => {
        handleResponse("Successfully Created New Season.", "success");
        fetchSeasons();
      })
      // @ts-ignore
      .catch((res) => {
        handleResponse("Error Creating New Season.", "error");
      });
  };

  const handleSubmit = () => {
    setSubmited(true);
    handlePatchSeason();
  };

  const handlePatchSeason = async () => {
    await Axios.patch(url + "/seasons/" + selectedSeason, {
      name: seasonName,
      from: from,
      to: to,
      season: season,
      actif: actif,
      registrations: registrations,
    })
      // @ts-ignore
      .then(async (res) => {
        handleResponse("Successfully Updated Season.", "success");
        await Axios.get(url + "/seasons")
          .then((response) => {
            setSeasonData(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        setSubmited(false);
        handleDivisionsPatch();
      })
      // @ts-ignore
      .catch((res) => {
        handleResponse("Error Updating Season.", "error");
      });
  };

  const handleDivisionsPatch = async () => {
    //Create SeasonDivision
    for (let i = 0; i < right.length; i++) {
      await Axios.get(
        url +
          "/seasonDivisions/seasonID/" +
          selectedSeason +
          "/divisionID/" +
          right[i]._id
      )
        .then((res) => {
          if (!res.data) {
            postSeasonDivision(selectedSeason, right[i]._id);
          }
        })
        // @ts-ignore
        .catch((res) => {});
    }
    //Delete SeasonDivision
    for (let i = 0; i < left.length; i++) {
      await Axios.get(
        url +
          "/seasonDivisions/seasonID/" +
          selectedSeason +
          "/divisionID/" +
          left[i]._id
      )
        .then((res) => {
          if (res.data) {
            deleteSeasonDivision(res.data);
          }
        })
        // @ts-ignore
        .catch((res) => {});
    }
  };

  const postSeasonDivision = async (seasonID, divisionID) => {
    await Axios.post(url + "/seasonDivisions", {
      seasonID: seasonID,
      divisionID: divisionID,
    })
      // @ts-ignore
      .then((res) => {})
      // @ts-ignore
      .catch((res) => {});
  };

  const deleteSeasonDivision = async (seasonDivision) => {
    await Axios.delete(url + "/seasonDivisions/" + seasonDivision._id)
      // @ts-ignore
      .then((res) => {})
      // @ts-ignore
      .catch((res) => {});
  };

  const fetchSeasons = async () => {
    await Axios.get(url + "/seasons")
      .then((response) => {
        setSeasonData(response.data);
        setSelectedSeason(response.data[0]._id);
        fetchDivisions(response.data[0]._id);
        loadSeason(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchDivisions = async (seasonID) => {
    await Axios.get(url + "/divisions")
      .then(async (response) => {
        await Axios.get(url + "/seasonDivisions/" + seasonID)
          .then((res) => {
            const divisions = [];
            for (let i = 0; i < res.data.length; i++) {
              divisions.push(res.data[i].divisionID._id);
            }

            const divisionsLeft = [];
            const divisionsRight = [];
            for (let i = 0; i < response.data.length; i++) {
              if (!divisions.includes(response.data[i]._id)) {
                divisionsLeft.push(response.data[i]);
              } else if (divisions.includes(response.data[i]._id)) {
                divisionsRight.push(response.data[i]);
              }
            }
            setLeft(divisionsLeft);
            setRight(divisionsRight);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // @ts-ignore
  const handleActif = (e) => {
    setActif(!actif);
  };

  // @ts-ignore
  const handleRegistrations = (e) => {
    setRegistrations(!registrations);
  };

  const customList = (items) => (
    <Paper
      sx={{
        width: 200,
        height: 230,
        overflow: "auto",
        marginTop: "20px",
        border: "solid 2px white",
      }}
    >
      {" "}
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value._id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.titleEn} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <div className="min-h-screen">
      {loginStatus && (
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="Seasons"></Title>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              Create New Season
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
            </div>
            <div className="flex items-center">
              <FormControl sx={{ m: 1 }}>
                <TextField
                  value={newSeason}
                  label="New Season"
                  variant="outlined"
                  onChange={(e) => setNewSeason(e.target.value)}
                  type="text"
                ></TextField>
              </FormControl>
              <Fab
                aria-label="add"
                color="info"
                onClick={handlePostSeason}
                className="zoom z-0"
                sx={{ zIndex: 0 }}
              >
                <AddIcon
                  fontSize="large"
                  sx={{ transition: "100ms" }}
                ></AddIcon>
              </Fab>
            </div>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:my-4 lg:my-8 text-4xl font-extrabold stats-font p-2">
              Edit Season
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
            </div>
            <div className="flex flex-col">
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="season-label">Season</InputLabel>
                <Select
                  labelId="season-label"
                  label={"Season"}
                  onChange={(e) => {
                    setSelectedSeason(e.target.value);
                    handleSeasonChange(
                      e,
                      seasonsData
                        .map((item) => item._id)
                        .indexOf(e.target.value)
                    );
                  }}
                  value={selectedSeason}
                >
                  {seasonsData.map((season, index) => (
                    <MenuItem value={season._id} key={index}>
                      {season.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <div className="flex justify-center m-4">
                  <FormControlLabel
                    control={<Switch checked={actif} />}
                    label="Actif"
                    onChange={(e) => {
                      handleActif(e);
                    }}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    control={<Switch checked={registrations} />}
                    label="Registrations"
                    onChange={(e) => {
                      handleRegistrations(e);
                    }}
                    labelPlacement="start"
                  />
                </div>
              </FormControl>
              <FormControl>
                <p className="text-center">Season Name</p>
                <TextField
                  value={seasonName}
                  label="Season Name"
                  variant="outlined"
                  onChange={(e) => setSeasonName(e.target.value)}
                  type="text"
                ></TextField>
              </FormControl>
              <FormControl>
                <div className="flex flex-row">
                  <div className="w-1/2 pr-2 flex flex-col">
                    <p className="text-center">From</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // @ts-ignore
                        onChange={(e) => setFrom(e.$d)}
                        value={dayjs(from)}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="w-1/2 pl-2">
                    <p className="text-center">To</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // @ts-ignore
                        onChange={(e) => setTo(e.$d)}
                        value={dayjs(to)}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </FormControl>
              <FormControl>
                <p className="text-center">Season</p>
                <Select
                  // @ts-ignore
                  onChange={(e) => setSeason(e.target.value)}
                  value={season}
                >
                  <MenuItem value={0}>Autumn</MenuItem>
                  <MenuItem value={1}>Winter</MenuItem>
                  <MenuItem value={2}>Spring</MenuItem>
                  <MenuItem value={3}>Summer</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <p className="text-center mt-8">Divisons</p>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>{customList(left)}</Grid>
                  <Grid item>
                    <Grid container direction="column" alignItems="center">
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                      >
                        ≫
                      </Button>
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                      >
                        &lt;
                      </Button>
                      <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                      >
                        ≪
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>{customList(right)}</Grid>
                </Grid>
              </FormControl>
              <div>
                {right.map((division, index) => (
                  <div
                    className="w-full fade-in p-8 my-4 border-4 border-gray-400 rounded-md bgBlack text-center font-bold"
                    key={index}
                  >
                    <DivisionTeams
                      division={division}
                      season={selectedSeason}
                      submit={submited}
                    ></DivisionTeams>
                  </div>
                ))}
              </div>
              <div className="flex justify-center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  aria-label="Submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const DivisionTeams = ({ division, season, submit }) => {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [divisionTeams, setDivisionTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const fetch = async () => {
    await Axios.get(url + "/teams/division/" + division._id).then(
      (response) => {
        const teams = response.data.map((team) => ({
          label: team.name,
          value: team._id,
        }));

        setDivisionTeams(teams);

        fetchTeams();
      }
    );
  };

  const fetchTeams = async () => {
    await Axios.get(
      url + "/teamSeasons/season/" + season + "/division/" + division._id
    ).then((response) => {
      const teams = response.data
        .filter((team) => team.actif === true)
        .map((team) => ({
          label: team.teamID.name,
          value: team.teamID._id,
        }));

      setSelectedTeams(teams);
    });
  };

  const submitTeamSeasons = async (teams) => {
    await Axios.get(
      url +
        "/teamSeasons/seasonID/" +
        season +
        "/divisionID/" +
        division._id +
        "/setInactif"
    )
      // @ts-ignore
      .then((response) => {})
      // @ts-ignore
      .catch((res) => {});

    for (let i = 0; i < teams.length; i++) {
      await Axios.get(
        url + "/teamSeasons/seasonID/" + season + "/teamID/" + teams[i].value
      )
        .then((response) => {
          if (response.data) {
            patchTeamSeasons(response.data);
          } else {
            postTeamSeasons(season, teams[i].value);
          }
        })
        // @ts-ignore
        .catch((res) => {});
    }
  };

  const postTeamSeasons = async (seasonID, teamID) => {
    await Axios.post(url + "/teamSeasons", {
      seasonID: seasonID,
      teamID: teamID,
    })
      // @ts-ignore
      .then((res) => {})
      // @ts-ignore
      .catch((res) => {});
  };

  const patchTeamSeasons = async (teamSeason) => {
    await Axios.patch(url + "/teamSeasons/" + teamSeason._id, { actif: true })
      // @ts-ignore
      .then((res) => {})
      // @ts-ignore
      .catch((res) => {});
  };

  // @ts-ignore
  const handleSelection = (event, values) => {
    setSelectedTeams(values);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (submit) {
      console.log(division);
      submitTeamSeasons(selectedTeams);
    }
  }, [submit]);

  return (
    <div className="flex justify-center items-center flex-col font-Poppins">
      <h1 className="p-2 text-2xl">{division.titleEn}</h1>
      <h2 className="font-medium pb-2">Select Teams</h2>
      <Autocomplete
        multiple
        id="tags-standard"
        options={divisionTeams}
        getOptionLabel={(option) => option.label}
        value={selectedTeams}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={handleSelection}
        renderInput={(params) => (
          <TextField {...params} variant="standard" sx={{ width: "400px" }} />
        )}
      />
    </div>
  );
};
export default SeasonsAdmin;
