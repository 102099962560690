import React from "react";
import PlayerCard from "./PlayerCard";
import { useEffect, useState, useRef } from "react";

function CardContainer({ onData, prop }) {
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState(null);
  const [dragDistance, setDragDistance] = useState(0);
  const [componentHeight, setComponentHeight] = useState(0);
  const containerRef = useRef(null);

  function handleMouseDown(event) {
    setIsDragging(true);
    setDragStart(event.clientY);
  }

  function handleMouseMove(event) {
    if (isDragging) {
      const distance = event.clientY - dragStart;
      setDragDistance(distance);
    }
  }

  function handleMouseUp() {
    setIsDragging(false);
    setDragStart(null);
    setDragDistance(0);

    if (dragDistance <= -220) {
      setTimeout(function () {
        document.body.style.overflow = "visible";
      }, 400);
      const render = false;
      onData(render);
    }
  }

  function handleMouseLeave() {
    setIsDragging(false);
    setDragStart(null);
    setDragDistance(0);
  }

  function handleTouchStart(event) {
    setIsDragging(true);
    setDragStart(event.touches[0].clientY);
  }

  function handleTouchMove(event) {
    if (isDragging) {
      const distance = event.touches[0].clientY - dragStart;
      setDragDistance(distance);
    }
  }

  function handleTouchEnd() {
    setIsDragging(false);
    setDragStart(null);
    setDragDistance(0);
    if (dragDistance <= -220) {
      document.body.style.overflow = "visible";
      const render = false;
      onData(render);
    }
  }

  function handleGlobalMouseMove(event) {
    if (isDragging) {
      const distance = event.clientY - dragStart;
      setDragDistance(distance);
    }
  }

  function handleGlobalTouchMove(event) {
    if (isDragging) {
      const distance = event.touches[0].clientY - dragStart;
      setDragDistance(distance);
    }
  }

  function handleGlobalMouseUp() {
    setIsDragging(false);
  }

  function handleGlobalTouchEnd() {
    setIsDragging(false);
  }

  const containerStyle = {
    transform: `translateY(${
      dragDistance < 0 ? dragDistance : 0
    }px) translate(-50%, -50%)`,
    opacity:
      dragDistance < 0 ? 1 - 2 * (Math.abs(dragDistance) / componentHeight) : 1,
  };

  const closeBtnStyle = {
    transform: `translateY(${
      dragDistance < 0 ? dragDistance : 0
    }px) translate(-50%, -50%)`,
    opacity:
      dragDistance < 0 ? 4 * (Math.abs(dragDistance) / componentHeight) : 0,
    backgroundColor: dragDistance <= -220 ? `#d40000` : `black`,
    transition: "transform 0.1s ease-out, opacity 0.1s ease-out",
    WebkitTransition: "transform 0.1s ease-out, opacity 0.1s ease-out",
    MozTransition: "transform 0.1s ease-out, opacity 0.1s ease-out",
    willChange: "transform, opacity",
  };

  const closeBtnStyle1 = {
    transform: `rotate(${dragDistance <= -220 ? 45 : 0}deg)`,
  };
  const closeBtnStyle2 = {
    transform: `rotate(${dragDistance <= -220 ? -45 : 0}deg)`,
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("mousemove", handleGlobalMouseMove);
    document.addEventListener("touchmove", handleGlobalTouchMove);
    document.addEventListener("mouseup", handleGlobalMouseUp);
    document.addEventListener("touchend", handleGlobalTouchEnd);
    setComponentHeight(containerRef.current.offsetHeight);
    return () => {
      document.removeEventListener("mousemove", handleGlobalMouseMove);
      document.removeEventListener("touchmove", handleGlobalTouchMove);
      document.removeEventListener("mouseup", handleGlobalMouseUp);
      document.removeEventListener("touchend", handleGlobalTouchEnd);
    };
  }, [isDragging]);

  return (
    <div className="fixed w-[100%] h-[100vh] top-0 z-50">
      <div className="absolute top-0 left-0 w-[100%] h-[100vh]">
        <div className="blury bg-black bg-opacity-80 absolute left-0 top-0 w-[100%] h-[100vh]"></div>
        <div
          className="close-icon absolute left-[50%] translate-x-[-50%] top-[100%] z-0"
          style={closeBtnStyle}
        >
          <span style={closeBtnStyle1}></span>
          <span style={closeBtnStyle2}></span>
        </div>
        <div
          className="absolute left-[50%] sm:top-[50%] lg:top-[52%] will-change-transform w-[100%] h-[100vh] flex"
          style={containerStyle}
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <PlayerCard className="z-10" id={prop}></PlayerCard>
        </div>
      </div>
    </div>
  );
}

export default CardContainer;
