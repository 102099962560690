import Home from "./Home";
import Teams from "./Teams";
import Team from "./Team";
import Players from "./Players";
import Schedules from "./Schedules";
import Game from "./Game";
import Standings from "./Standings";
import About from "./About";
import FAQ from "./FAQ";
import Photos from "./Photos";
import Login from "./Login";
import Registrations from "./Registrations";
import Admin from "./Admin";
import SeasonsAdmin from "./admin/SeasonsAdmin";
import DivisionsAdmin from "./admin/DivisionsAdmin";
import GamesAdmin from "./admin/GamesAdmin";
import PlayersAdmin from "./admin/PlayersAdmin";
import RegistrationsAdmin from "./admin/RegistrationsAdmin";
import TeamsAdmin from "./admin/TeamsAdmin";
import FAQAdmin from "./admin/FAQAdmin";
import Player from "./Player";
import CardContainer from "./component/CardContainer";
import Nav from "./component/Nav";
import Footer from "./component/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
import GlobalContext from "./GlobalContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Axios from "axios";
import Contact from "./Contact";
import NotFound from "./NotFound";
import LocationsAdmin from "./admin/LocationsAdmin";
import LocationPage from "./LocationPage";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: `Poppins`,
  },
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [globalURL, setGlobalURL] = useState(
    "https://afterworkserver.onrender.com"
  );
  // const [globalURL, setGlobalURL] = useState("http://localhost:8000");
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");

  const images = [
    {
      src: `${process.env.PUBLIC_URL}/assets/img/awbg.JPG`,
      classes:
        "top-1/2 sm:left-2/3 md:left-1/3 md:w-[140vw] sm:w-[200vw] translate-x-[-50%] translate-y-[-50%]",
      imgClasses: "top-1/2 left-1/2  background-animate",
    },
    {
      src: `${process.env.PUBLIC_URL}/assets/img/awbg2.JPG`,
      classes: "top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]",
      imgClasses: "top-1/2 left-1/2 background-animate2",
    },
    {
      src: `${process.env.PUBLIC_URL}/assets/img/awbg3.JPG`,
      classes:
        "top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] border-2 ",
      imgClasses: "background-animate3 top-1/2 left-1/2 ",
    },
  ];

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    setInterval(() => {
      if (localStorage.refresh) {
        Axios.post(globalURL + "/users/refresh", {
          refreshToken: localStorage.refresh,
        })
          .then((res) => {
            localStorage.setItem("token", res.data.newAccessToken);
            localStorage.setItem("refresh", res.data.refreshToken);
          })
          .catch((err) => {
            if (localStorage.token) {
              localStorage.removeItem("token");
            }
            localStorage.removeItem("refresh");
          });
      }
    }, 600000);
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Preload images
    const loadImage = (index) => {
      const img = new Image();
      img.src = images[index].src;
      img.onload = () => {
        // Image is loaded, set the class and trigger the interval
        setCurrentIndex(index);
      };
    };

    const intervalId = setInterval(() => {
      // Increment the index for the next iteration
      const nextIndex = (currentIndex + 1) % images.length;
      loadImage(nextIndex);
    }, 60000);

    // Load the first image when the component mounts
    loadImage(currentIndex);

    // Clear the interval on component unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [images.length, images]);

  const handleRender = (render) => {
    setShow(render);
  };

  const handleData = (playerID) => {
    setId(playerID);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App relative min-h-screen ">
        <div
          className={`min-w-[140vw] h-[140vh] w-fit fixed ${images[currentIndex].classes}`}
        >
          <img
            src={images[currentIndex].src}
            className={`select-none opacity-0 bgBlack min-w-[140vw] absolute w-full min-h-[140vh] object-cover ${images[currentIndex].imgClasses}`}
          />
        </div>
        <span className="body-bg w-[140vw] h-[140vh] fixed top-0"></span>
        <GlobalContext.Provider value={{ globalURL, setGlobalURL }}>
          <Router basename="/">
            <ScrollToTop />
            <div className="min-h-screen">
              <Nav />

              <Routes>
                <Route path="/" element={<Navigate to="/Home" />} />

                {/* Route for the Home component */}
                <Route path="/Home" element={<Home />} />
                <Route path="/teams" element={<Teams />} />
                <Route
                  path="/players"
                  element={<Players onData={handleRender} prop={handleData} />}
                />
                <Route path="/schedules" element={<Schedules />} />
                <Route
                  path="/standings"
                  element={
                    <Standings onData={handleRender} prop={handleData} />
                  }
                />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/FAQ" element={<FAQ />} />
                <Route path="/photos" element={<Photos />} />
                <Route path="/login" element={<Login />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/registrations" element={<Registrations />} />
                <Route path="/admin/seasons" element={<SeasonsAdmin />} />
                <Route path="/admin/divisions" element={<DivisionsAdmin />} />
                <Route path="/admin/players" element={<PlayersAdmin />} />
                <Route
                  path="/admin/registrations"
                  element={<RegistrationsAdmin />}
                />
                <Route path="/admin/teams" element={<TeamsAdmin />} />
                <Route path="/admin/FAQ" element={<FAQAdmin />} />
                <Route path="/admin/games" element={<GamesAdmin />} />
                <Route path="/admin/locations" element={<LocationsAdmin />} />
                <Route path="/location/:id" element={<LocationPage />} />
                <Route
                  path="/players/:id"
                  element={<Player onData={handleRender} prop={handleData} />}
                />
                <Route
                  path="/team/:id"
                  element={<Team onData={handleRender} prop={handleData} />}
                />
                <Route
                  path="/game/:id"
                  element={<Game onData={handleRender} prop={handleData} />}
                />
                <Route path="/*" element={<NotFound />} />
              </Routes>
              {show && <CardContainer onData={handleRender} prop={id} />}
            </div>
            <Footer />
          </Router>
        </GlobalContext.Provider>
      </div>
    </ThemeProvider>
  );
}

export default App;

// export default App;
