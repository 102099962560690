import { useNavigate } from "react-router-dom";
import "./Confirmation.css";
import Button from "@mui/material/Button";
import { useState, useEffect, useContext } from "react";
import GlobalContext from "../GlobalContext";

function Confirmation({ email = null }) {
  const navigate = useNavigate();

  const handleClick = () => {
    // Replace '/destination' with the path of the page you want to redirect to
    navigate("/");
  };
  return (
    <div className="fade-in zoom relative border-2 border-white aspect-3/4 max-w-[300px] w-[80%] sm-shadow rounded-lg">
      <div className=" relative top-[-10%] left-[50%] translate-x-[-50%] h-1/4 translate-y-[50%] border-2 border-transparent">
        <div className="swal-icon swal-icon--success">
          <span className="swal-icon--success__line swal-icon--success__line--long"></span>
          <span className="swal-icon--success__line swal-icon--success__line--tip"></span>
          <div className="swal-icon--success__ring"></div>
          <div className="swal-icon--success__hide-corners"></div>
        </div>
      </div>
      {email ? (
        <div className="w-full h-2/3 absolute bottom-0 text-center pt-8">
          <h1 className="text-2xl ">Thank You!</h1>
          <h2 className=" ">Registration Completed</h2>
          <p className=" p-4 pb-6">
            You will receive a confirmation email shortly at <b>{email}</b>
          </p>
          <Button
            onClick={handleClick}
            variant="outlined"
            sx={{
              fontWeight: 800,
              background: "transparent",
              color: "white",
              border: "solid 1px white",
              fontFamily: "Poppins",
              "&:hover": { backgroundColor: "black" },
            }}
            color="success"
            size="large"
            className="sm-shadow"
          >
            Done
          </Button>
        </div>
      ) : (
        <div className="w-full h-2/3 absolute bottom-0 text-center pt-8">
          <h1 className="text-2xl ">Thank You!</h1>
          <p className=" p-4 pb-6">Your message as been sent.</p>
          <Button
            onClick={handleClick}
            variant="outlined"
            sx={{
              fontWeight: 800,
              background: "transparent",
              color: "white",
              border: "solid 1px white",
              fontFamily: "Poppins",
              "&:hover": { backgroundColor: "black" },
            }}
            color="success"
            size="large"
            className="sm-shadow"
          >
            Done
          </Button>
        </div>
      )}
    </div>
  );
}

export default Confirmation;
