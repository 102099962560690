import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import Axios from "axios";
import GlobalContext from "../GlobalContext";

function Nav() {
  const isLargeScreen = useMediaQuery({ minWidth: 1024 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(999999);
  const [active, setActive] = useState(false);
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [loginStatus, setLoginStatus] = useState(false);
  const location = useLocation();

  Axios.defaults.withCredentials = true;

  const dropDownRef = useRef(null);
  const ddlRef = useRef([]);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (
        window.scrollY > lastScrollY &&
        lastScrollY !== 0 &&
        window.scrollY > 150
      ) {
        // if scroll down hide the navbar

        setHide(true);
      } else {
        // if scroll up hide the navbar
        setHide(false);
      }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = () => {
    setIsOpen(false);
  };

  const handleUnfocus = () => {
    // This function will be called when the input element loses focus
    handleItemClick();
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      if (isMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }

      const handleFocusOut = (event) => {
        if (!dropDownRef.current.contains(event.target)) {
          handleUnfocus();
        }
      };

      document.addEventListener("focusout", handleFocusOut);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
        //  document.removeEventListener("focusout", handleFocusOut);
      };
    }

    setHide(false);
  }, [lastScrollY, isMenuOpen]);

  useEffect(() => {
    setActive(false);
    setIsMenuOpen(false);
    ddlRef.current.forEach((ref, index) => {
      if (ref && ref.classList.contains("active")) {
        setActive(true);
      }
    });
  }, [location]);

  useEffect(() => {
    if (localStorage.refresh) {
      Axios.get(url + "/users/login", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          setLoginStatus(response.data.LoggedIn ? true : false);
        })
        .catch((err) => {
          if (localStorage.token) {
            localStorage.removeItem("token");
          }
          localStorage.removeItem("refresh");
        });
    } else setLoginStatus(false);
  }, [location]);

  useEffect(() => {
    if (isMenuOpen) {
      toggleMenu();
    }
  }, [isLargeScreen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <nav
        className={`activeNav fixed will-change-auto border-black z-40 navbar shadow-sm sm:min-h-[3.5rem] lg:min-h-[70px]  ${
          hide && "hiddenNav"
        } ${isMenuOpen ? "h-[100vh]" : "h-[70px]"} `}
      >
        <div className="absolute h-[70px] w-fit lg:hidden right-[3%] sm:mr-6 top-0 min-h-[3.5rem]">
          <button
            className={`navbar-toggle z-10 ${
              isMenuOpen ? "open" : ""
            } absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]`}
            onClick={toggleMenu}
          >
            <span className="toggle-icon rounded-full"></span>
            <span className="toggle-icon rounded-full"></span>
            <span className="toggle-icon rounded-full"></span>
          </button>
        </div>
        <div className="navbar-brand absolute w-[100%] h-[70px] top-0 flex items-center min-h-[3.5rem] ">
          <NavLink to="/" className="z-10">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/AW_mini_allblack.png`}
              className="w-14 ml-6 select-none sm-shadow"
            />
          </NavLink>
        </div>

        <div className="w-[100%] sm:h-[80%]  flex justify-center ">
          <div className="sm:w-[80%] lg:w-[60%] h-[100%] relative  flex justify-center">
            {!isLargeScreen && isMenuOpen && (
              <span className="w-[100%] sm-shadow h-1 rotate-180 bg-black absolute bottom-0 rounded-full z-40"></span>
            )}
            <div
              className={`flex flex-col w-[100%] h-[100%] relative items-center sm:overflow-auto lg:overflow-visible `}
            >
              <ul
                className={`navbar-menu text-black lg:flex w-[100%] justify-around  text-center sm:text-5xl lg:text-2xl font-bold items-center ${
                  isMenuOpen ? "open fade-in" : ""
                }`}
              >
                <NavLink to="/" className="h-fit">
                  <li className="smallCaps ">
                    <span data-content="H">H</span>
                    <span data-content="OME">OME</span>
                  </li>
                </NavLink>
                <div className="relative">
                  <button onClick={toggleDropdown} className="NavLink">
                    <div
                      className={`${
                        active ? "active" : isOpen ? "ddlActive " : ""
                      } flex flex-nowrap z-10 `}
                    >
                      <div className="z-10 smallCaps">
                        <span data-content="R">R</span>
                        <span data-content="OSTER">OSTER</span>
                      </div>
                      <span
                        data-content="▼"
                        className={`inline-block  ${
                          isOpen ? "scale-y-[-1]" : ""
                        } `}
                      >
                        ▼
                      </span>
                    </div>
                  </button>
                  {""}
                  <ul
                    ref={dropDownRef}
                    className={`absolute left-[50%] sm:top-14 lg:top-11 w-[150%] bg-red-700 border-4 border-black z-10 ${
                      isOpen ? "ddlOpen" : "ddl"
                    }`}
                  >
                    <NavLink
                      to="/teams"
                      onClick={handleItemClick}
                      ref={(el) => (ddlRef.current[1] = el)}
                    >
                      <li
                        className={
                          "w-[100%]  hover:bg-red-600 hover:bg-opacity-50  p-2 smallCaps"
                        }
                      >
                        <span data-content="T">T</span>
                        <span data-content="EAMS">EAMS</span>
                      </li>
                    </NavLink>
                    <NavLink
                      to="/players"
                      onClick={handleItemClick}
                      ref={(el) => (ddlRef.current[0] = el)}
                    >
                      <li
                        className={
                          "w-[100%]  hover:bg-red-600 hover:bg-opacity-50  p-2 smallCaps"
                        }
                      >
                        <span data-content="P">P</span>
                        <span data-content="LAYERS">LAYERS</span>
                      </li>
                    </NavLink>
                  </ul>
                </div>

                <NavLink to="/schedules">
                  <li className="smallCaps">
                    <span data-content="S">S</span>
                    <span data-content="CHEDULES">CHEDULES</span>
                  </li>
                </NavLink>
                <NavLink to="/standings">
                  <li className="smallCaps">
                    <span data-content="S">S</span>
                    <span data-content="TANDINGS">TANDINGS</span>
                  </li>
                </NavLink>
                <NavLink to="/about">
                  <li className="smallCaps">
                    <span data-content="A">A</span>
                    <span data-content="BOUT">BOUT</span>
                  </li>
                </NavLink>
                <NavLink to="/FAQ">
                  <li className="smallCaps">
                    <span data-content="FAQ">FAQ</span>
                    <span></span>
                  </li>
                </NavLink>
                <NavLink to="/photos">
                  <li className="smallCaps">
                    <span data-content="P">P</span>
                    <span data-content="HOTOS">HOTOS</span>
                  </li>
                </NavLink>
                {loginStatus ? (
                  <NavLink to="/admin">
                    <li className="smallCaps">
                      <span data-content="A">A</span>
                      <span data-content="DMIN">DMIN</span>
                    </li>
                  </NavLink>
                ) : (
                  <NavLink to="/login">
                    <li className="smallCaps">
                      <span data-content="L">L</span>
                      <span data-content="OGIN">OGIN</span>
                    </li>
                  </NavLink>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Nav;
