function MiniTitle({ word }) {
  const miniTitle = Array.from(word).map((letter, index) => (
    <div className="relative sm:text-2xl lg:text-3xl" key={index}>
      <span
        data-content={`${letter}`}
        className="absolute fontRed z-20 font-extrabold text-white"
      >
        {letter}
      </span>
      <span
        data-content={`${letter}`}
        className="customShadow z-10 font-extrabold"
      >
        {letter}
      </span>
    </div>
  ));

  return <div className=" miniTitle">{miniTitle}</div>;
}

export default MiniTitle;
