import * as React from 'react'
import ReactDOM from 'react-dom/client'
import RememberMeIcon from "@mui/icons-material/RememberMe";
import { Button, FormControl, InputLabel } from '@mui/material';
import GlobalContext from '../GlobalContext';
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import Pagination from "@mui/material/Pagination";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ReorderIcon from "@mui/icons-material/Reorder";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import Axios from "axios";


import {
  Column,
  Table as ReactTable,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  ColumnDef,
} from '@tanstack/react-table'
import { useCallback, useContext, useEffect, useState } from 'react';
import PlayerProfile from './PlayerProfile';

type PlayerSeason = {
  playerID: {
    _id: string;
    firstname: string;
    lastname: string;
  };
  ppg: number;
  gamesPlayed: number;
  pts: number;
  threes: number;
  twos: number;
  freeThrows: number;
  freeThrowsAttempts: number;
};



function PlayersData ({ onData, prop }:{onData : any, prop: any}) {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [seasonsData, setSeasonData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [seasonDivisionData, setSeasonDivisionData] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [num, setNum] = useState(10);

  const [data, setData] = useState([]);
  Axios.defaults.withCredentials = true;

  const fetchSeasons = async () => {
    try {
      const response = await Axios.get(url + "/seasons");
      setSeasonData(response.data);
      if (response.data.length) {
        setSelectedSeason(response.data[0]._id);
        fetchSeasonDivisions(response.data[0]._id);
      }
    } catch (error) {
      console.error("Error fetching seasons data:", error);
    }
  };

  const fetchSeasonDivisions = async (seasonID) => {
    try {
      const response = await Axios.get(url + "/seasonDivisions/season/" + seasonID);
      setSeasonDivisionData(response.data);
      if (response.data.length) {
        setSelectedDivision(response.data[0].divisionID._id);
        fetchData(seasonID, response.data[0].divisionID._id);
      } else {
        setSelectedDivision("");
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching season divisions data:", error);
    }
  };

  const fetchData = async (seasonID, divisionID) => {
    try {
      const response = await Axios.get(url + `/playerSeasons/season/${seasonID}/division/${divisionID}`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching player data:", error);
    }
  };

  useEffect(() => {
    fetchSeasons();
  }, [url]);

  const handleSeasonChange = useCallback((seasonID) => {
    fetchSeasonDivisions(seasonID);
    setSelectedSeason(seasonID);
  }, [fetchSeasonDivisions]);

  const handleDivisionChange = useCallback((divisionID) => {
    fetchData(selectedSeason, divisionID);
    setSelectedDivision(divisionID);
  }, [fetchData, selectedSeason]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [grid, setGrid] = useState(false);
  const renderGrid = () => {
    setGrid(!grid);
  };

  const columns = React.useMemo(() => [
    {
      header: "Info",
      columns: [
        {
          header: "Full Name",
          accessorFn: (row) =>
            `${row.playerID.firstname} ${row.playerID.lastname}`,
          cell: (info) => (
            <Button variant="outlined"
            color="inherit" sx={{
              bgcolor: "white",
              width: "100%",
              color: "black",
              fontWeight: "600",
              fontFamily:"Poppins",
              ":hover": { color: "white" },
            }} href={`/players/${info.row.original.playerID._id}`}>
              {info.getValue()}
            </Button>
          ),
          sortDescFirst: true,
        },
        {
          id: "_id",
          header: "⭐",
          accessorFn: (row) => `${row.playerID._id}`,
          cell: (info) => (
            <button onClick={()=>{onData(true); prop(info.getValue())}} className="hover:text-[#ff0000] transition-all duration-500">
              <RememberMeIcon />
            </button>
          ),
          enableSorting: false,
          enableColumnFilter: false,
        },
      ],
    },
    {
      header: "Stats",
      columns: [
        {
          header: "GP",
          accessorKey: "gamesPlayed",
          cell: (info) => info.getValue(),
          enableColumnFilter: false,
        },
        {
          header: "PPG",
          accessorKey: "ppg",
          cell: (info) => Number(info.getValue()).toFixed(1),
          enableColumnFilter: false,
        },
        {
          header: "PTS",
          accessorKey: "pts",
          cell: (info) => info.getValue(),
          enableColumnFilter: false,
        },
        {
          header: "3PTS",
          accessorKey: "threes",
          cell: (info) => info.getValue(),
          enableColumnFilter: false,
        },
        {
          header: "2PTS",
          accessorKey: "twos",
          cell: (info) => info.getValue(),
          enableColumnFilter: false,
        },
        {
          header: "FTM",
          accessorKey: "freeThrows",
          cell: (info) => info.getValue(),
          enableColumnFilter: false,
        },
        {
          header: "FTA",
          accessorKey: "freeThrowsAttempts",
          cell: (info) => info.getValue(),
          enableColumnFilter: false,
        },
        {
          header: "FT%",
          accessorFn: (row) => `${row.freeThrowsAttempts>0? (row.freeThrows / row.freeThrowsAttempts)*100: 0}`,
          cell: (info) => (
            <span>{ Number(info.getValue()).toFixed(1)}%</span>
          ),
          enableColumnFilter: false,
          sortDescFirst: true,
        },
      ],
    },
  ], [data]);

  const table = useReactTable({
    data,
      columns,
      // Pipeline
      state: {
        sorting,
      },
      initialState: {
        pagination: {
          pageIndex: 0,
          pageSize: num,
        },
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    
  })
  
  const { pageSize, pageIndex } = table.getState().pagination;
  const { rows } = table.getFilteredRowModel();

  const [ids, setIds] = useState([]);

  const fetchIds = useCallback(() => {
    const tableBody = document.getElementById("tableBody");
    const rows = tableBody.getElementsByTagName("tr");

    const extractedIds = [];
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const id = row.getAttribute("id");
      extractedIds.push(id);
    }
    setIds(extractedIds);
  }, [pageSize, pageIndex, rows]);

  useEffect(() => {
    fetchIds();
  }, [fetchIds]);

    useEffect(() => {
    console.log(columns)
  }, [columns]);

  const totalPages = Math.ceil(table.getFilteredRowModel().rows.length / pageSize)

  return (<><div className="w-fit flex sm:justify-center items-center sm:flex-col lg:flex-row">
  <FormControl sx={{ m: 1, minWidth: 200 }}>
    <InputLabel id="season-label"><Typography>Season</Typography></InputLabel>
    <Select
      labelId="season-label"
      label={<Typography >Season</Typography>}
      onChange={(e) => handleSeasonChange(e.target.value)}
      value={selectedSeason}
    >
      {seasonsData.map((season, index) => (
        <MenuItem value={season._id} key={index}>
          {season.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  <FormControl sx={{ m: 1, minWidth: 200 }}>
    <InputLabel id="division-label">Division</InputLabel>
    <Select
      labelId="division-label"
      label={"Division"}
      onChange={(e) => handleDivisionChange(e.target.value)}
      value={selectedDivision}
    >
      {seasonDivisionData.map((seasonDivision, index) => (
        <MenuItem value={seasonDivision.divisionID._id} key={index}>
          {seasonDivision.divisionID.titleEn}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</div>
<Box sx={{ width: "100%" }} className="fade-in">
      <div className="relative my-6 w-[100%] flex justify-center flex-col items-center">
        <div className="mb-5">
          <Button variant="contained" disabled={!grid} sx={{ margin: "4px", bgcolor: "white", color: "black", fontWeight: "600", ":hover": { bgcolor: "#ff0000" } }} onClick={renderGrid} ><ReorderIcon></ReorderIcon></Button>
          <Button variant="contained" disabled={grid} sx={{ margin: "4px", bgcolor: "white", color: "black", fontWeight: "600", ":hover": { bgcolor: "#ff0000" } }} onClick={renderGrid}><ViewCompactIcon></ViewCompactIcon></Button>
        </div>

        <Pagination
          variant="outlined"
          count={totalPages}
          onChange={(_, value) => {
            table.setPageIndex(value - 1);
          }}
          page={pageIndex + 1}
          size="large"
          sx={{ '& .MuiPaginationItem-page.Mui-selected': { color: 'red', border: "red solid 2px" } }}
        ></Pagination>
      </div>

      <TableContainer className="box">
        <div className="boxbox">
          <Table className="tableHover">
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableCell
                        key={header.id}
                        colSpan={header.colSpan}
                        sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            <div>
                              {header.column.getCanFilter() ? (
                                <div className="flex justify-center">
                                  <Filter
                                    column={header.column}
                                    table={table}
                                  /> 
                                </div>
                              ) : null}
                            </div>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none flex-col flex align-center items-center sortLabel"
                                  : "inline-block flex justify-center ",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}{" "}
                              {header.column.getCanSort() &&
                                header.column.getIsSorted() !== "asc" &&
                                header.column.getIsSorted() !== "desc" && (
                                  <div className={`inline-block`}>
                                    <ExpandMoreSharpIcon
                                      className={` inline-block sort sortHover`}
                                    />
                                  </div>
                                )}
                              {(header.column.getIsSorted() === "asc" ||
                                header.column.getIsSorted() === "desc") && (
                                <div className={`inline-block`}>
                                  <ExpandMoreSharpIcon
                                    className={` inline-block sort ${
                                      header.column.getIsSorted() === "asc"
                                        ? "sortFlip"
                                        : ""
                                    }`}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody id="tableBody" sx={{ display: (grid ? "none" : "") }}>
              {table.getRowModel().rows.map((row) => {
                return (
                  <TableRow key={row.id} id={row.getValue("_id")}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <TableCell
                          key={cell.id}
                          sx={{ textAlign: "center", fontFamily: "Poppins", padding: "4px", userSelect: "none" }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      <div className={`grid sm:grid-cols-2 sm:gap-2 md:grid-cols-3 lg:grid-cols-4 lg:gap-4 ${grid ? "" : "hidden"}`} >
        {ids.map((id, index) => (
          <div key={index} className="aspect-[15/16] w-full h-full"><div className="flex"
          >
            <PlayerProfile id={id} /></div></div>

        ))} 
      </div>
      <div className="relative my-6 w-[100%] flex justify-center">
        <div className="absolute page-size  ">
          <Select
            name="page-size"
            id="page-size"
            value={pageSize}
            onChange={(e) => {
              const size = e.target.value ? Number(e.target.value) : 10;
              setNum(size);
              table.setPageSize(size);
            }}
            size="small"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </div>

        <Pagination
          variant="outlined"
          count={totalPages}
          onChange={(_, value) => {
            table.setPageIndex(value - 1);
          }}
          page={pageIndex + 1}
          size="large"
          sx={{ '& .MuiPaginationItem-page.Mui-selected': { color: 'red', border: "red solid 2px" } }}
        ></Pagination>
      </div>
    </Box>
  </>
    
  );
}

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')

export default PlayersData

function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: ReactTable<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <TextField
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder={`Min`}
        size="small"
      />
      <TextField
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        inputProps={{ "aria-label": "search" }}
        size="small"
      />
    </div>
  ) : (
    <TextField
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search`}
      inputProps={{style: {fontFamily:"Poppins"}}}
      size="small"
    />
  );
}