import React from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "auto",
  height: "80vh",
  fontFamily: "Poppins",
};

function Rules() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="contained"
        sx={{
          fontWeight: 800,
          background: "white",
          color: "black",
          fontFamily: "Poppins",
          "&:hover": { backgroundColor: "#e0e0e0" },
        }}
        color="error"
        size="large"
        className="sm-shadow"
      >
        Rules
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  h-[80vh] w-[80vw] border-6 bg-white">
          <img
            className="absolute fade-in sm:w-14 lg:w-24 bottom-0 right-0 p-2 z-50 xs-shadow"
            src={`${process.env.PUBLIC_URL}/assets/img/AW_allblack.png`}
            alt="AW"
          />
          <div
            className=" sm:p-8 lg:p-24 text-black "
            // @ts-ignore
            style={style}
          >
            <h1 className="font-bold text-xl">General Rules</h1>

            <ul className=" sm:p-2 lg:p-6 pb-12 flex flex-col gap-4 list-decimal">
              <li>
                AfterWork Basketball is a basketball league perfect for workers
                looking for team-building and who want to play their favorite
                sport with colleagues and compete. It's also an excellent way to
                spend quality time with a group of friends once the workday is
                over.
              </li>
              <li>
                The season includes:
                <ul className="px-5 py-2 list-disc flex flex-col gap-2">
                  <li className="">10 scheduled games</li>
                  <li>Playoff series for qualifying teams</li>
                  <li>1 jersey</li>
                  <li>Individual (points) and team statistics (ranking)</li>
                  <li>A website with photos/videos and more</li>
                  <li>Prizes for the winning team</li>
                </ul>
              </li>
              <li>
                Who can play:
                <ul className="px-1 py-2 flex flex-col gap-2">
                  <li className="first-letter:font-bold">
                    a. Anyone 18 years and older who wants to play basketball in
                    a supervised and safe environment.
                  </li>
                  <li className="first-letter:font-bold">
                    b. (16 or 17-year-olds tolerated with parental consent. See
                    the Consent Form section for Legal Consent.)
                  </li>
                </ul>
              </li>
              <li>
                Team composition:
                <ul className="px-1 py-2 flex flex-col gap-2">
                  <li className="first-letter:font-bold">
                    a. A team consists of a minimum of 10 players and a maximum
                    of 15 players. At least 2 individuals assigned female at
                    birth and 2 individuals assigned male at birth must be on
                    the team.
                  </li>
                  <li className="first-letter:font-bold">
                    b. (A team must have a minimum of 8 registered players to
                    exist. However, it is at the league's discretion to add solo
                    registered players to any team that does not consist of a
                    minimum of 10 players.)
                  </li>
                </ul>
              </li>
              <li>
                Registration:
                <ul className="px-1 py-2 flex flex-col gap-2">
                  <li className="first-letter:font-bold">
                    a. Registration must be done on the website
                    www.afterworkleagues.com. Each player must complete their
                    registration and pay the registration fees for each season
                    AT LEAST 2 WEEKS BEFORE THE START OF THE SEASON.
                  </li>
                  <li className="first-letter:font-bold">
                    b. Teams of 10 players who have duly completed their
                    registrations and paid the associated fees will be
                    prioritized.
                  </li>
                  <li className="first-letter:font-bold">
                    c. However, confirmation from the league officials is
                    required to ensure participation.
                  </li>
                </ul>
              </li>
              <li>
                Conduct of a game:
                <ul className="px-1 py-2 flex flex-col gap-2">
                  6.1.
                  <li className="first-letter:font-bold">
                    a. Games start at the indicated time on the schedule.
                    Players can arrive 15 minutes before the start of the game
                    to warm up.
                  </li>
                  <li className="first-letter:font-bold">
                    b. All games are played with 5 players on each team. 1
                    female and 1 male must be on the court at all times.
                  </li>
                  <li className="first-letter:font-bold">
                    c. A game consists of 4 periods of 8 minutes each. The last
                    2 minutes of the 4th quarter are played with stoppage time
                    after each basket.
                  </li>
                  <li className="first-letter:font-bold">
                    d. *After the 4 periods, in case of a tie at the end of the
                    allotted time, an additional 2-minute overtime will be
                    granted. The team starting with the ball will be determined
                    by the current possession order. Once this overtime is over,
                    the ball will be put back into play, this time with a
                    referee's jump ball. The first team to score any basket will
                    be awarded the victory.
                  </li>
                  <li className="first-letter:font-bold">
                    e. During the playoffs, an additional 3-minute overtime will
                    be added to the clock until a winning team is determined at
                    the end of the added time.
                  </li>
                  6.2.
                  <li className="first-letter:font-bold">
                    a. A certified referee will call fouls and officiate the
                    game. Points will be counted by AW's minor officials. A
                    scorecard will be completed for each game.
                  </li>
                  <li className="first-letter:font-bold">
                    b. In the absence of the required number of players on the
                    court, either 1 female and 4 males or 1 male and 4 females,
                    the concerned team will start the game with a minimum of 4
                    players and will have until the start of the 3rd quarter to
                    meet these requirements, or they will automatically lose the
                    game. The game will still be played. In the preceding
                    paragraph, "female" refers to an individual assigned female
                    at birth, and "male" refers to an individual assigned male
                    at birth.
                  </li>
                </ul>
              </li>
              <li>
                Rules:
                <ul className="px-1 py-2 flex flex-col gap-2">
                  7.1.
                  <li className="first-letter:font-bold">
                    a. General basketball rules apply. A successful basket is
                    worth 2 points, and a shot made from behind the farthest
                    3-point line is worth 3 points.
                  </li>
                  <li className="first-letter:font-bold">
                    b. Incomplete teams at the start of the game must begin if
                    they have a minimum of 4 players. A 10-minute delay will be
                    tolerated if they have fewer than 4 players. After that,
                    they will forfeit the game.
                  </li>
                  <li className="first-letter:font-bold">
                    c. *If both teams are at fault, no victory points will be
                    awarded for that match.
                  </li>
                  <li className="first-letter:font-bold">
                    d. **A team that is more than 5 minutes late loses its
                    ETHICS POINT.
                  </li>
                  <li className="first-letter:font-bold">
                    e. If in compliance, the opposing team is entitled to a free
                    throw and possession of the ball at the start of the game,
                    if the game is played.
                  </li>
                  <li className="first-letter:font-bold">
                    f. ***In the case of a forfeit, present players are invited
                    to play a friendly game where teams can mix. Appearance
                    rules no longer apply, and the game will be played in 2
                    halves of 25 minutes of continuous time, stopped only for
                    timeouts and in NO other case. (Individual statistics will
                    be recorded.)
                  </li>
                  <li className="first-letter:font-bold">
                    g. These punctuality rules will always apply unless there is
                    a technical problem that is not the responsibility of the
                    players.
                  </li>
                  7.2.
                  <li className="first-letter:font-bold">
                    a. Teams have 2 timeouts in the first half and 3 timeouts in
                    the second half. The team loses a timeout if not used before
                    the final 2 minutes of the game.
                  </li>{" "}
                  <li className="first-letter:font-bold">
                    b. During each overtime, if applicable, teams have 1
                    timeout.
                  </li>
                  7.3.
                  <li className="first-letter:font-bold">
                    a. Each game played earns the team an automatic ETHICS
                    POINT; a victory earns an additional 2 points.
                  </li>
                  <li className="first-letter:font-bold">
                    b. A team receiving 2 technical fouls in the same game
                    automatically loses its ETHICS POINT.
                  </li>
                  <li className="first-letter:font-bold">
                    c. A team with a player ejected from the game also loses its
                    ETHICS POINT.
                  </li>
                  <li className="first-letter:font-bold">
                    d. In case of a tie in the final season standings, a team
                    with a higher number of ETHICS POINTS will have an advantage
                    over other teams.
                  </li>
                  7.4.
                  <li className="first-letter:font-bold">
                    a. A player displaying unsportsmanlike behavior may be
                    ejected from the game by the referee or an AW employee.
                  </li>
                  <li className="first-letter:font-bold">
                    b. *Note that an ejected player cannot play in the following
                    game, even if it is a playoff game.
                  </li>
                  <li className="first-letter:font-bold">
                    c. A player ejected twice in the season will be removed from
                    the league without any refund. Their participation in a
                    future season will be evaluated by the organizers.
                  </li>
                  <li>
                    7.5. Each player must have played a minimum of 4 games to
                    participate in the playoffs.
                  </li>
                  <li>
                    Statistics:
                    <ul className="px-5 py-2 list-disc flex flex-col gap-2">
                      <li>1 win without technical fouls = 3 points</li>
                      <li>1 loss without technical fouls = 1 point</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                Behavior/Attitude:
                <ul className="px-1 py-2 ">
                  <li>
                    Appropriate behavior is required at all times. Respect for
                    others, equipment, facilities, referees, and AW staff is
                    mandatory. Misconduct will not be tolerated. Offenders may
                    be ejected from a game and/or the league without a refund.
                  </li>
                </ul>
              </li>
              <li>
                Use of Substitutes:
                <ul className="px-1 py-2 flex flex-col gap-2">
                  <li className="first-letter:font-bold">
                    a. A team can use as many substitutes as they have absent
                    players, following these parameters:
                  </li>
                  <li className="first-letter:font-bold">
                    b. A substitute must pay $15 to play.
                  </li>
                  <li className="first-letter:font-bold">
                    c. Any substitute can play a maximum of 3 games in the
                    season.
                  </li>
                  <li className="first-letter:font-bold">
                    d. By the 4th game, they must complete and pay their
                    registration to play and will automatically become eligible
                    for the playoffs.
                  </li>
                  <li className="first-letter:font-bold">
                    e. Only a maximum of 2 substitutes can be on the court at
                    once.
                  </li>
                  <li className="first-letter:font-bold">
                    f. Finally, league officials must be informed in advance of
                    the use of a substitute, at least 2 hours before the start
                    of the game.
                  </li>
                </ul>
              </li>
              <li>
                Website:
                <ul className="px-1 py-2 ">
                  <li>
                    AW commits to updating the website with team scores,
                    individual scores, rankings, schedules, etc., within 48
                    hours after a game.
                  </li>
                </ul>
              </li>
              <li>
                Playoff Structure:
                <ul className="px-1 py-2 ">
                  <li>Refer to the season schedule.</li>
                </ul>
              </li>
              <li>
                Employer Physical Activity Expense Reimbursement Program:
                <ul className="px-1 py-2 ">
                  <li>
                    Some employers offer the possibility of reimbursing part of
                    the expenses related to physical activity. Check with your
                    employer.
                  </li>
                </ul>
              </li>
              <li>
                Accidents/Injuries:
                <ul className="px-1 py-2 flex flex-col gap-2">
                  <li className="first-letter:font-bold">
                    a. Playing safely is paramount.
                  </li>
                  <li className="first-letter:font-bold">
                    b. An incident report will be filed for any accident or
                    injury.
                  </li>
                  <li className="first-letter:font-bold">
                    c. The wearing of necklaces and bracelets is prohibited
                    during games.
                  </li>
                  <li className="first-letter:font-bold">
                    d. Piercings may be tolerated if concealed in a way that
                    protects the wearer and opponents.
                  </li>
                  <li className="first-letter:font-bold">
                    e. The decision to remove or allow piercings to play is at
                    the discretion of the present league official.
                  </li>
                </ul>
              </li>
              <li>
                Referee
                <ul className="px-1 py-2 flex flex-col gap-2">
                  <li className="first-letter:font-bold">
                    a. While advanced division games are supposed to have 2
                    referees, they will be played as long as a minimum of one
                    referee is present.
                  </li>
                  <li className="first-letter:font-bold">
                    b. A game played with one referee is not a reason for a
                    refund or an adjustment of a member's fee.
                  </li>
                </ul>
              </li>
              <li>
                Dress Code
                <ul className="px-1 py-2 flex flex-col gap-2">
                  <li className="first-letter:font-bold">
                    a. Wearing the league's jersey is mandatory to play.
                  </li>
                  <li className="first-letter:font-bold">
                    b. In the absence of your jersey, a league official may lend
                    you one with a penalty of $2.
                  </li>
                  <li className="first-letter:font-bold">
                    c. An alternative solution may be considered only if the
                    league cannot provide a replacement jersey.
                  </li>
                  <li className="first-letter:font-bold">
                    d. The decision to play with any other top than the one
                    provided by the league is at the discretion of the present
                    league official.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Rules;
