import React from "react";
function PlayerAwards({ awards }) {
  return (
    <div className="w-[100%]  bgBlack border-8  border-[#bf0202] sm-shadow relative overflow-hidden">
      <div className="w-[100%] h-[100%] flex flex-col items-center ">
        <div className="w-[100%] sm:p-4 lg:p-12 h-[15%] sm:min-h-[80px] lg:min-h-[120px] relative text-white flex items-center justify-center font-bold sm:text-lg lg:text-4xl z-40">
          <div className="w-[100%] text-center stats-font">AWARDS</div>
          <span className="absolute bottom-0 border-2 border-white rounded-lg w-[75%]"></span>
        </div>
        <div className="w-[100%] h-[85%] sm:mt-4 lg:mt-8 flex flex-col justify-between p-2 z-40 ">
          {awards.map((award, index) => (
            <div className="w-[100%] text-white flex flex-wrap flex-col justify-center font-bold text-center p-2">
              <h1 className="sm:text-lg md:text-2xl lg:text-4xl stats-font">
                {award.awardID.titleEn}{" "}
                {award.awardID.titleEn === "Championships" ? "🏆" : "🥇"}
              </h1>
              <h1 className="sm:text-[10px] lg:text-base line-height">
                {award.seasonID.name}
              </h1>
            </div>
          ))}
        </div>
      </div>
      <img
        className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] opacity-50 z-0"
        src={`${process.env.PUBLIC_URL}/assets/img/AW_allblack.png`}
      />
    </div>
  );
}

export default PlayerAwards;
