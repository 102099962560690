import { TextField } from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import React, { useState, useEffect, useContext } from "react";
import GlobalContext from "../GlobalContext";
import Axios from "axios";

const Division = ({ division, submit }) => {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [titleEn, setTitleEn] = useState("");
  const [price, setPrice] = useState(0);
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setTitleEn(division?.titleEn);
    setPrice(division?.price);
    setColor(division?.color);
  };
  useEffect(() => {
    if (submit) {
      patchDivision();
    }
  }, [submit]);

  const patchDivision = async () => {
    await Axios.patch(url + "/divisions/" + division?._id, {
      titleEn: titleEn,
      price: price ? price : 0,
      color: color,
    }).then((response) => {
      console.log(response);
    });
  };

  const handleColor = (color) => {
    setColor(color);
  };

  return (
    <div className="flex sm:flex-col lg:flex-row my-4 gap-2">
      <TextField
        label="Title"
        onChange={(e) => setTitleEn(e.target.value)}
        value={titleEn}
      ></TextField>
      <TextField
        onWheel={(e) => e.target.blur()}
        label="Price"
        type="number"
        onChange={(e) => setPrice(parseInt(e.target.value))}
        value={price}
      ></TextField>
      <MuiColorInput label="Color" value={color} onChange={handleColor} />
    </div>
  );
};

export default Division;
