import * as React from "react";
import Title from "./component/Title";
import PlayersData from "./component/PlayersData";

function Players({ onData, prop }) {
  return (
    <div>
      <div>
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="PLAYERS" />
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <PlayersData onData={onData} prop={prop}></PlayersData>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Players;
