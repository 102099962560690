import React from "react";
import "./tableHover.css";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";

import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

type Team = {
 _id:string;
  teamID: {
    _id: string;
    name: string;
  };
  gamesPlayed: number;
  teamPTS: number;
  ethicPTS: number;
  win: number;
  lose: number;
  tie: number;
  diffPTS: number;
};

const PlayerStandings = ({ teams }) => {
  const data = teams;

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const [grid, setGrid] = useState(false);
  const renderGrid = () => {
    setGrid(!grid);
  };

  const columns = React.useMemo<ColumnDef<Team>[]>(
    () => [
      {
        header: "Info",
        columns: [
          {
            header: "Rank #",
            id: "rank",
            cell: ({ row }) => {
              return <div className="font-bold text-base">{row.index + 1}</div>;
            },
          },
          {
            header: "Name",
            id: "_id",
            accessorFn: (row) =>
              `${row.teamID?._id}`,
            cell: (info) => (
              <Button
              variant="outlined"
              color="inherit" sx={{
                bgcolor: "white",
                width: "100%",
                color: "black",
                fontWeight: "600",
                fontFamily:"Poppins",
                ":hover": { color: "white" },
                }}
                href={`/team/${info.row.original.teamID?._id}`}
              >
                {info.row.original.teamID?.name}
              </Button>
            ),
            sortDescFirst: true,
          },
        ],
      },
      {
        header: "Stats",
        columns: [
          {
            header: "Total points",
            accessorKey: "teamPTS",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },{
            header: "GP",
            accessorKey: "gamesPlayed",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "Win (2)",
            accessorKey: "win",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "Loss (0)",
            accessorKey: "lose",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "Ethic Points (1)",
            accessorKey: "ethicPTS",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "Point differential",
            accessorKey: "diffPTS",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          }    
        ],
      },
    ],
    []
  );

  const [num, setNum] = useState(10);

  function LocalTable({
    data,
    columns,
  }: {
    data: Team[];
    columns: ColumnDef<Team>[];
  }) {
    const table = useReactTable({
      data,
      columns,
      // Pipeline
      state: {
        sorting,
      },
      initialState: {
        pagination: {
          pageIndex: 0,
          pageSize: num,
        },
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
    });

    const { pageSize, pageIndex } = table.getState().pagination;
    const { rows } = table.getFilteredRowModel();

    const [ids, setIds] = useState([]);

    const fetchIds = () => {
      const tableBody = document.getElementById("tableBody");
      const rows = tableBody.getElementsByTagName("tr");

      const extractedIds = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const id = row.getAttribute("id");
        extractedIds.push(id);
      }
      setIds(extractedIds);
    };

    useEffect(() => {
      fetchIds();
    }, [pageSize, pageIndex, rows]);

    const totalPages = Math.ceil(
      table.getFilteredRowModel().rows.length / pageSize
    );

    return (
      <Box sx={{ width: "100%" }} className="fade-in">

        <TableContainer component={Paper} className="box">
          <div className="boxbox">
            <Table className="tableHover">
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableCell
                          key={header.id}
                          colSpan={header.colSpan}
                          sx={{
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            textAlign: "center",
                            
                            backgroundImage: `${
                              header.column.id === "teamPTS" ||
                              header.column.id === "rank"
                                ? "linear-gradient(to right,  #5f0000 0%,  #960000 30%, #960000 70%, #5f0000 100%)"
                                : ""
                            }`,
                            backgroundRepeat: "no-repeat",
                          }}
                          className={`${
                            header.column.id === "teamPTS" ||
                            header.column.id === "rank"
                              ? "border-4 border-red-950"
                              : ""
                          }`}
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none flex-col flex align-center items-center sortLabel"
                                    : "inline-block flex justify-center ",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}{" "}
                                {header.column.getCanSort() &&
                                  header.column.getIsSorted() !== "asc" &&
                                  header.column.getIsSorted() !== "desc" && (
                                    <div className={`inline-block`}>
                                      <ExpandMoreSharpIcon
                                        className={` inline-block sort sortHover`}
                                      />
                                    </div>
                                  )}
                                {(header.column.getIsSorted() === "asc" ||
                                  header.column.getIsSorted() === "desc") && (
                                  <div className={`inline-block`}>
                                    <ExpandMoreSharpIcon
                                      className={` inline-block sort ${
                                        header.column.getIsSorted() === "asc"
                                          ? "sortFlip"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody id="tableBody" sx={{ display: grid ? "none" : "" }}>
                {table.getRowModel().rows.map((row, index) => {
                  return (
                    <TableRow key={row.id} id={row.getValue("_id")}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            sx={{
                              textAlign: "center",
                              fontFamily: "Poppins",userSelect:"none",
                              padding: "4px",
                              fontWeight: `${
                                cell.column.id === "teamPTS" ? "700" : ""
                              }`,
                              borderBottom: `${
                                (cell.column.id === "teamPTS" ||
                                  cell.column.id === "rank") &&
                                table.getRowModel().rows.length - 1 === index
                                  ? "solid 4px"
                                  : (cell.column.id === "teamPTS" ||
                                      cell.column.id === "rank") &&
                                    table.getRowModel().rows.length - 1 !==
                                      index
                                  ? "none"
                                  : ""
                              }`,
                              backgroundImage: `${
                                cell.column.id === "teamPTS" ||
                                cell.column.id === "rank"
                                  
                                ? "linear-gradient(to right,  #5f0000 0%,  #960000 30%, #960000 70%, #5f0000 100%)"
                                  : ""
                              }`,
                              backgroundRepeat: "no-repeat",
                              "&:hover": {
                                backgroundImage: `${
                                  cell.column.id === "teamPTS" ||
                                  cell.column.id === "rank"
                                    ? "linear-gradient(to left,  #000, #000)"
                                    : ""
                                }`,
                              },
                            }}
                            className={`${
                              cell.column.id === "teamPTS" ||
                              cell.column.id === "rank"
                                ? "border-x-4 border-red-950"
                                : ""
                            } `}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>

                
      </Box>
    );
  }

  return (
    <>
      <LocalTable {...{ data, columns }} />
      <hr />
    </>
  );
};

export default PlayerStandings;


