import Slider from "react-slick";
import WeekDefenders from "./component/WeekDefenders";
import MiniTitle from "./component/MiniTitle";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import GlobalContext from "./GlobalContext";
import News from "./component/News";

function Home() {
  const isLargeScreen = useMediaQuery({ minWidth: 1024 });
  const sliderRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      sliderRef.current.slickNext();
    }, 5000);

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="container " id="Home">
      <div className="content fade-in sm:w-[95%] lg:w-[80%] h-[90vh] justify-start items-center">
        <div className="absolute top-[65vh] left-[50%] translate-x-[-50%] select-none">
          <div className="relative flex justify-center">
            <span className="absolute fontRed z-20 font-extrabold sm:text-5xl lg:text-7xl text-white">
              AFTERWORK
            </span>
            <span
              data-content={`AFTERWORK`}
              className="customShadow z-10 font-extrabold sm:text-5xl lg:text-7xl select-none"
            >
              AFTERWORK
            </span>
          </div>
          <MiniTitle word="BASKET BALL" />
        </div>
        <Slider
          ref={sliderRef}
          className="sm:w-[100%] lg:min-w-[920px] sm:min-h-[50vh] sm:mt-10 lg:mt-0 lg:h-[60vh] lg:w-[60%] relative"
          {...{
            arrows: isLargeScreen,
            dots: true,
            infinite: true,
            speed: 700,
            slidesToShow: 1,
            slidesToScroll: 1,
          }}
        >
          <div className="sliderFlex h-[60vh] sm:h-[50vh] lg:h-[60vh] sm:p-4 lg:p-12 justify-center items-center">
            <div className="sliderFlex relative w-[100%] h-[100%] justify-center items-center">
              <img
                src="./assets/img/AW_RED.png"
                className={` ${isLargeScreen ? "lg-shadow" : "sm-shadow"} `}
                style={{
                  width: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="sliderFlex  sm:p-4 lg:p-12 justify-center items-center">
            <div className="sliderFlex relative w-[100%] h-[100%] justify-center items-center">
              <img
                src="./assets/img/championsH2024.jpg"
                className={` ${isLargeScreen ? "lg-shadow" : "sm-shadow"} `}
                style={{
                  width: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          {/* <div className="sliderFlex h-[60vh] sm:h-[50vh] lg:h-[60vh] sm:p-4 lg:p-12 justify-center items-center">
            <div className="sliderFlex relative w-[100%] h-[100%] justify-center items-center">
              <WeekDefenders
                // @ts-ignore
                className={` ${isLargeScreen ? "lg-shadow" : "sm-shadow"} `}
              />
            </div>
          </div>

          <div className="sliderFlex h-[60vh] sm:h-[50vh] lg:h-[60vh] sm:p-4 lg:p-12 justify-center items-center">
            <div className="sliderFlex relative w-[100%] h-[100%] justify-center items-center">
              <img
                src="./assets/img/baskcourt2.jpg"
                className={` ${isLargeScreen ? "lg-shadow" : "sm-shadow"} `}
                style={{
                  width: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div> */}
        </Slider>
      </div>
      <News />
    </div>
  );
}

export default Home;
