import Axios from "axios";
import { useState, useEffect, useContext } from "react";
import GlobalContext from "../GlobalContext";
import PersonIcon from "@mui/icons-material/Person";

function PlayerStats({ season, player }) {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [imgUrl, setImgUrl] = useState(null);
  const [seasonData, setSeasonData] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    playerImage();
    fetchSeason();
  }, []);

  const fetchSeason = async () => {
    await Axios.get(url + "/seasons/" + season?.seasonID._id)
      .then((res) => {
        setSeasonData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const playerImage = async () => {
    await Axios.get(url + "/images/playerID/" + player?._id)
      .then((res) => {
        setImgUrl(res.data ? res.data : "");
        console.log(res.data ? res.data : "");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  return (
    <div className="lg:w-[750px] lg:h-[900px] md:w-[550px] md:h-[700px] sm:w-[90vw] sm:h-[80vh] relative flex justify-center  items-center lg-shadow">
      <div className="w-[100%] h-[100%]  absolute bgRed  fiche-animation overflow-hidden"></div>

      <div className="w-[95%] h-[95%] absolute bgRed fiche-animation overflow-hidden "></div>
      <div
        onClick={handleClick}
        className={`w-[90%] h-[90%] flip-card absolute cursor-pointer fiche-animation`}
      >
        <div
          className={`w-[90%] h-[90%] flip-card-inner bgBlack `}
          style={{ transform: isClicked && "rotateY(180deg)" }}
        >
          <div className="flip-card-front w-full h-full bgRed flex justify-center items-center  rounded-[10px] select-none">
            <div className="w-[95%] h-[95%]  flex flex-col gap-4 justify-center relative overflow-hidden rounded-[10px]">
              <div
                className={`w-[100%] h-[30%] relative bgBlack sm:pl-4 lg:pl-8 text-white flex  font-bold sm:text-lg md:text-4xl rounded-t-[10px] `}
              >
                <div className="w-[50%]  text-start h-full stats-font flex flex-col items-start justify-center sm:text-xl md:text-3xl lg:text-4xl">
                  <h1
                    className={`text-left border-b-4 mb-4 sm-shadow ${
                      !isClicked && "fade-down"
                    }`}
                    style={{ animationDelay: "500ms" }}
                  >
                    {seasonData?.name}
                  </h1>
                  <h1
                    className={`w-full text-left ${
                      !isClicked && "fade-down"
                    } sm-shadow`}
                    style={{ animationDelay: "550ms" }}
                  >
                    {player?.lastname}
                  </h1>
                  <h1
                    className={`w-full text-left ${
                      !isClicked && "fade-down"
                    } sm-shadow`}
                    style={{ animationDelay: "550ms" }}
                  >
                    {player?.firstname}
                  </h1>
                </div>
                <div
                  className={`sm:w-[48%] lg:w-[45%] h-full self-start  overflow-hidden ${
                    !isClicked && "fade-down"
                  }`}
                  style={{ animationDelay: "550ms" }}
                >
                  {imgUrl === "" ? (
                    <div className="fade-in relative w-full flex justify-center items-center h-full">
                      <PersonIcon
                        sx={{ color: "whitesmoke", fontSize: "96px" }}
                        className="sm-shadow scale-150"
                      ></PersonIcon>
                    </div>
                  ) : (
                    <div
                      className="fade-in w-full h-full mb-2 mr-2 sm-shadow"
                      style={{
                        backgroundImage: `url(${imgUrl})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "bottom",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="w-full h-[65%] bgBlack relative lg:text-5xl md:text-4xl sm:text-xl stats-font rounded-b-[10px] md:p-4">
                <div className="w-full h-1/3  grid grid-cols-4 relative">
                  <div className="w-[100%] flex gap-2 flex-col items-center justify-center text-white font-bold z-10 sm-shadow relative">
                    <h1
                      className={`${!isClicked && "fade-down"}`}
                      style={{ animationDelay: "600ms" }}
                    >
                      GP
                    </h1>
                    <h1
                      className={`${!isClicked && "fade-down"}  font-Poppins`}
                      style={{ animationDelay: "650ms" }}
                    >
                      {season?.gamesPlayed}
                    </h1>
                  </div>
                  <div className="w-[100%] flex gap-2 flex-col items-center justify-center text-white font-bold z-10 sm-shadow relative">
                    <h1
                      className={`${!isClicked && "fade-down"}`}
                      style={{ animationDelay: "700ms" }}
                    >
                      PPG
                    </h1>
                    <h1
                      className={`${!isClicked && "fade-down"} font-Poppins`}
                      style={{ animationDelay: "750ms" }}
                    >
                      {season?.ppg.toFixed(1)}
                    </h1>
                    <span
                      className={`${
                        !isClicked && "fade-down"
                      } w-2 h-3/4 absolute bg-white sm-shadow left-[-4px] top-[15%] rounded-full`}
                      style={{ animationDelay: "600ms" }}
                    ></span>
                  </div>
                  <div className="w-[100%] flex gap-2 flex-col items-center justify-center text-white font-bold z-10 sm-shadow relative">
                    <h1
                      className={`${!isClicked && "fade-down"}`}
                      style={{ animationDelay: "800ms" }}
                    >
                      PTS
                    </h1>
                    <h1
                      className={`${!isClicked && "fade-down"} font-Poppins`}
                      style={{ animationDelay: "850ms" }}
                    >
                      {season?.pts}
                    </h1>
                    <span
                      className={`${
                        !isClicked && "fade-down"
                      } w-2 h-3/4 absolute bg-white sm-shadow left-[-4px] top-[15%] rounded-full`}
                      style={{ animationDelay: "700ms" }}
                    ></span>
                  </div>
                  <div className="w-[100%] flex gap-2 flex-col items-center justify-center text-white font-bold z-10 sm-shadow relative">
                    <h1
                      className={`${!isClicked && "fade-down"}`}
                      style={{ animationDelay: "900ms" }}
                    >
                      FGM
                    </h1>
                    <h1
                      className={`${!isClicked && "fade-down"} font-Poppins`}
                      style={{ animationDelay: "950ms" }}
                    >
                      {season?.fgm}
                    </h1>
                    <span
                      className={`${
                        !isClicked && "fade-down"
                      } w-2 h-3/4 absolute bg-white sm-shadow left-[-4px] top-[15%] rounded-full`}
                      style={{ animationDelay: "800ms" }}
                    ></span>
                  </div>
                </div>
                <div className="w-full h-1/3 grid grid-cols-3">
                  <div className="w-[100%] flex gap-2 flex-col items-center justify-center text-white font-bold z-10 sm-shadow relative">
                    <h1
                      className={`${!isClicked && "fade-down"}`}
                      style={{ animationDelay: "1000ms" }}
                    >
                      3PM
                    </h1>
                    <h1
                      className={`${!isClicked && "fade-down"} font-Poppins`}
                      style={{ animationDelay: "1050ms" }}
                    >
                      {season?.threes}
                    </h1>
                  </div>
                  <div className="w-[100%] flex gap-2 flex-col items-center justify-center text-white font-bold z-10 sm-shadow relative">
                    <h1
                      className={`${!isClicked && "fade-down"}`}
                      style={{ animationDelay: "1100ms" }}
                    >
                      FTM
                    </h1>
                    <h1
                      className={`${!isClicked && "fade-down"} font-Poppins`}
                      style={{ animationDelay: "1150ms" }}
                    >
                      {season?.freeThrows}
                    </h1>
                    <span
                      className={`${
                        !isClicked && "fade-down"
                      } w-2 h-3/4 absolute bg-white sm-shadow left-[-4px] top-[15%] rounded-full`}
                      style={{ animationDelay: "1000ms" }}
                    ></span>
                  </div>
                  <div className="w-[100%] flex gap-2 flex-col items-center justify-center text-white font-bold z-10 sm-shadow relative">
                    <h1
                      className={`${!isClicked && "fade-down"}`}
                      style={{ animationDelay: "1200ms" }}
                    >
                      FT%
                    </h1>
                    <h1
                      className={`${!isClicked && "fade-down"} font-Poppins`}
                      style={{ animationDelay: "1250ms" }}
                    >
                      {season?.freeThrowsAttempts > 0
                        ? (
                            (season?.freeThrows / season?.freeThrowsAttempts) *
                            100
                          ).toFixed(1)
                        : 0}
                    </h1>
                    <span
                      className={`${
                        !isClicked && "fade-down"
                      } w-2 h-3/4 absolute bg-white sm-shadow left-[-4px] top-[15%] rounded-full`}
                      style={{ animationDelay: "1100ms" }}
                    ></span>
                  </div>
                </div>
                <div className="w-full h-1/3 grid grid-col-1 text-white ">
                  <h1
                    className={`${!isClicked && "fade-down"} z-10`}
                    style={{ animationDelay: "1400ms" }}
                  >
                    CHAMPIONSHIPS
                  </h1>
                  <h1
                    className={`${
                      !isClicked && "fade-down"
                    } font-Poppins text-6xl font-extrabold text-red-600 sm-shadow z-10`}
                    style={{ animationDelay: "1450ms" }}
                  >
                    {season?.championShip ? (
                      "🥇"
                    ) : (
                      <p className="text-lg">UNDISPUTED</p>
                    )}
                  </h1>
                </div>
                <img
                  className="absolute left-[50%] top-[50%] w-[90%] translate-x-[-50%] translate-y-[-50%] opacity-70 "
                  src={`${process.env.PUBLIC_URL}/assets/img/AW_allblack.png`}
                />
              </div>
            </div>
          </div>
          <div className="flip-card-back w-full h-full bgRed rounded-[10px] z-20">
            <div className="w-[95%] h-[95%] relative overflow-hidden rounded-[10px]">
              <img
                className="absolute left-[50%] top-[50%] w-[90%] translate-x-[-50%] translate-y-[-50%] opacity-70 "
                src={`${process.env.PUBLIC_URL}/assets/img/AW_allblack.png`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerStats;
