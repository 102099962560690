import { useState, useEffect, useContext } from "react";
import Axios from "axios";
import "./PlayerProfile.css";
import GlobalContext from "../GlobalContext";
import Title from "./Title";
import PersonIcon from "@mui/icons-material/Person";

function PlayerProfile({ id, rank = "0" }) {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [imgUrl, setImgUrl] = useState(null);

  const [data, setData] = useState([]);
  Axios.defaults.withCredentials = true;

  const fetchData = async () => {
    await Axios.get(url + "/players/" + id).then((res) => {
      setData(res.data);
    });
  };

  const playerImage = async () => {
    await Axios.get(url + "/images/playerID/" + id)
      .then((res) => {
        setImgUrl(res.data ? res.data : "");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData();
    playerImage();
  }, [id]);

  return (
    <a
      className="w-[100%] max-w-[500px] aspect-[15/16] h-auto player-profile border-4 gradient-border2 sm-shadow border-red-600 rounded-2xl relative"
      // @ts-ignore
      href={`/players/${data._id}`}
    >
      {rank !== "0" && (
        <div className="absolute sm:p-1 xl:p-6 z-20 top-0 right-0">
          <div className="relative flex justify-center items-center border-black stats-font">
            <span
              data-content={rank}
              className="absolute fontRed z-20 font-extrabold sm:text-xl lg:text-5xl text-white"
            >
              {rank}
            </span>
            <span
              data-content={rank}
              className="customShadow z-10 font-extrabold sm:text-xl lg:text-5xl"
            >
              {rank}
            </span>
          </div>
        </div>
      )}

      <div className="w-[100%] h-[100%] bg-black relative overflow-hidden rounded-t-xl">
        <img
          src="../assets/img/AW_mini_allblack.png"
          className="object-cover h-[100%] selector absolute opacity-90"
        ></img>{" "}
        <div className=" h-[80%] w-full absolute bottom-0 overflow-hidden z-10">
          {imgUrl === "" ? (
            <div className="fade-in relative w-full flex justify-center items-center h-full">
              <PersonIcon
                sx={{ color: "whitesmoke", fontSize: "96px" }}
                className="sm-shadow sm:scale-[1.5]  md:scale-[2] xl:scale-[3]"
              ></PersonIcon>
            </div>
          ) : (
            <div
              className="fade-in w-full h-full sm-shadow"
              style={{
                backgroundImage: `url(${imgUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "bottom",
              }}
            />
          )}
        </div>
        {/* <img
          src="../assets/img/buddy.png"
          className="absolute bottom-0 photo sm-shadow"
        ></img> */}
      </div>
      <div className="relative">
        <div className="w-[100%] gradient-border  rounded-b-xl h-[20%] pb-4 sm:border-[12px] md:border-[20px] border-red-600 border-t-black bg-black font-semibold uppercase text-white lg:text-lg flex justify-center flex-wrap flex-col text-center font-Poppins">
          <p className="z-10">{data?.lastname}</p>
          <p className="z-10">{data?.firstname}</p>
        </div>
      </div>
    </a>
  );
}

export default PlayerProfile;
