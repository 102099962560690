import React from "react";
import "./tableHover.css";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import ReorderIcon from "@mui/icons-material/Reorder";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import { useEffect, useState  } from "react";

import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import PlayerProfile from "./PlayerProfile";

type PlayerTeam = {
  playerID: {
    _id: string;
    firstname: string;
    lastname: string;
  };
  ppg: number;
  gamesPlayed: number;
  pts: number;
  fgm: number;
  threes: number;
  twos: number;
  freeThrows: number;
  freeThrowsAttempts: number;
  fouls:number;
};

const TeamPlayers = ({ onData, prop, playersData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(playersData)
  }, [playersData]);


  const [grid, setGrid] = useState(false);
  const renderGrid = () => {
    setGrid(!grid);
  };

  const columns = React.useMemo<ColumnDef<PlayerTeam>[]>(
    () => [
      {
        header: "Info",
        columns: [
          {
            header: "Full Name",
            accessorFn: (row) =>
              `${row.playerID.firstname} ${row.playerID.lastname}`,
            cell: (info) => (
              <Button variant="contained" color="inherit" sx={{bgcolor:"black",width:"100%", color:"white", fontWeight:"600", ":hover":{bgcolor:"#bf0202"}}} href={`/players/${info.row.original.playerID._id}`}>
                {info.getValue()}
              </Button>
            ),
            sortDescFirst: true,
          },
          {
            id: "_id",
            header: "⭐",
            accessorFn: (row) => `${row.playerID._id}`,
            cell: (info) => (
              <button onClick={()=>{onData(true); prop(info.getValue())}}>
                <RememberMeIcon />
              </button>
            ),
            enableSorting: false,
            enableColumnFilter: false,
          },
        ],
      },
      {
        header: "Stats",
        columns: [
          {
            header: "GP",
            accessorKey: "gamesPlayed",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "PPG",
            accessorKey: "ppg",
            cell: (info) => Number(info.getValue()).toFixed(1),
            enableColumnFilter: false,
          },
          {
            header: "PTS",
            accessorKey: "pts",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "FGM",
            accessorKey: "fgm",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "3PM",
            accessorKey: "threes",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "FTM",
            accessorKey: "freeThrows",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "FTA",
            accessorKey: "freeThrowsAttempts",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "FT%",
            accessorFn: (row) => `${row.freeThrows / row.freeThrowsAttempts}`,
            cell: (info) => (
              <span>{info.getValue() ? 0 : info.getValue()}%</span>
            ),
            enableColumnFilter: false,
            sortDescFirst: true,
          },
          {
            header: "Fouls",
            accessorKey: "fouls",
            enableColumnFilter: false,
          },
        ],
      },
    ],
    []
  );


  function LocalTable({
    data,
    columns,
  }: {
    data: PlayerTeam[];
    columns: ColumnDef<PlayerTeam>[];
  }) {
    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });

    const [ids, setIds] = useState([]);
   
    const fetchIds = () => {
      const tableBody = document.getElementById("tableBody");
      const rows = tableBody.getElementsByTagName("tr");

      const extractedIds = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const id = row.getAttribute("id");
        extractedIds.push(id);
      }
      setIds(extractedIds);
    };

    useEffect(() => {
      fetchIds();
    }, []);


    return (
      <Box sx={{ width: "100%" }}>
        <div className="relative w-[100%] flex justify-center flex-col items-center">
          <div className="mb-5">
          <Button variant="contained" disabled={!grid} sx={{ margin: "4px", bgcolor: "white", color: "black", fontWeight: "600", ":hover": { bgcolor: "#ff0000" } }} onClick={renderGrid} ><ReorderIcon></ReorderIcon></Button>
          <Button variant="contained" disabled={grid} sx={{ margin: "4px", bgcolor: "white", color: "black", fontWeight: "600", ":hover": { bgcolor: "#ff0000" } }} onClick={renderGrid}><ViewCompactIcon></ViewCompactIcon></Button>
       </div>
        </div>
      <div className={`${!grid?"":"hidden"}`}>
        <TableContainer component={Paper} className="box">
          <div className="boxbox">
            <Table className="tableHover">
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableCell
                          key={header.id}
                          colSpan={header.colSpan}
                          sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              <div>
                                
                              </div>
                              <div
                                {...{
                                  className:"inline-block flex justify-center "
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}{" "}
                                
                              </div>
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody id="tableBody">
                {table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id} id={row.getValue("_id")}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            sx={{ textAlign: "center", fontFamily: "Poppins",  padding:"4px" }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer></div>
        <div className={`grid sm:grid-cols-2 sm:gap-2 lg:grid-cols-4 lg:gap-4 ${grid?"":"hidden"}`} >
          {ids.map((id, index) => (
            <PlayerProfile key={index} id={id} />
          ))}
        </div>

          
      </Box>
    );
  }

  return (
    <>
      <LocalTable {...{ data, columns }} />
    </>
  );
};

export default TeamPlayers;

