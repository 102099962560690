import Title from "./component/Title";

function NotFound() {
  return (
    <div>
      <div className="container flex justify-center items center relative">
        <Title word={"404 Page"}></Title>
        <Title word={"Not Found"}></Title>
      </div>
    </div>
  );
}

export default NotFound;
