import { useState, useEffect, useContext } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Axios from "axios";
import GlobalContext from "../GlobalContext";
import { useSnackbar } from "notistack";
import GameStatsCalculator from "./GameStatsCalculator";

function Event({
  event = null,
  index = null,
  season,
  locations,
  divisions = [],
  maxDate,
  minDate,
  refresh,
  onEdit = null,
}) {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [shouldRenderDatePicker, setShouldRenderDatePicker] = useState(false);
  const [type, setType] = useState("");
  const [gameType, setGameType] = useState("");
  const [location, setLocation] = useState(null);
  const [localTeam, setLocalTeam] = useState("");
  const [guestTeam, setGuestTeam] = useState("");
  const [status, setStatus] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");
  const [teamsData, setTeamsData] = useState([]);
  const [date, setDate] = useState(null);
  const [desc, setDesc] = useState("");
  const [time, setTime] = useState("");
  const [edited, setEdited] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [editedEvent, setEditedEvent] = useState(
    event && event.saved ? { ...event.saved } : { ...event }
  );
  const [open, setOpen] = useState(false);

  const options = { month: "long", day: "numeric", year: "numeric" };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await Axios.post(url + "/games/delete/" + event._id, editedEvent)
        .then((res) => {
          handleResponse("Successfully Deleted Event.", "success");
          refresh();
        })
        // @ts-ignore
        .catch((res) => {
          handleResponse("Error Deleting Event.", "error");
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    handleClose();
  };

  Axios.defaults.withCredentials = true;

  const { enqueueSnackbar } = useSnackbar();

  const handleResponse = (msg, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  useEffect(() => {
    if (onEdit) {
      onEdit(index, editedEvent);
    }
    // Update the parent component when the editedEvent changes
  }, [editedEvent, index]);

  const handleInputChange = (fieldName, value) => {
    if (onEdit) {
      setEditedEvent((prevEvent) => ({
        ...prevEvent,
        [fieldName]: value,
      }));
    }
  };

  const load = async () => {
    setType(event ? (event.saved ? event.saved.type : event.type) : "Game");
    setGameType(
      event ? (event.saved ? event.saved.gameType : event.gameType) : "Playoffs"
    );
    setLocation(
      event
        ? event.saved
          ? event?.saved?.locationID
          : event?.locationID
        : locations.length !== 0
        ? locations[0]
        : null
    );

    setLocalTeam(
      event
        ? event.saved
          ? event.saved.localTeam?._id
          : event.localTeam?._id
        : ""
    );
    setGuestTeam(
      event
        ? event.saved
          ? event.saved.guessTeam?._id
          : event.guessTeam?._id
        : ""
    );
    setStatus(
      event ? (event.saved ? event.saved.status : event.status) : "TBP"
    );
    setSelectedDivision(
      event
        ? event.saved
          ? event.saved?.divisionID?._id
          : event.guessTeam?.divisionID?._id
        : ""
    );
    setDate(
      event ? (event.saved ? event.saved.date : event.date) : await minDate
    );
    setTime(event ? (event.saved ? event.saved.time : event.time) : "21:10");
  };

  const handlePlayerEdit = (updatedPlayers) => {
    if (onEdit) {
      setEditedEvent((prevEvent) => ({
        ...prevEvent,
        saved: updatedPlayers,
      }));
    }
  };

  useEffect(() => {
    load();
    setLoaded(true);
    const delay = 2000;

    const timeoutId = setTimeout(() => {
      setShouldRenderDatePicker(true);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (event && loaded) {
      if (
        event.gameType !== gameType ||
        event.locationID._id !== location._id ||
        event.status !== status ||
        event.date !== date ||
        event.time !== time
      ) {
        setEdited(true);
      } else {
        setEdited(false);
      }
    }
  }, [gameType, location, status, date, time]);

  const handleDivisionChange = (e) => {
    setSelectedDivision(e.target.value);
    fetchTeams(e.target.value);
  };

  const fetchTeams = async (divisionID) => {
    try {
      const response = await Axios.get(
        url + "/teamSeasons/season/" + season + "/division/" + divisionID
      );
      const teams = response.data.map((teamSeason, index) => {
        return {
          _id: teamSeason.teamID._id,
          name: teamSeason.teamID.name,
        };
      });
      setTeamsData(teams);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const postEvent = async () => {
    try {
      if (date) {
        await Axios.post(url + "/games", {
          seasonID: season,
          divisionID: selectedDivision !== "" ? selectedDivision : null,
          type,
          gameType,
          locationID: location._id,
          localTeamID: localTeam !== "" ? localTeam : null,
          guessTeamID: guestTeam !== "" ? guestTeam : null,
          status: type === "Game" ? status : "F",
          date,
          time,
          desc,
        })
          .then((res) => {
            handleResponse("Successfully Created New Event.", "success");
            refresh();
          })
          // @ts-ignore
          .catch((res) => {
            handleResponse("Error Creating New Event.", "error");
          });
      } else {
        handleResponse("Error with Date.", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const editEvent = async () => {
    try {
      await Axios.patch(url + "/games/" + event._id, editedEvent)
        .then((res) => {
          handleResponse("Successfully Edited Event.", "success");
          refresh();
        })
        // @ts-ignore
        .catch((res) => {
          handleResponse("Error Editing Event.", "error");
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (e?.key !== "Enter" && !event) {
      postEvent();
    } else if (event) {
      editEvent();
    }
  };

  return (
    <li className="fade-in">
      <form
        onSubmit={handleSubmit}
        style={{
          border: `solid 2px ${
            event && ["F", "FFT", "CAN"].some((str) => str === event.status)
              ? "#ff0000"
              : edited
              ? "#ffa726"
              : "white"
          }`,
        }}
        className={` rounded-lg shadow-2xl my-4 overflow-auto ${
          edited
            ? "shadow-[#ffa726]"
            : event && ["F", "FFT", "CAN"].some((str) => str === event.status)
            ? "hover:shadow-red-600"
            : "hover:shadow-white"
        } duration-300 transition-all bg-black w-full flex flex-col flex-wrap lg:flex-nowrap`}
      >
        {event && (
          <div
            style={{
              backgroundColor:
                event && ["F", "FFT", "CAN"].some((str) => str === event.status)
                  ? "#ff0000"
                  : edited
                  ? "#ffa726"
                  : "white",
            }}
            className={`w-full grid sm:grid-cols-2 lg:grid-cols-4 text-black  text-center duration-500`}
          >
            <div className="flex justify-center flex-col p-2">
              <div className="w-full flex justify-center">
                <h1
                  className={`font-extrabold border-b-2 duration-500 ${
                    ((event &&
                      ["F", "FFT", "CAN"].some(
                        (str) => str === event.status
                      )) ||
                      edited) &&
                    "border-black"
                  } w-fit`}
                >
                  TYPE
                </h1>
              </div>
              <p>{event.type}</p>
            </div>
            {event.type === "Game" && (
              <div className="flex justify-center flex-col p-2">
                <div className="w-full flex justify-center">
                  <h1
                    className={`font-extrabold border-b-2 duration-500 ${
                      ((event &&
                        ["F", "FFT", "CAN"].some(
                          (str) => str === event.status
                        )) ||
                        edited) &&
                      "border-black"
                    } w-fit`}
                  >
                    DIVISION
                  </h1>
                </div>
                <p>{event.divisionID.titleEn}</p>
              </div>
            )}
            {event.type === "Game" && (
              <div className="flex justify-center flex-col p-2">
                <div className="w-full flex justify-center">
                  <h1
                    className={`font-extrabold border-b-2 duration-500 ${
                      ((event &&
                        ["F", "FFT", "CAN"].some(
                          (str) => str === event.status
                        )) ||
                        edited) &&
                      "border-black"
                    } w-fit`}
                  >
                    MATCHUP
                  </h1>
                </div>
                <p>
                  {event.guessTeam.name}
                  {" @"} {event.localTeam.name}
                </p>
              </div>
            )}

            {event.type === "Game" && (
              <div className="flex justify-center flex-col p-2">
                <div className="w-full flex justify-center">
                  <h1
                    className={`font-extrabold border-b-2 duration-500 ${
                      ((event &&
                        ["F", "FFT", "CAN"].some(
                          (str) => str === event.status
                        )) ||
                        edited) &&
                      "border-black"
                    } w-fit`}
                  >
                    STATUS
                  </h1>
                </div>
                <p>{event.status}</p>
              </div>
            )}
            {event &&
              event.type === "Game" &&
              ["F", "FFT", "CAN"].some((str) => str === event.status) && (
                <div className="flex justify-center flex-col p-2">
                  <div className="w-full flex justify-center">
                    <h1
                      className={`font-extrabold border-b-2 duration-500 ${
                        ((event &&
                          ["F", "FFT", "CAN"].some(
                            (str) => str === event.status
                          )) ||
                          edited) &&
                        "border-black"
                      } w-fit`}
                    >
                      GAME TYPE
                    </h1>
                  </div>
                  <p>{event.gameType}</p>
                </div>
              )}
            {event &&
              ["F", "FFT", "CAN"].some((str) => str === event.status) && (
                <div className="flex justify-center flex-col p-2">
                  <div className="w-full flex justify-center">
                    <h1
                      className={`font-extrabold border-b-2 duration-500 ${
                        ((event &&
                          ["F", "FFT", "CAN"].some(
                            (str) => str === event.status
                          )) ||
                          edited) &&
                        "border-black"
                      } w-fit`}
                    >
                      DATE
                    </h1>
                  </div>
                  <p>
                    {new Date(event.date).toLocaleDateString("en-US", options)}
                  </p>
                </div>
              )}
            {event &&
              ["F", "FFT", "CAN"].some((str) => str === event.status) && (
                <div className="flex justify-center flex-col p-2">
                  <div className="w-full flex justify-center">
                    <h1
                      className={`font-extrabold border-b-2 duration-500 ${
                        ((event &&
                          ["F", "FFT", "CAN"].some(
                            (str) => str === event.status
                          )) ||
                          edited) &&
                        "border-black"
                      } w-fit`}
                    >
                      TIME
                    </h1>
                  </div>
                  <p>{event.time}</p>
                </div>
              )}
            {event &&
              ["F", "FFT", "CAN"].some((str) => str === event.status) && (
                <div className="flex justify-center flex-col p-2">
                  <div className="w-full flex justify-center">
                    <h1
                      className={`font-extrabold border-b-2 duration-500 ${
                        ((event &&
                          ["F", "FFT", "CAN"].some(
                            (str) => str === event.status
                          )) ||
                          edited) &&
                        "border-black"
                      } w-fit`}
                    >
                      LOCATION
                    </h1>
                  </div>
                  <p>{event.locationID.name}</p>
                </div>
              )}
          </div>
        )}
        <div className=" p-4 w-fit flex gap-2 flex-wrap ">
          {!event && (
            <FormControl sx={{ width: 120 }}>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <MenuItem value={"Game"}>Game</MenuItem>
                <MenuItem value={"Gala"}>Gala</MenuItem>
              </Select>
            </FormControl>
          )}

          {type === "Game" && (
            <div className="flex gap-2">
              {!event && (
                <FormControl sx={{ width: 120 }} className="fade-in">
                  <InputLabel id="type-label">Division</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Division"
                    value={selectedDivision}
                    onChange={handleDivisionChange}
                    required
                  >
                    {divisions.map((division, index) => (
                      <MenuItem key={index} value={division._id}>
                        {division.title}
                      </MenuItem>
                    ))}{" "}
                  </Select>
                </FormControl>
              )}

              {!["F", "FFT", "CAN"].some((str) => str === event?.status) && (
                <FormControl sx={{ width: 120 }} className="fade-in">
                  <InputLabel id="type-label">Game Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Game Type"
                    value={gameType}
                    required
                    onChange={(e) => {
                      setGameType(e.target.value);
                      handleInputChange("gameType", e.target.value);
                    }}
                    onDoubleClick={() => {
                      setGameType(event.gameType);
                      handleInputChange("gameType", event.gameType);
                    }}
                  >
                    <MenuItem value={"Season Game"}>Season Game</MenuItem>
                    <MenuItem value={"Playoffs"}>Playoffs</MenuItem>
                    <MenuItem value={"Quarter Finals"}>Quarter Finals</MenuItem>
                    <MenuItem value={"Semi Finals"}>Semi Finals</MenuItem>
                    <MenuItem value={"Finals"}>Finals</MenuItem>
                    <MenuItem value={"Free"}>Free</MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
          )}
          {!["F", "FFT", "CAN"].some((str) => str === event?.status) && (
            <FormControl sx={{ width: 150 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {shouldRenderDatePicker && (
                  <DatePicker
                    onChange={(e) => {
                      setDate(e.$d);
                      handleInputChange("date", e.$d);
                    }}
                    value={dayjs(date)}
                    minDate={dayjs(minDate)}
                    maxDate={dayjs(maxDate)}
                    slotProps={{
                      textField: {
                        label: "Date",
                        onDoubleClick: () => {
                          setDate(event.date);
                          handleInputChange("date", event.date);
                        },
                      },
                    }}
                  />
                )}
              </LocalizationProvider>
            </FormControl>
          )}
          {!["F", "FFT", "CAN"].some((str) => str === event?.status) && (
            <FormControl sx={{ width: 150 }}>
              <TextField
                id="time"
                label="Time"
                type="time"
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                  handleInputChange("time", e.target.value);
                }}
                onDoubleClick={() => {
                  setTime(event.time);
                  handleInputChange("time", event.time);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </FormControl>
          )}
          {!["F", "FFT", "CAN"].some((str) => str === event?.status) && (
            <FormControl sx={{ width: 120 }}>
              <InputLabel id="type-label">Location</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Location"
                value={location?._id || ""}
                onChange={(e) => {
                  setLocation(
                    locations.find(
                      (location) => location._id === e.target.value
                    )
                  );
                  handleInputChange(
                    "locationID",
                    locations.find(
                      (location) => location._id === e.target.value
                    )
                  );
                }}
                onDoubleClick={() => {
                  setLocation(event.locationID);
                  handleInputChange("locationID", event.locationID);
                }}
                required
              >
                {locations?.map((location, index) => (
                  <MenuItem value={location._id} key={index}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {type === "Game" && selectedDivision !== "" && !event && (
            <div className="flex items-center gap-2 font-bold flex-wrap fade-in">
              Matchup :
              <FormControl sx={{ width: 120 }}>
                <InputLabel id="local-label">Local Team</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Local Team"
                  value={localTeam}
                  onChange={(e) => {
                    setLocalTeam(e.target.value);
                    handleInputChange("localTeamID", e.target.value);
                  }}
                  required
                >
                  {teamsData.map((team, index) => (
                    <MenuItem value={team._id} key={index}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              vs
              <FormControl sx={{ width: 120 }}>
                <InputLabel id="guess-label">Guess Team</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Guess Team"
                  value={guestTeam}
                  onChange={(e) => {
                    setGuestTeam(e.target.value);
                    handleInputChange("guessTeamID", e.target.value);
                  }}
                  required
                >
                  {teamsData.map((team, index) => (
                    <MenuItem value={team._id} key={index}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {type === "Game" && event && event.status !== "F" && (
            <div className="flex items-center gap-2 font-bold flex-wrap fade-in">
              {!["F", "FFT", "CAN"].some((str) => str === event?.status) && (
                <FormControl sx={{ width: 120 }} className="fade-in">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      handleInputChange("status", e.target.value);
                    }}
                    onDoubleClick={() => {
                      setStatus(event.status);
                      handleInputChange("status", event.status);
                    }}
                    required
                  >
                    <MenuItem value={"F"}>Final</MenuItem>
                    <MenuItem value={"TBP"}>To be Played</MenuItem>
                    <MenuItem value={"CAN"}>Canceled</MenuItem>
                    <MenuItem value={"PPD"}>Postponed</MenuItem>
                    <MenuItem value={"SPD"}>Suspended</MenuItem>
                    <MenuItem value={"FFT"}>Forfeit</MenuItem>
                    <MenuItem value={"N/R"}>Score Not Reported</MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
          )}
        </div>
        {type === "Gala" && !event && (
          <div className="w-full flex justify-center m-4">
            <FormControl sx={{ width: "90%" }}>
              <TextField
                autoComplete="off"
                id="outlined-multiline-flexible"
                label="Description"
                name="desc"
                multiline
                rows={6}
                onChange={(e) => {
                  setDesc(e.target.value);
                }}
                inputProps={{ maxLength: 256 }}
              />
            </FormControl>
          </div>
        )}
        {event &&
        !["F", "FFT", "CAN"].some((str) => str === event.status) &&
        (status === "F" || status === "FFT") ? (
          <div className="w-full relative flex justify-center ">
            <GameStatsCalculator
              game={editedEvent}
              onSubmit={handleSubmit}
              onEdit={handlePlayerEdit}
            />
          </div>
        ) : !event ? (
          <div className="w-full relative flex justify-center pb-4">
            <Button
              variant="outlined"
              className="w-[80%]"
              type="submit"
              color="success"
            >
              Add
            </Button>
          </div>
        ) : (
          <div className="w-full relative flex justify-center gap-2 pb-4">
            {edited && (
              <Button
                variant="outlined"
                className="w-[80%] fade-in"
                type="submit"
                color="warning"
              >
                Edit
              </Button>
            )}

            <IconButton
              className="self-end"
              color="error"
              size="medium"
              sx={{ border: "solid 2px #f44336" }}
              onClick={handleOpen}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Delete Game</DialogTitle>
              <DialogContent>Are you sure you want to delete?</DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleConfirmDelete}
                  variant="outlined"
                  color="error"
                >
                  Confirm Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </form>
    </li>
  );
}

export default Event;
