import { useState } from "react";
import Title from "./component/Title";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FAQ() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <div className="container">
        <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
          <Title word="FAQ" />
        </div>

        <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <div>
            {/* <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="border-4 border-black sm-shadow"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="bg-gradient-to-tr from-[#ff0000] to-[#ab0000] font-bold p-0"
              >
                <h1 className="font-Poppins  text-lg">General settings</h1>
              </AccordionSummary>
              <AccordionDetails
                sx={{ padding: "16px 24px" }}
                className=" border-t-4 border-black flex justify-center items-center flex-col"
              >
                <p className="font-Poppins font-semibold">
                  Nulla facilisi. Phasellus sollicitudin nulla et quam mattis
                  feugiat. Aliquam eget maximus est, id dignissim quam.
                </p>
                <p className="font-Poppins font-bold w-[80%] bgRed rounded-md  px-8 py-4 mt-4 sm-shadow text-black text-center">
                  Nulla facilisi. Phasellus sollicitudin nulla et quam mattis
                  feugiat. Aliquam eget maximus est, id dignissim quam.
                </p>
                <Typography></Typography>
              </AccordionDetails>
            </Accordion> */}
            <h2 className="text-2xl font-bold text-blue-300 mb-4">
              When and at what time are the games played?
            </h2>
            <p>
              Wednesday or Thursday at 21h10. See the schedule for more details.
            </p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              What are the dates for the fall session?
            </h2>
            <p>See schedule for your session.</p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              How much is a session?
            </h2>
            <p>$200 for regular and $220 for advanced.</p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              Can I pay by installments?
            </h2>
            <p>Full amount is required when you receive your bill.</p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              How can I pay?
            </h2>
            <p>
              Interac E-Transfer at:{" "}
              <a
                href="mailto:info@afterworkleagues.com"
                className="text-blue-300"
              >
                info@afterworkleagues.com
              </a>
            </p>
            <p>Question = League name</p>
            <p>Answer = Afterwork</p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              Is there showers and lockers?
            </h2>
            <p>
              Yes, lockers with showers for both men and women are available.
            </p>
            <p>
              You must use those lockers to change. We highly recommend the use
              of a padlock.
            </p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              Is there a parking lot?
            </h2>
            <p>
              Yes, there’s a free parking lot at the entrance of the sports
              center with limited places.
            </p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              What happens in case of an incident?
            </h2>
            <p>
              An incident or accident report will be filled by a sports center
              employee, and you will get a copy.
            </p>
            <p>
              In case of an accident, if need be, we will contact the «Emergency
              Contact» that you have provided in the registration request form.
              We are not responsible in case of theft. Again, we recommend the
              use of a padlock.
            </p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              Can I get reimbursed in case of an incident?
            </h2>
            <p>
              There are no reimbursements except in case of an exceptional
              situation. These cases will be treated case by case.
            </p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              What’s the nearest metro station?
            </h2>
            <p>
              Nearest metro station is St-Michel. From there, take the 67 north
              all the way to Champ-Doré street.
            </p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              I want to play but I don’t have enough players on my team.
            </h2>
            <p>
              If your team does not have the minimum player required (or if
              you're alone), please contact us as soon as possible, and we will
              try to combine your team with other players as long as there is a
              place in the league for another team.
            </p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              Can I subscribe if the league has already started?
            </h2>
            <p>
              Yes, if the maximum of 15 players in a team is respected. You will
              be charged the full regular price if you subscribe before game 5.
              After the 5th game, you will be charged according to the
              calculation: (Price - $20) / 2 + $20, regardless of the number of
              games left.
            </p>

            <h2 className="text-2xl font-bold text-blue-300 mt-6">
              Do I need to create an account on your website in order to play in
              the league?
            </h2>
            <p>
              No. This option is to get email and text alerts, schedule
              reminders, and more.
            </p>
            <p>
              You must, however, have completed your form and made your full
              payment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
