import React, { useCallback, useMemo } from "react";
import Title from "./component/Title";
import GamesList from "./component/GamesList";
import { useState, useEffect, useContext } from "react";
import GlobalContext from "./GlobalContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";
import { Button, Modal } from "@mui/material";
import ReorderIcon from "@mui/icons-material/Reorder";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
const options = { month: "long", day: "numeric", year: "numeric" };

function Schedules() {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [isLoading, setIsLoading] = useState(true);
  const [gamesData, setGamesData] = useState([]);
  const [seasonsData, setSeasonsData] = useState([]);
  const [seasonData, setSeasonData] = useState(null);
  const [divisionsData, setDivisionsData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedDivision, setSelectedDivsion] = useState("");
  const [grid, setGrid] = useState(true);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dots, setDots] = useState(true);
  const [teamsData, setTeamsData] = useState([]);
  const [teamsByDivision, setTeamsByDivision] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("all");

  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => !prevDots);
    }, 5000); // Change the interval as needed (e.g., switch every 1000 milliseconds)

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = (value) => {
    setOpen(true);
    setEvent(value);
  };

  const handleClose = () => {
    setOpen(false);
    setEvent(null);
  };

  const handleTeamChangeList = (e) => {
    setSelectedTeam(e.target.value);
    fetchEvents(selectedSeason, e.target.value);
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
    fetchGames(selectedSeason, selectedDivision, e.target.value);
  };

  const handleGrid = () => {
    setLoading(true);
    if (grid) {
      fetchEvents(selectedSeason, "all");
    } else {
      fetchGames(selectedSeason, selectedDivision, "all");
    }
    setGrid(!grid);
    setSelectedTeam("all");
  };

  Axios.defaults.withCredentials = true;

  const fetchSeasonsData = async () => {
    await Axios.get(url + "/seasons")
      .then((response) => {
        if (response.data.length) {
          setSeasonData(response.data[0]);
          setSeasonsData(response.data);
          setSelectedSeason(response.data[0]._id);
          fetchDivisionsData(response.data[0]._id);
          fetchEvents(response.data[0]._id, "all");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchDivisionsData = async (seasonID) => {
    await Axios.get(url + "/seasonDivisions/" + seasonID)
      .then((response) => {
        setDivisionsData(response.data);
        if (response.data.length) {
          if (grid) {
            setSelectedDivsion(response.data[0].divisionID._id);
            fetchGames(seasonID, response.data[0].divisionID._id, "all");
            setSelectedTeam("all");
            fetchTeams(seasonID, response.data[0].divisionID._id);
          } else {
            fetchTeams(seasonID, response.data[0]._id);
          }
        } else {
          setSelectedDivsion("");
          setGamesData([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchEvents = async (seasonID, team) => {
    try {
      if (team === "all") {
        await Axios.get(url + "/games/season/" + seasonID).then((response) => {
          setEvents([]);
          setLoading(true);
          if (response.data.length > 0) {
            const events = response.data.map((event) => {
              //look into saved array if there is a copy of an event and .saved = copy
              return {
                _id: event._id,
                title:
                  event.type === "Game"
                    ? event.guessTeamID.name + " @" + event.localTeamID.name
                    : "Gala",
                date: event.date,
                time: event.time,
                divisionID: event.divisionID,
                seasonID: event.seasonID,
                localTeam: event.localTeamID,
                guessTeam: event.guessTeamID,
                localTeamPTS: event.localTeamPTS,
                guessTeamPTS: event.guessTeamPTS,
                locationID: event.locationID,
                status: event.status,
                type: event.type,
                gameType: event.gameType,
                winner: event.winner,
                backgroundColor:
                  event.gameType === "Free"
                    ? "#5CD85A"
                    : event.divisionID
                    ? event.divisionID.color
                    : "#ffffff",
              };
            });
            setEvents(events);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1200);
        });
      } else {
        await Axios.get(
          url + "/games/season/" + seasonID + "/team/" + team
        ).then((response) => {
          setEvents([]);
          setLoading(true);
          if (response.data.length > 0) {
            const events = response.data.map((event) => {
              //look into saved array if there is a copy of an event and .saved = copy
              return {
                _id: event._id,
                title: event.guessTeamID.name + " @" + event.localTeamID.name,
                date: event.date,
                time: event.time,
                divisionID: event.divisionID,
                seasonID: event.seasonID,
                localTeam: event.localTeamID,
                guessTeam: event.guessTeamID,
                localTeamPTS: event.localTeamPTS,
                guessTeamPTS: event.guessTeamPTS,
                locationID: event.locationID,
                status: event.status,
                type: event.type,
                gameType: event.gameType,
                desc: event.desc,
                winner: event.winner,
                backgroundColor:
                  event.gameType === "Free"
                    ? "#5CD85A"
                    : event.divisionID
                    ? event.divisionID.color
                    : "#ffffff",
              };
            });
            setEvents(events);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1200);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchGames = async (seasonID, divisionID, team) => {
    if (team === "all") {
      try {
        await Axios.get(
          url + "/games/season/" + seasonID + "/division/" + divisionID
        ).then((response) => {
          setLoading(true);
          if (response.data.length > 0) {
            setGamesData(response.data);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1200);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      try {
        await Axios.get(
          url +
            "/games/season/" +
            seasonID +
            "/division/" +
            divisionID +
            "/team/" +
            team
        ).then((response) => {
          setLoading(true);
          if (response.data.length > 0) {
            setGamesData(response.data);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1200);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const fetchTeams = async (seasonID, divisionID) => {
    try {
      await Axios.get(url + "/teamSeasons/all/season/" + seasonID).then(
        (response) => {
          if (response.data.length > 0) {
            setTeamsData(response.data);
          }
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    await Axios.get(
      url + "/teamSeasons/season/" + seasonID + "/division/" + divisionID
    ).then((response) => {
      if (response.data.length > 0) {
        setTeamsByDivision(response.data);
      }
    });
  };

  const getWeekNumberContent = useCallback(
    (arg) => {
      const startDate = new Date(seasonData?.from);
      const currentWeek = Math.ceil(
        (arg.date - startDate) / (7 * 24 * 60 * 60 * 1000) + 1
      );
      return currentWeek.toString();
    },
    [seasonData]
  );

  const fullCalendarProps = useMemo(
    () => ({
      plugins: [dayGridPlugin],
      initialView: "dayGridMonth",
      defaultAllDay: true,
      height: "auto",

      weekNumbers: true,
      weekNumberClassNames: "weekNumbers",
      weekText: "W ",
      validRange: {
        start: seasonData?.from,
        end: seasonData?.to,
      },
      weekNumberContent: getWeekNumberContent,
      weekNumberCalculation: "local",
      expandRows: true,
      eventClick: (e) => {
        handleOpen(e.event);
      },
      events: { events },
    }),
    [getWeekNumberContent, handleOpen, seasonData, events]
  );

  useEffect(() => {
    fetchSeasonsData();
  }, []);

  const handleSeasonChange = (event) => {
    fetchDivisionsData(event.target.value);
    setSelectedSeason(event.target.value);
    setSeasonData(seasonsData.find((season) => season?._id === selectedSeason));
  };

  const handleDivisionChange = (event) => {
    setSelectedTeam("all");
    fetchTeams(selectedSeason, event.target.value);
    fetchGames(selectedSeason, event.target.value, "all");
    setSelectedDivsion(event.target.value);
  };
  if (isLoading) {
    return (
      <div className="w-[100%] h-[100vh] flex justify-center items-center">
        <CircularProgress sx={{ color: "white" }} />
      </div>
    );
  } else {
    return (
      <div className="fade-in">
        <div className="container">
          <div className="absolute w-screen h-screen ">
            {event && (
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                }}
              >
                <div
                  className={`pt-10 flex relative zoom sm:h-[80vh] sm:max-h-[600px]  lg:max-h-[800px] md:h-[80vh] max-w-[95vw]  sm:aspect-[6/8] flex-col  border-[${event.backgroundColor}] rounded-lg sm-shadow text-black bgBlack `}
                  style={{ border: "solid 8px " + event.backgroundColor }}
                >
                  <div className="w-[100%] h-[100%] absolute  top-0 overflow-hidden  border-b-0 border-black rounded-t-xl">
                    <img
                      src="../assets/img/AW_allblack.png"
                      className="object-cover h-[100%] selector absolute left-1/2 translate-x-[-50%]"
                    ></img>
                  </div>
                  {event._def.extendedProps.type === "Game" && (
                    <div
                      className={`absolute top-0 left-0 p-2 text-black  rounded-br-xl flex gap-1 z-10`}
                      style={{ backgroundColor: "" + event.backgroundColor }}
                    >
                      <p>{event?.start.toLocaleDateString("en-US", options)}</p>{" "}
                      -
                      <p className="font-bold">
                        {event._def.extendedProps.time}
                      </p>
                    </div>
                  )}
                  {event._def.extendedProps.type === "Game" && (
                    <div className="w-full h-fit font-extrabold sm:text-3xl mt-8 lg:text-4xl flex flex-col justify-center items-center text-white z-10">
                      {" "}
                      <div className="w-full font-extrabold sm:text-sm lg:text-base  flex justify-center items-center text-white z-10">
                        {" "}
                        {event._def.extendedProps.divisionID.titleEn.toUpperCase()}
                      </div>
                      {event._def.extendedProps.gameType.toUpperCase()}
                      <div className="w-full font-extrabold sm:text-sm lg:text-base  flex justify-center items-center text-white z-10">
                        {" "}
                        {event._def.extendedProps.status.toUpperCase()}
                      </div>
                    </div>
                  )}
                  {event._def.extendedProps.type === "Game" && (
                    <div className=" flex sm:flex-col  duration-75 w-full h-[85%] justify-evenly">
                      {/* Guess team */}
                      <div className="  w-full relative flex justify-center items-center">
                        <div className="w-3/4 relative pb-1  ">
                          <a
                            href={
                              "/team/" + event._def.extendedProps.guessTeam?._id
                            }
                          >
                            <Title
                              word={event._def.extendedProps.guessTeam?.name}
                            />
                          </a>
                          <span className="absolute w-[100%] top-0 left-[-50%]  text-center translate-x-[50%] translate-y-[-100%] text-white text-sm z-40">
                            Visitor
                          </span>
                          <span className="absolute w-fit bottom-0 left-1/2  text-center translate-x-[-50%] translate-y-[80%] font-bold text-white text-2xl z-40">
                            {["F", "FFT", "CAN"].some(
                              (str) => str === event?._def.extendedProps.status
                            ) && event._def.extendedProps.guessTeamPTS}
                            {event._def.extendedProps.winner ===
                            event._def.extendedProps.guessTeam?._id ? (
                              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white z-40"></span>
                            ) : (
                              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-transparent z-40"></span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="sm:w-full relative flex justify-center items-center text-white">
                        {event._def.extendedProps.status === "FFT" ||
                        event._def.extendedProps.status === "F" ? (
                          dots ? (
                            <div className="fade-in opacity-0">
                              <Button
                                size="large"
                                className="sm:h-12 lg:h-12 sm:w-44 lg:w-64 border-transparent"
                                onClick={() =>
                                  navigate(
                                    `/game/${event._def.extendedProps?._id}`
                                  )
                                }
                                sx={{
                                  border: "solid 2px transparent",
                                  "&:hover": {
                                    color: "white",
                                    backgroundColor: "black",
                                    border: "solid 2px white",
                                  },
                                }}
                              >
                                <div className="dot-bouncing lg:text-3xl">
                                  <span className="sm-shadow"></span>
                                  <span className="sm-shadow"></span>
                                  <span className="sm-shadow"></span>
                                </div>
                              </Button>
                            </div>
                          ) : (
                            <div className="fade-in">
                              <Button
                                size="large"
                                variant="contained"
                                color="inherit"
                                sx={{
                                  fontWeight: 800,
                                  color: "black",
                                  backgroundColor: "white",
                                  "&:hover": {
                                    color: "white",
                                    backgroundColor: "black",
                                    border: "solid 2px white",
                                  },
                                }}
                                className="sm-shadow sm:h-12 lg:h-12 sm:w-44 lg:w-64 text-black"
                                onClick={() =>
                                  navigate(
                                    `/game/${event._def.extendedProps?._id}`
                                  )
                                }
                              >
                                See More
                              </Button>
                            </div>
                          )
                        ) : (
                          <div className="relative flex  justify-center items-center stats-font text-center ">
                            <span className="absolute fontRed z-20 font-extrabold sm:text-3xl md:sm:text-4xl lg:text-5xl text-white">
                              vs
                            </span>
                            <span
                              data-content={`vs`}
                              className="customShadow z-10 font-extrabold sm:text-3xl md:sm:text-4xl lg:text-5xl select-none"
                            >
                              vs
                            </span>
                          </div>
                        )}
                      </div>
                      {/* Local team */}
                      <div className=" w-full relative flex justify-center items-center">
                        <div className="w-3/4 relative pb-1  ">
                          <a
                            href={
                              "/team/" + event._def.extendedProps.localTeam?._id
                            }
                          >
                            <Title
                              word={event._def.extendedProps.localTeam?.name}
                            />
                          </a>
                          <span className="absolute w-[100%] top-0 left-[-50%]  text-center translate-x-[50%] translate-y-[-100%] text-white text-sm z-40">
                            Home
                          </span>
                          <span className="absolute w-fit bottom-0 left-1/2  text-center translate-x-[-50%] translate-y-[80%] font-bold text-white text-2xl z-40">
                            {["F", "FFT", "CAN"].some(
                              (str) => str === event?._def.extendedProps.status
                            ) && event._def.extendedProps.localTeamPTS}
                            {event._def.extendedProps.winner ===
                            event._def.extendedProps.localTeam?._id ? (
                              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white z-40"></span>
                            ) : (
                              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-transparent z-40"></span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {event._def.extendedProps.type === "Gala" && (
                    <div className="sm:mt-12 lg:mt-24">
                      <Title word={event._def.extendedProps.type} />
                    </div>
                  )}
                  {event._def.extendedProps.type === "Gala" && (
                    <div className="flex flex-col h-full justify-center items-center text-white z-10">
                      <div
                        className="text-center block w-3/4 sm:text-base lg:text-lg"
                        dangerouslySetInnerHTML={{
                          __html: event._def.extendedProps.desc.replace(
                            /\n/g,
                            "<br>"
                          ),
                        }}
                      ></div>
                    </div>
                  )}
                  <div className="flex w-full h-[15%]">
                    <a
                      href={
                        "/location/" + event._def.extendedProps.locationID?._id
                      }
                      className="text-white text-xl w-full flex items-center justify-center pb-4 z-10"
                    >
                      <LocationOnIcon className="sm-shadow" />{" "}
                      {event._def.extendedProps.locationID?.name}
                    </a>
                  </div>
                </div>
              </Modal>
            )}
          </div>
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="SCHEDULES" />
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative my-6 w-[100%] flex justify-center flex-col items-center">
              <div className="mb-5">
                <Button
                  variant="contained"
                  disabled={!grid}
                  sx={{
                    margin: "4px",
                    bgcolor: "white",
                    color: "black",
                    fontWeight: "600",
                    ":hover": { bgcolor: "#ff0000" },
                  }}
                  onClick={(e) => handleGrid()}
                >
                  <ReorderIcon></ReorderIcon>
                </Button>
                <Button
                  variant="contained"
                  disabled={grid}
                  sx={{
                    margin: "4px",
                    bgcolor: "white",
                    color: "black",
                    fontWeight: "600",
                    ":hover": { bgcolor: "#ff0000" },
                  }}
                  onClick={(e) => setGrid(!grid)}
                >
                  <ViewCompactIcon></ViewCompactIcon>
                </Button>
              </div>
              {!grid && (
                <div className="flex fade-in sm:flex-wrap sm:justify-center ">
                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="season-label">Seasons</InputLabel>
                    <Select
                      labelId="season-label"
                      label={"Seasons"}
                      onChange={handleSeasonChange}
                      value={selectedSeason}
                    >
                      {seasonsData.map((season, index) => (
                        <MenuItem value={season._id} key={index}>
                          {season.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="division-label">Divisions</InputLabel>
                    <Select
                      labelId="division-label"
                      label={"Division"}
                      onChange={handleDivisionChange}
                      value={selectedDivision}
                    >
                      {divisionsData.map((division, index) => (
                        <MenuItem value={division.divisionID._id} key={index}>
                          {division.divisionID.titleEn}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="season-label">Team</InputLabel>
                    <Select
                      labelId="season-label"
                      label={"Team"}
                      onChange={handleTeamChange}
                      value={selectedTeam}
                    >
                      <MenuItem value={"all"}>All Teams</MenuItem>
                      {teamsByDivision?.map((ts, index) => (
                        <MenuItem value={ts.teamID._id} key={index}>
                          {ts.teamID.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
                Games
                <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
              </div>
              {loading ? (
                <div className="mt-12">
                  <CircularProgress />
                </div>
              ) : grid ? (
                <div className="w-full fade-in h-fit overflow-auto relative">
                  <div className="flex sm:flex-col lg:flex-row justify-center items-center">
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="season-label">Seasons</InputLabel>
                      <Select
                        labelId="season-label"
                        label={"Seasons"}
                        onChange={handleSeasonChange}
                        value={selectedSeason}
                      >
                        {seasonsData.map((season, index) => (
                          <MenuItem value={season._id} key={index}>
                            {season.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="season-label">Team</InputLabel>
                      <Select
                        labelId="season-label"
                        label={"Team"}
                        onChange={handleTeamChangeList}
                        value={selectedTeam}
                      >
                        <MenuItem value={"all"}>All Teams</MenuItem>
                        {teamsData?.map((ts, index) => (
                          <MenuItem value={ts.teamID._id} key={index}>
                            {ts.teamID.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="w-full fade-in h-fit overflow-auto">
                    <FullCalendar {...fullCalendarProps} />
                  </div>
                </div>
              ) : (
                <GamesList games={gamesData} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Schedules;
