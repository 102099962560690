import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Moment from "react-moment";

import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

type TeamGame = {
  gameID:{
    localTeamID:{
      _id:string,
      name:string,
    },
    guessTeamID:{
      _id:string,
      name:string
    },
    status:string
    date:Date,
    winner:string,
    localTeamPTS:number,
    guessTeamPTS:number
  };
  ppg: number;
  fgm: number;
  pts: number;
  threes: number;
  twos: number;
  freeThrows: number;
  freeThrowsAttemps: number;
  fouls: number;
  win:Boolean;
};

const TeamGamesList = ({games}) => {

  const data = games;

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = React.useMemo<ColumnDef<TeamGame>[]>(
    () => [
      
          {
            accessorFn: (row) => row.gameID.date,
            header: "Date",
              
            cell: (info) => (<div className="flex flex-col">
              <Moment date={info.getValue<any>()} format={"ddd"}></Moment>
              <Moment date={info.getValue<any>()} format={"DD/MM/YYYY"}></Moment>
            </div>
            )
          },{
            accessorFn: (row) => row.gameID.status,
            header: "Status",
              
            cell: (info) => (info.getValue()!== "TBP" && info.getValue()!== "F"?(<p className="text-red-500">{info.getValue<any>()}</p>
            ):(<p>{info.getValue<any>()}</p>)),
            enableSorting: false,
          },{
            header: "Score",
            accessorFn: (row) => `${row.gameID.localTeamPTS} ${row.gameID.guessTeamPTS}`,
            cell: (info) => (
               info.row.original.gameID.localTeamPTS + info.row.original.gameID.guessTeamPTS !== 0?(<div>{info.row.original.gameID.localTeamPTS.toLocaleString()} - {info.row.original.gameID.guessTeamPTS.toLocaleString()}</div>):(<div>-</div>)
            ),
            enableSorting: false,
            enableColumnFilter: false,
          },{
            header: "Home",
            accessorFn: (row) => row.gameID.localTeamID.name,
            cell: (info) => (
              <div>
                <p className={`${info.row.original.gameID.winner===info.row.original.gameID.localTeamID._id?"text-green-500 font-bold":""}`}>{info.row.original.gameID.localTeamID.name}</p>
              </div>
            ),
            enableSorting: false,
          }
          ,{
            header: "Visitors",
            accessorFn: (row) => row.gameID.guessTeamID.name,
            cell: (info) => (
              <div>
                <p className={`${info.row.original.gameID.winner===info.row.original.gameID.guessTeamID._id?"text-green-500 font-bold":""}`}>{info.row.original.gameID.guessTeamID.name}</p>
              </div>
            ),
            enableSorting: false,
          },
    ],
    []
  );

  function LocalTable({
    data,
    columns,
  }: {
    data: TeamGame[];
    columns: ColumnDef<TeamGame>[];
  }) {
    const table = useReactTable({
      data,
      columns,
      // Pipeline
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      //
    });

    return (
      <Box sx={{ width: "100%" }}>

        <TableContainer component={Paper} className="box">
          <div className="boxbox">
            <Table>
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableCell
                          key={header.id}
                          colSpan={header.colSpan}
                          sx={{ fontFamily: "Poppins", fontWeight: "bold" }}
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none flex-col flex align-center items-center sortLabel"
                                    : "inline-block flex justify-center ",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}{" "}
                                {header.column.getCanSort() &&
                                  header.column.getIsSorted() !== "asc" &&
                                  header.column.getIsSorted() !== "desc" && (
                                    <div className={`inline-block`}>
                                      <ExpandMoreSharpIcon
                                        className={` inline-block sort sortHover`}
                                      />
                                    </div>
                                  )}
                                {(header.column.getIsSorted() === "asc" ||
                                  header.column.getIsSorted() === "desc") && (
                                  <div className={`inline-block`}>
                                    <ExpandMoreSharpIcon
                                      className={` inline-block sort ${
                                        header.column.getIsSorted() === "asc"
                                          ? "sortFlip"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody >
                {table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id} id={row.getValue("_id")}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            sx={{ textAlign: "center", fontFamily: "Poppins",  padding:"4px"}}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Box>
    );
  }

  return (
    <>
      <LocalTable {...{ data, columns }} />
    </>
  );
};

export default TeamGamesList