import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PlayerStats from "./component/PlayerStats";
import Axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import GlobalContext from "./GlobalContext";
import PlayerGamesList from "./component/PlayerGamesList";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import PlayerAwards from "./component/PlayerAwards";
import PersonIcon from "@mui/icons-material/Person";

const Player = ({ onData, prop }) => {
  const { id } = useParams();
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [isLoading, setIsLoading] = useState(true);
  const [playerData, setPlayerData] = useState([]);
  const [playerSeasonsData, setPlayerSeasonsData] = useState([]);
  const [playerTeamData, setPlayerTeamData] = useState([]);
  const [playerAllTimeData, setPlayerAllTimeData] = useState([]);
  const [playerSeasonGames, setPlayerSeasonGames] = useState([]);
  const [playerAwardsData, setPlayerAwardsData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [imgUrl, setImgUrl] = useState(null);

  Axios.defaults.withCredentials = true;

  const fetchPlayerData = async () => {
    const response = await Axios.get(url + "/players/" + id);
    setPlayerData(response.data);
  };

  const fetchPlayerSeasonData = async () => {
    const response = await Axios.get(url + "/playerSeasons/all/" + id);
    setPlayerSeasonsData(response.data);
    fetchPlayerSeasonGamesData(response.data[0].seasonID._id);
    setSelectedSeason(response.data[0].seasonID._id);
  };

  const fetchPlayerAllTimeData = async () => {
    const response = await Axios.get(url + "/playerallTimes/player/" + id);
    setPlayerAllTimeData(response.data);
  };

  const fetchPlayerAwardsData = async () => {
    const response = await Axios.get(url + "/playerAwards/" + id);
    setPlayerAwardsData(response.data);
  };

  const fetchPlayerTeamData = async () => {
    await Axios.get(url + "/playerTeams/player/" + id)
      .then((response) => {
        setPlayerTeamData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchPlayerSeasonGamesData = async (seasonID) => {
    const response = await Axios.get(
      url + "/playerGames/season/" + seasonID + "/" + id
    );
    setPlayerSeasonGames(response.data);
  };

  useEffect(() => {
    playerImage();
    fetchPlayerData();
    fetchPlayerSeasonData();
    fetchPlayerAllTimeData();
    fetchPlayerAwardsData();
    fetchPlayerTeamData();
  }, []);

  const playerImage = async () => {
    await Axios.get(url + "/images/playerID/" + id)
      .then((res) => {
        setImgUrl(res.data ? res.data : "");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
    fetchPlayerSeasonGamesData(event.target.value);
  };

  if (isLoading) {
    return (
      <div className="w-[100%] h-[100vh] flex justify-center items-center">
        <CircularProgress sx={{ color: "white" }} />
      </div>
    );
  } else {
    return (
      <div
        className="w-full min-h-screen flex flex-col items-center h-fit fade-in font-Poppins relative "
        style={{ marginTop: "0 !important" }}
      >
        <div className="w-[100%] sm:h-[350px] lg:h-[450px]  flex justify-center relative">
          <div className="flex w-[100%] h-full  top-0 bgBlack justify-center absolute items-end overflow-hidden">
            <div className="sm:w-[60%] lg:w-[40%] h-[80%] overflow-hidden z-10">
              {imgUrl === "" ? (
                <div className="fade-in relative w-full flex justify-center  items-center h-full">
                  <PersonIcon
                    sx={{ color: "whitesmoke", fontSize: "96px" }}
                    className="sm-shadow scale-[3] mt-24"
                  ></PersonIcon>
                </div>
              ) : (
                <div
                  className="fade-in w-full h-full sm-shadow"
                  style={{
                    backgroundImage: `url(${imgUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "bottom",
                  }}
                />
              )}
            </div>
            <img
              className="absolute sm:h-[70%] lg:h-[150%] opacity-50"
              src={`${process.env.PUBLIC_URL}/assets/img/AW_allblack.png`}
            ></img>
            <button
              onClick={() => {
                onData(true);
                prop(id);
              }}
              className="animate-bounce absolute top-[40%] sm:left-[10%] lg:left-[30%] border-4 rounded-full p-2  text-white transition-all  hover:border-[#bf0202] hover:text-[#ff0000] shadow-lg shadow-black z-10 "
            >
              <RememberMeIcon
                sx={{
                  fontSize: 56,
                }}
              ></RememberMeIcon>
            </button>
          </div>
        </div>
        <div className="bg-black w-[100%] lg:h-[30vh] sm:h-[225px] border-t-8 border-b-8 border-[#bf0202] z-20 p-2 relative sm-shadow">
          <div className="w-[100%] h-[40%]  uppercase text-white sm:text-3xl lg:text-5xl flex items-center flex-col font-extrabold text-center stats-font">
            <p className="w-[100%]">{playerData.lastname}</p>
            <p className="w-fit  border-b-4">{playerData.firstname}</p>
          </div>
          <div className="w-[100%] h-[5%]  uppercase text-white sm:text-base lg:text-base flex items-center flex-col font-extrabold text-center ">
            {playerTeamData.length !== 0 ? (
              <a
                href={`/team/${playerTeamData.teamID?._id}`}
                className="transition-all hover:text-[#bf0202]"
              >
                {playerTeamData.teamID?.name}
              </a>
            ) : (
              <p>No Team</p>
            )}
          </div>
          <div className="w-[100%] h-[55%]  flex justify-center flex-col items-center ">
            <div className="grid grid-cols-5 text-white sm:w-[90%] lg:w-[50%] h-[40%] stats-font">
              <div className=" border-l-4 border-b-2 flex justify-center items-end p-2 font-bold text-xs lg:text-2xl h-[100%]">
                PPG
              </div>
              <div className="border-b-2 flex justify-center items-end p-2 font-bold sm:text-xs lg:text-2xl h-[100%]">
                PTS
              </div>
              <div className="border-b-2 flex justify-center items-end p-2 font-bold sm:text-xs lg:text-2xl h-[100%]">
                FGM
              </div>
              <div className="border-b-2 flex justify-center items-end p-2 font-bold sm:text-xs lg:text-2xl h-[100%]">
                3PM
              </div>

              <div className="border-b-2 border-r-4 flex justify-center items-end p-2 font-bold sm:text-xs lg:text-2xl h-[100%]">
                FT%
              </div>
            </div>
            <div className="grid grid-cols-5 text-white sm:w-[90%] lg:w-[50%] h-[40%]">
              <div className=" border-t-2 border-l-4 flex justify-center items-center font-bold sm:text-2xl lg:text-3xl h-[100%]">
                {playerSeasonsData.length === 0
                  ? "0"
                  : playerSeasonsData[0].ppg.toFixed(1)}
              </div>
              <div className="border-t-2 flex justify-center items-center font-bold sm:text-2xl lg:text-3xl h-[100%]">
                {playerSeasonsData.length === 0
                  ? "0"
                  : playerSeasonsData[0].pts}
              </div>
              <div className="border-t-2 flex justify-center items-center font-bold sm:text-2xl lg:text-3xl h-[100%]">
                {playerSeasonsData.length === 0
                  ? "0"
                  : playerSeasonsData[0].fgm}
              </div>
              <div className="border-t-2 flex justify-center items-center font-bold sm:text-2xl lg:text-3xl h-[100%]">
                {playerSeasonsData.length === 0
                  ? "0"
                  : playerSeasonsData[0].threes}
              </div>
              <div className="border-t-2 border-r-4 flex justify-center items-center font-bold sm:text-2xl lg:text-3xl h-[100%]">
                {playerSeasonsData.length === 0
                  ? 0
                  : playerSeasonsData[0].freeThrowsAttempts > 0
                  ? (
                      (playerSeasonsData[0].freeThrows /
                        playerSeasonsData[0].freeThrowsAttempts) *
                      100
                    ).toFixed(1)
                  : 0}
              </div>
            </div>
          </div>
        </div>

        <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
            Season Stats
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
          </div>
          <div className="flex items-center justify-center mb-8">
            <div className="w-[100%] flex items-center justify-center flex-col">
              <PlayerStats
                season={playerSeasonsData[0]}
                player={playerData}
              ></PlayerStats>
            </div>
          </div>
        </div>
        <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
            Games
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
          </div>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="season-label">Seasons</InputLabel>
            <Select
              labelId="season-label"
              label={"Seasons"}
              onChange={handleSeasonChange}
              value={selectedSeason}
            >
              {playerSeasonsData.map((season, index) => (
                <MenuItem value={season?.seasonID?._id} key={index}>
                  {season?.seasonID?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="w-[100%]">
            {playerSeasonGames.length !== 0 ? (
              <PlayerGamesList games={playerSeasonGames}></PlayerGamesList>
            ) : selectedSeason !== "" ? (
              <div className="font-extrabold text-6xl text-center">
                NO GAMES PLAYED
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        {/* <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
            All Time
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
          </div>
          <div className="flex items-center sm:w-[100%] lg:w-[50%] mb-8">
             <PlayerStats
              season={playerAllTimeData}
              player={playerData}
            ></PlayerStats> 
          </div>
        </div> */}

        <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
            Awards
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
          </div>
          <div className="flex items-center sm:w-[100%] lg:w-[50%] mb-8">
            <PlayerAwards awards={playerAwardsData}></PlayerAwards>
          </div>
        </div>
      </div>
    );
  }
};

export default Player;
