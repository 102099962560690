import Title from "./Title";
import PlayerCard from "./PlayerCard";
import { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../GlobalContext";

function News() {
  const [registrations, setRegistrations] = useState(null);
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;

  const navigate = useNavigate();

  const fetchRegistrations = async () => {
    const response = await Axios.get(url + "/seasons/registrations/season");
    setRegistrations(response.data);
  };

  useEffect(() => {
    fetchRegistrations();
  }, []);

  const handleClick = () => {
    // Replace '/destination' with the path of the page you want to redirect to
    navigate("/registrations");
  };
  return (
    <div className="w-full fade-in h-full flex justify-center flex-col items-center">
      <div
        id="news"
        className="bgRed sm:w-[85%] lg:w-[60%] relative  p-4 border-black border-8 rounded-3xl my-8 sm-shadow"
      >
        <Title word="NEWS" />
      </div>

      <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
        <div className="flex flex-col flex-wrap text-center mb-5 text-white">
          <div className="relative text-center w-fit sm:mb-8 lg:mb-12 text-4xl font-extrabold stats-font p-2">
            Registrations
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
          </div>
          <h1 className="font-extrabold sm:text-2xl lg:text-4xl capitalize">
            {registrations?.name}
          </h1>
        </div>

        {/* <div className=" sm:text-lg lg:text-xl sm:w-[100%] lg:w-[70%] text-center lg:pb-4 text-white">
          <p className="font-extrabold pb-2">**IMPORTANT MESSAGE**</p>
          <p>
            REGISTRATIONS FOR THE {registrations?.name} SEASON ARE NOW IN
            PROGRESS. THE WINTER SESSION WILL START AT THE END OF JANUARY,
            official date coming soon. Note that there are 2 divisions this
            season: Regular (mainly on wednesday) and Advanced (thursday)
            However it's possible that those might be merge if necessary. In
            that case, participants would be informed.
          </p>
          <p className="font-extrabold">THANK YOU!</p>
        </div> */}
        <div>
          <button
            // @ts-ignore
            style={{ "--b": "8px", "--s": "12px" }}
            className="button sm:scale-50 lg:scale-100 font-extrabold"
            onClick={handleClick}
          >
            REGISTER
          </button>
        </div>
      </div>
      {/* <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
        <div className="flex flex-col flex-wrap text-center text-white">
          <h1 className="font-extrabold sm:text-4xl lg:text-6xl">CHAMPIONS</h1>
          <h1 className="font-extrabold sm:text-2xl lg:text-4xl">
            AUTUMN SEASON 2023
          </h1>
        </div>
        <div className=" sm:text-lg lg:text-xl sm:w-[100%] lg:w-[70%] text-center pb-4 text-white">
          <p className="font-extrabold">CONGRATULATIONS</p>
          <p className="font-extrabold pb-2">🎉TO TEAM🎉</p>
        </div>
        <div className="sm:w-[100%] lg:w-[70%] text-center mb-20">
          <h1 className="bg-white rounded-2xl text-black font-bold">
            DIVISION: ADVANCED
          </h1>
          <div className="relative  flex justify-center p-8 border-black">
            <span
              data-content="MC BUCKETS"
              className="absolute fontRed z-20 font-extrabold sm:text-2xl lg:text-3xl text-white"
            >
              MC BUCKETS
            </span>
            <span
              data-content="MC BUCKETS"
              className="customShadow z-10 font-extrabold sm:text-2xl lg:text-3xl"
            >
              MC BUCKETS
            </span>
          </div>

          <img
            src="./assets/img/IMG_3364.jpg"
            className={`rounded-lg ${
              isLargeScreen ? "lg-shadow" : "sm-shadow"
            } `}
          ></img>
        </div>
        <div className=" sm:text-lg lg:text-xl sm:w-[100%] lg:w-[70%] text-center pb-4 text-white">
          <p className="font-extrabold">CONGRATULATIONS</p>
          <p className="font-extrabold pb-2">🎉TO TEAM🎉</p>
        </div>
        <div className="sm:w-[100%] lg:w-[70%] text-center ">
          <h1 className="bg-white rounded-2xl text-black font-bold">
            DIVISION: REGULAR
          </h1>
          <div className="relative  flex justify-center p-8 border-black">
            <span
              data-content="MC BUCKETS"
              className="absolute fontRed z-20 font-extrabold sm:text-2xl lg:text-3xl text-white"
            >
              MC BUCKETS
            </span>
            <span
              data-content="MC BUCKETS"
              className="customShadow z-10 font-extrabold sm:text-2xl lg:text-3xl"
            >
              MC BUCKETS
            </span>
          </div>

          <img
            src="./assets/img/IMG_3472.jpg"
            className={`rounded-lg ${
              isLargeScreen ? "lg-shadow" : "sm-shadow"
            } `}
          ></img>
        </div>
      </div> */}

      <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] overflow-hidden sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 black-bg  border-black border-8">
        <div className="relative text-center w-fit sm:mb-8 lg:mb-12 text-4xl font-extrabold stats-font p-2">
          Introducing!
          <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
        </div>
        <div className="relative text-center w-fit  sm:mb-8 lg:mb-12 text-4xl font-extrabold stats-font p-2">
          The Player Card
        </div>
        <div className="relative flex justify-center items-center w-screen sm:min-h-[800px] lg:h-[1000px] mb-8  ">
          <div className="w-fit h-fit ">
            <PlayerCard example={true}></PlayerCard>
          </div>
        </div>
      </div>
      <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg border-black border-8">
        <div className="text-center text-white font-Poppins">
          <p className="font-extrabold">More than a</p>
          <p className="font-extrabold ">League.</p>
        </div>
      </div>
    </div>
  );
}

export default News;
