import { useState, useEffect, useContext } from "react";
import Title from "../component/Title";
import GlobalContext from "../GlobalContext";
import Axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { FormHelperText, OutlinedInput } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import PersonIcon from "@mui/icons-material/Person";

const dayjs = require("dayjs");

function PlayersAdmin() {
  const [loginStatus, setLoginStatus] = useState(false);
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [divisionsData, setDivisionsData] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [playersData, setPlayersData] = useState([]);
  const [playerData, setPlayerData] = useState(null);

  Axios.defaults.withCredentials = true;

  const fetchDivisions = async () => {
    await Axios.get(url + "/divisions")
      .then((response) => {
        if (response.data.length !== 0) {
          setDivisionsData(response.data);
          setSelectedDivision(response.data[0]._id);
          fetchPlayers(response.data[0]._id);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchPlayers = async (divisionID) => {
    await Axios.get(url + "/players/division/" + divisionID)
      .then((response) => {
        const players = response.data.map((player) => ({
          label: player.firstname + " " + player.lastname,
          value: player._id,
        }));
        setPlayersData(players);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchDivisions();
    Axios.get(url + "/users/login", {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setLoginStatus(response.data.LoggedIn ? true : false);
    });
  }, []);

  const handleDivisionChange = async (e) => {
    setSelectedDivision(e.target.value);
    fetchPlayers(e.target.value);
  };

  const handlePlayerSelection = async (playerID) => {
    await Axios.get(url + "/players/" + playerID)
      .then(async (response) => {
        await Axios.get(url + "/images/playerID/" + playerID)
          .then((res) => {
            setPlayerData({
              ...response.data,
              imgURL: res.data ? res.data : "",
            });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className="min-h-screen">
      {loginStatus && (
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="Players"></Title>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              Edit Player
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
            </div>
            <div className="flex sm:flex-col justify-center items-center">
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="division-label">Division</InputLabel>
                <Select
                  labelId="division-label"
                  label={"Division"}
                  onChange={(e) => handleDivisionChange(e)}
                  value={selectedDivision}
                >
                  {divisionsData.map((division, index) => (
                    <MenuItem value={division._id} key={index}>
                      {division.titleEn}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                id="tags-standard"
                options={playersData}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, value) =>
                  value && handlePlayerSelection(value.value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      width: "200px",
                    }}
                  />
                )}
              />
            </div>
            <div>{playerData && <EditPlayer player={playerData} />}</div>
          </div>
        </div>
      )}
    </div>
  );
}

function EditPlayer({ player }) {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [file, setFile] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [birth, setBirth] = useState(player.birth);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState(player.phone);
  const [emPhone, setEmPhone] = useState(player.emPhone);
  const [errorDate, setErrorDate] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    birth: "",
    gender: "",
    email: "",
    postalCode: "",
    phone: "",
    work: "",
    jersey: "",
    emName: "",
    emRelation: "",
    emPhone: "",
  });
  const [deleteImg, setDeleteImg] = useState(false);

  Axios.defaults.withCredentials = true;

  const { enqueueSnackbar } = useSnackbar();

  const handleResponse = (msg, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  Axios.defaults.withCredentials = true;

  const loadForm = () => {
    setFormData({
      firstname: "",
      lastname: "",
      birth: "",
      gender: "",
      email: "",
      postalCode: "",
      phone: "",
      work: "",
      jersey: "",
      emName: "",
      emRelation: "",
      emPhone: "",
    });
    setTimeout(() => {
      setPhone(player.phone);
      setEmPhone(player.emPhone);
      setBirth(player.birth);
      setFormData({
        firstname: player.firstname,
        lastname: player.lastname,
        birth: player.birth,
        gender: player.gender,
        email: player.email,
        postalCode: player.postalCode,
        phone: player.phone,
        work: player.work,
        jersey: player.jersey,
        emName: player.emName,
        emRelation: player.emRelation,
        emPhone: player.emPhone,
      });
    }, 1500);
  };

  async function postImage({ image }) {
    const formData = new FormData();
    formData.append("image", image);

    const result = await Axios.post(
      url + "/images/playerID/" + player._id,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return result.data;
  }

  const fileSelected = (event) => {
    const file = event.target.files[0];

    setFile(file);
    setSelectedFile(file ? file.name : null);
    setImage(null);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    // Trigger the file input by clicking the button
    document.getElementById("fileInput").click();
  };

  const uploadImage = async () => {
    setLoading(true);
    if (!deleteImg) {
      await postImage({
        image: file,
      });
    }

    setLoading(false);
  };

  Axios.defaults.withCredentials = true;

  const handleEmailChange = async (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);

    handleValidation();

    setTimeout(() => {
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(inputValue)) {
        setError(true);
      } else {
        setError(false);
      }
    }, 1200); // Simulating a 500ms debounce
  };

  const handleValidation = () => {
    setLoading(true);

    // Simulate an asynchronous validation process
    setTimeout(() => {
      setLoading(false);
    }, 1500); // Simulating a 2-second validation delay
  };

  const handleImageClick = () => {
    setImage(null);
    setFile([]);
    setSelectedFile(null);
    setDeleteImg(false);
  };

  useEffect(() => {
    if (birth) {
      setErrorDate(null);
    }
  }, [birth]);

  useEffect(() => {
    setLoadingForm(true);
    loadForm();
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  }, [player]);

  const handlePhoneNumberChange = (event) => {
    // Remove non-numeric characters from input
    const rawInput = event.target.value.replace(/\D/g, "");
    const name = event.target.name;

    // Ensure only the first 10 digits are considered
    const trimmedInput = rawInput.slice(0, 10);

    // Format phone number with spaces after the third and sixth digit
    let formattedNumber = "";
    for (let i = 0; i < trimmedInput.length; i++) {
      if (i === 3 || i === 6) {
        formattedNumber += " ";
      }
      formattedNumber += trimmedInput[i];
    }
    if (name === "phone") {
      setPhone(formattedNumber);
    } else if (name === "emPhone") {
      setEmPhone(formattedNumber);
    }
  };

  const handleSubmit = async (event) => {
    setLoadingForm(true);
    try {
      event.preventDefault();
      // Add your logic here to handle the form data (e.g., send it to a server)
      // Reset the form fields
      if (birth === null) {
        setErrorDate("This field is required");
      } else {
        setErrorDate(null);

        formData.birth = birth.toString();

        for (const key in formData) {
          if (formData.hasOwnProperty(key)) {
            // Check if the property value is a string before applying trim
            if (typeof formData[key] === "string") {
              // Trim the value and update the property
              formData[key] = formData[key].trim();
            }
          }
        }

        updatePlayer(formData);
        setTimeout(() => {
          setLoadingForm(false);
        }, 1000);

        if (deleteImg) {
          await Axios.delete(url + "/images/playerID/" + player._id);
          console.log("delete");
        } else {
          uploadImage();
        }
      }

      handleResponse("Successfully Edited Player.", "success");
    } catch (error) {
      handleResponse("Error Editing Player.", "error");
    }
  };

  const updatePlayer = async (data) => {
    await Axios.patch(url + "/players/" + player._id, data);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Update the corresponding form field in the state
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="w-full h-full">
      {loadingForm ? (
        <div className=" mt-10">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-full h-full">
          <form
            onSubmit={handleSubmit}
            className="flex sm:flex-col lg:flex-row m-1 p-4 w-full gap-8"
          >
            <div className="flex flex-col sm:w-full lg:w-1/2 gap-4 font-Poppins">
              <h1 className="sm:mx-1 m-3 text-lg sm:text-center lg:text-start">
                PLAYER INFORMATION
              </h1>

              <div className="sm:flex lg:hidden w-full h-full  flex-col  gap-4 ">
                <div
                  onDoubleClick={handleImageClick}
                  className="w-[80%] self-center border-4 border-white overflow-hidden bg-white bg-opacity-60 aspect-square "
                >
                  {deleteImg || (player.imgURL === "" && !image) ? (
                    <div className="fade-in relative flex justify-center items-center h-full">
                      <PersonIcon
                        sx={{ color: "black", fontSize: "96px" }}
                        className="sm-shadow scale-150"
                      ></PersonIcon>
                    </div>
                  ) : (
                    <div
                      className="fade-in w-full h-full sm-shadow"
                      style={{
                        backgroundImage: `url(${
                          image ? image : player.imgURL
                        })`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "bottom",
                      }}
                    />
                  )}
                </div>
                <div className="flex justify-around">
                  <div>
                    <Button
                      variant="outlined"
                      onClick={handleButtonClick}
                      type="button"
                      disabled={deleteImg}
                    >
                      Upload Image
                    </Button>

                    <p className="font-bold">
                      {selectedFile
                        ? `Selected File: ${selectedFile}`
                        : "No file selected"}
                    </p>

                    <input
                      onChange={fileSelected}
                      type="file"
                      accept="image/*"
                      id="fileInput"
                      style={{ display: "none" }}
                    />
                  </div>
                  <div>
                    <Button
                      variant={`${deleteImg ? "contained" : "outlined"}`}
                      onClick={(e) => {
                        setImage(null);
                        setFile([]);
                        setSelectedFile(null);
                        setDeleteImg(!deleteImg);
                      }}
                      type="button"
                      color="error"
                    >
                      Delete ?
                    </Button>
                  </div>
                </div>
              </div>
              <TextField
                id="standard-basic"
                label="First Name"
                name="firstname"
                variant="outlined"
                onChange={handleChange}
                value={formData.firstname}
                color="warning"
                focused={formData.firstname.trim() !== player.firstname}
                onDoubleClick={(e) =>
                  setFormData({
                    ...formData,
                    firstname: player.firstname,
                  })
                }
                required
              />
              <TextField
                id="standard-basic"
                label="Last Name"
                name="lastname"
                variant="outlined"
                onChange={handleChange}
                value={formData.lastname}
                color="warning"
                focused={formData.lastname.trim() !== player.lastname}
                onDoubleClick={(e) =>
                  setFormData({
                    ...formData,
                    lastname: player.lastname,
                  })
                }
                required
              />
              <div className="flex gap-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    onChange={(e) => {
                      // @ts-ignore
                      setBirth(e.$d);
                    }}
                    maxDate={dayjs(Date.now())}
                    slotProps={{
                      textField: {
                        required: true,
                        label: "Birthdate",
                        name: "birth",
                        error: errorDate === "",
                        color: "warning",
                        focused: birth !== player.birth,
                        value: dayjs(formData.birth),
                      },
                    }}
                  />
                  <div className="flex h-full justify-center items-center">
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ padding: 1, minWidth: 0 }}
                      onClick={(e) => setBirth(player.birth)}
                    >
                      <CachedIcon fontSize="small"></CachedIcon>
                    </Button>
                  </div>
                </LocalizationProvider>
                {errorDate !== null && (
                  <FormHelperText error>{errorDate}</FormHelperText>
                )}

                <FormControl sx={{ width: "100%" }}>
                  <InputLabel
                    sx={{
                      color: ` ${
                        formData.gender !== player.gender && "#ff9800"
                      }`,
                    }}
                    required
                    id="Gender-label"
                  >
                    Sex At Birth
                  </InputLabel>
                  <Select
                    labelId="Gender-label"
                    label={"Sex At Birth"}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                    name="gender"
                    value={formData.gender}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: `solid 2px ${
                          formData.gender !== player.gender && "#ff9800"
                        }`,
                      },
                    }}
                    onDoubleClick={(e) =>
                      setFormData({
                        ...formData,
                        gender: player.gender,
                      })
                    }
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <FormControl>
                <InputLabel
                  sx={{
                    color: ` ${
                      formData.email.trim() !== player.email && "#ff9800"
                    }`,
                  }}
                  id="email-label"
                  required
                >
                  Email
                </InputLabel>
                <OutlinedInput
                  id="standard-basic"
                  label="Email"
                  type="email"
                  name="email"
                  onChange={(e) => {
                    handleEmailChange(e);
                    handleChange(e);
                  }}
                  value={formData.email}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: `solid 2px ${
                        formData.email !== player.email && "#ff9800"
                      }`,
                    },
                  }}
                  onDoubleClick={(e) =>
                    setFormData({
                      ...formData,
                      email: player.email,
                    })
                  }
                  error={Boolean(error) && email !== ""}
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      {email === "" ? (
                        <div></div>
                      ) : loading ? (
                        <CircularProgress size={20} />
                      ) : error ? (
                        <CloseIcon color="error"></CloseIcon>
                      ) : (
                        <DoneIcon></DoneIcon>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>
              <TextField
                id="standard-basic"
                label="Postal Code"
                variant="outlined"
                name="postalCode"
                onChange={handleChange}
                value={formData.postalCode}
                color="warning"
                focused={formData.postalCode.trim() !== player.postalCode}
                onDoubleClick={(e) =>
                  setFormData({
                    ...formData,
                    postalCode: player.postalCode,
                  })
                }
              />
              <TextField
                id="standard-basic"
                label="Phone Number"
                value={phone}
                name="phone"
                variant="outlined"
                onChange={(e) => {
                  handlePhoneNumberChange(e);
                  handleChange(e);
                }}
                color="warning"
                focused={phone !== player.phone}
                onDoubleClick={(e) => setPhone(player.phone)}
                required
              ></TextField>
              <TextField
                id="standard-basic"
                label="Work Area"
                variant="outlined"
                name="work"
                onChange={handleChange}
                value={formData.work}
                color="warning"
                focused={formData.work.trim() !== player.work}
                onDoubleClick={(e) =>
                  setFormData({
                    ...formData,
                    work: player.work,
                  })
                }
              />
              <FormControl>
                <InputLabel
                  sx={{
                    color: ` ${formData.jersey !== player.jersey && "#ff9800"}`,
                  }}
                  id="jersey-label"
                >
                  Jersey Size *
                </InputLabel>
                <Select
                  labelId="jersey-label"
                  label={"Jersey Size *"}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="jersey"
                  value={formData.jersey}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: `solid 2px ${
                        formData.jersey !== player.jersey && "#ff9800"
                      }`,
                    },
                  }}
                  onDoubleClick={(e) =>
                    setFormData({
                      ...formData,
                      jersey: player.jersey,
                    })
                  }
                  required
                >
                  <MenuItem value={"(S)"}>(S)</MenuItem>
                  <MenuItem value={"(M)"}>(M)</MenuItem>
                  <MenuItem value={"(L)"}>(L)</MenuItem>
                  <MenuItem value={"(XL)"}>(XL)</MenuItem>
                  <MenuItem value={"(2XL)"}>(2XL)</MenuItem>
                  <MenuItem value={"(3XL)"}>(3XL)</MenuItem>
                  <MenuItem value={"(4XL)"}>(4XL)</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex flex-col sm:w-full lg:w-1/2 gap-4 font-Poppins ">
              <h1 className="lg:m-2 sm:hidden lg:block select-none text-transparent text-lg">
                {" "}
                no title
              </h1>
              <div className="sm:hidden lg:flex w-full h-full  flex-col  gap-4 ">
                <div
                  onDoubleClick={handleImageClick}
                  className="w-[80%] self-center border-4 border-white overflow-hidden bg-white bg-opacity-60 aspect-square "
                >
                  {deleteImg || (player.imgURL === "" && !image) ? (
                    <div className="fade-in relative flex justify-center items-center h-full">
                      <PersonIcon
                        sx={{ color: "black", fontSize: "96px" }}
                        className="sm-shadow scale-150"
                      ></PersonIcon>
                    </div>
                  ) : (
                    <div
                      className="fade-in w-full h-full sm-shadow"
                      style={{
                        backgroundImage: `url(${
                          image ? image : player.imgURL
                        })`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "bottom",
                      }}
                    />
                  )}
                </div>
                <div className=" flex justify-around">
                  <div>
                    <Button
                      variant="outlined"
                      onClick={handleButtonClick}
                      type="button"
                      disabled={deleteImg}
                    >
                      Upload Image
                    </Button>

                    <p className="font-bold">
                      {selectedFile
                        ? `Selected File: ${selectedFile}`
                        : "No file selected"}
                    </p>

                    <input
                      onChange={fileSelected}
                      type="file"
                      accept="image/*"
                      id="fileInput"
                      style={{ display: "none" }}
                    />
                  </div>
                  <div>
                    <Button
                      variant={`${deleteImg ? "contained" : "outlined"}`}
                      onClick={(e) => {
                        setImage(null);
                        setFile([]);
                        setSelectedFile(null);
                        setDeleteImg(!deleteImg);
                      }}
                      type="button"
                      color="error"
                    >
                      Delete ?
                    </Button>
                  </div>
                </div>
              </div>

              <h1 className="m-[14px] text-lg">
                MEDICAL/EMERGENCY CONTACT INFORMATION
              </h1>

              <TextField
                id="standard-basic"
                label="Full Name"
                name="emName"
                variant="outlined"
                onChange={handleChange}
                required
                value={formData.emName}
                color="warning"
                focused={formData.emName.trim() !== player.emName}
                onDoubleClick={(e) =>
                  setFormData({
                    ...formData,
                    emName: player.emName,
                  })
                }
              />
              <TextField
                id="standard-basic"
                label="Relationship To Player"
                name="emRelation"
                variant="outlined"
                onChange={handleChange}
                required
                value={formData.emRelation.trim()}
                color="warning"
                focused={formData.emRelation !== player.emRelation}
                onDoubleClick={(e) =>
                  setFormData({
                    ...formData,
                    emRelation: player.emRelation,
                  })
                }
              />
              <TextField
                id="standard-basic"
                label="Phone Number"
                name="emPhone"
                value={emPhone}
                variant="outlined"
                onChange={(e) => {
                  handleChange(e);
                  handlePhoneNumberChange(e);
                }}
                color="warning"
                focused={emPhone !== player.emPhone}
                onDoubleClick={(e) => setEmPhone(player.emPhone)}
                required
              />

              <div className="flex justify-center">
                <Button
                  sx={{
                    width: "80%",
                    fontWeight: 800,
                    border: "solid 4px black",
                    background: "white",
                    color: "black",
                    fontFamily: "Poppins",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                  }}
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
export default PlayersAdmin;
