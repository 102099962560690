// @ts-ignore
import React from "react";
import Title from "./component/Title";
import Axios from "axios";
import { useState, useContext, useEffect } from "react";
import GlobalContext from "./GlobalContext";
import Masonry from "react-masonry-css";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import VanillaTilt from "vanilla-tilt";
import { useSnackbar } from "notistack";
import CheckIcon from "@mui/icons-material/Check";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CircularProgress from "@mui/material/CircularProgress";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";

function Photos() {
  const [loginStatus, setLoginStatus] = useState(false);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [newAlbum, setNewAlbum] = useState("");
  const [images, setImages] = useState([]);
  const [albumsData, setAlbumsData] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [galleryMode, setGalleryMode] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonStates, setButtonStates] = useState(
    Array(images.length).fill(false)
  );
  const [open, setOpen] = useState(false);
  const [imgDescs, setImgDescs] = useState(Array(images.length).fill(""));

  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;

  Axios.defaults.withCredentials = true;

  const { enqueueSnackbar } = useSnackbar();

  const handleResponse = (msg, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  async function postImage({ image, description, albumID }) {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("description", description);
    formData.append("albumID", albumID);

    const result = await Axios.post(url + "/images", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return result.data;
  }

  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    for (let i = 0; i < files?.length; i++) {
      // @ts-ignore
      const result = await postImage({
        image: files[i],
        description,
        albumID: selectedAlbum,
      })
        // @ts-ignore
        .then((response) => {
          handleResponse(
            i + 1 + "/" + files.length + " successfully uploaded.",
            "success"
          );
        })
        .catch((error) => {
          handleResponse(
            i + 1 + "/" + files.length + " error uploading.",
            "error"
          );
          console.error("Error posting image:", error);
        });
    }
    setLoading(false);
  };

  const fileSelected = (event) => {
    const files = event.target.files;
    setFiles(files);
  };
  const fetchImages = async (album) => {
    await Axios.get(url + "/images/albumID/" + album)
      .then((response) => {
        setImages(response.data);
        setButtonStates(Array(response.data.length).fill(false));
        const newImgDesc = [...imgDescs];
        for (let i = 0; i < response.data.length; i++) {
          newImgDesc[i] = response.data[i].description;
        }
        setImgDescs(newImgDesc);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchAlbums = async () => {
    await Axios.get(url + "/albums")
      .then((response) => {
        setAlbumsData(response.data);
        setSelectedAlbum(response.data[0]._id);
        fetchImages(response.data[0]._id);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // const activateTilt = () => {
  //   // @ts-ignore
  //   VanillaTilt.init(document.querySelector("#my-div"), {
  //     max: 25,
  //     speed: 200,
  //     glare: false,
  //     "max-glare": 0,
  //   });
  // };

  useEffect(() => {
    fetchAlbums();

    Axios.get(url + "/users/login", {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      console.log(response.data);
      setLoginStatus(response.data.LoggedIn ? true : false);
    });
  }, []);

  useEffect(() => {
    if (galleryMode) {
      document.body.style.overflow = "hidden";
      // if (
      //   typeof DeviceOrientationEvent !== "undefined" &&
      //   // @ts-ignore
      //   typeof DeviceOrientationEvent.requestPermission === "function"
      // ) {
      //   document.body.style.pointerEvents = "none";
      //   // @ts-ignore
      //   DeviceOrientationEvent.requestPermission()
      //     .then((permissionState) => {
      //       if (permissionState === "granted") {
      //         activateTilt();
      //       } else {
      //         // motion and orientation access is not enabled
      //       }
      //     })
      //     .catch(console.error);
      // } else {
      //   activateTilt();
      // }
    } else {
      document.body.style.overflow = "auto";
    }
  }, [galleryMode]);

  const breakpointColumnsObj = {
    default: 3, // Number of columns by default
    1100: 2, // Number of columns at screen width 1100px and above
    700: 1, // Number of columns at screen width 700px and above
  };

  const closeGallery = () => {
    setGalleryMode(false);
  };

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
    setGalleryMode(true);
  };

  const handleAlbumChange = (event) => {
    setSelectedAlbum(event.target.value);
    fetchImages(event.target.value);
  };

  //Admin

  const handlePostAlbum = () => {
    Axios.post(url + "/albums", {
      name: newAlbum,
    })
      // @ts-ignore
      .then((res) => {
        handleResponse("Successfully Created new Album.", "success");
        fetchAlbums();
      })
      // @ts-ignore
      .catch((res) => {
        handleResponse("Error Creating new Album.", "error");
      });
  };

  const toggleButtonState = (index) => {
    const newButtonStates = [...buttonStates];
    newButtonStates[index] = !newButtonStates[index];
    setButtonStates(newButtonStates);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async (itemToDelete) => {
    // Ensure that there is an item selected to delete
    try {
      // Send a delete request to your API or backend
      await Axios.delete(url + "/images/" + itemToDelete._id)
        // @ts-ignore
        .then((response) => {
          handleResponse("Image Deleted", "success");
          setList((prevArray) => [...prevArray, itemToDelete._id]);
        })
        .catch((error) => {
          console.error("Error deleting img:", error);
          handleResponse("Error Deleting Image", "error");
        });
    } catch (error) {
      console.error("Error while deleting item:", error);
    }
  };

  const handleUpdateDesc = async (index, itemToUpdate) => {
    // Ensure that there is an item selected to delete
    try {
      // Send a delete request to your API or backend
      await Axios.patch(url + "/images/" + itemToUpdate._id, {
        description: images[index].description,
      })
        // @ts-ignore
        .then((response) => {
          handleResponse("Description Updated", "success");
          console.log(images[index].description);
        })
        .catch((error) => {
          console.error("Error Updating Description:", error);
          handleResponse("Error Updating Description", "error");
        });
    } catch (error) {
      console.error("Error while Updating Description:", error);
    }
  };

  const handleDeleteAlbum = async () => {
    // Ensure that there is an item selected to delete
    try {
      // Send a delete request to your API or backend
      await Axios.delete(url + "/albums/" + selectedAlbum)
        // @ts-ignore
        .then((response) => {
          handleResponse("Album Deleted", "success");
          handleClose();
          fetchAlbums();
        })
        .catch((error) => {
          console.error("Error Deleting Album:", error);
          handleResponse("Error Deleting Album", "error");
        });
    } catch (error) {
      console.error("Error while Deleting Album:", error);
    }
  };

  const handleTextField = (index, e) => {
    const updatedImages = [...images]; // Create a copy of the images array
    updatedImages[index].description = e.target.value; // Update the description in the copied array
    setImages(updatedImages); // Update the state with the new array
  };

  return (
    <div className="fade-in">
      {galleryMode && (
        <div className="w-[100vw] h-[100vh] fixed z-40 left-[50%] group translate-x-[-50%] top-[50%] translate-y-[-50%] group fade-in">
          <span className="fixed bg-black bg-opacity-70 w-[100%] h-[100%] left-[50%] group translate-x-[-50%] top-[50%] translate-y-[-50%] blury "></span>
          <span className="absolute right-2 top-2 text-white cursor-pointer z-50 ">
            <CloseIcon fontSize="large" onClick={closeGallery}></CloseIcon>
          </span>
          <div
            className="sm:w-screen lg:w-screen h-full m-auto sm:py-8 lg:py-16 z-40 photo-tilt"
            id="my-div"
            // data-tilt
            // data-tilt-gyroscope
          >
            <div className="absolute tilt-item bottom-[15%] left-[50%] z-10">
              <p
                className={`${
                  images[currentIndex]?.description === "" && "hidden"
                } fade-in sm:py-2 lg:py-4 sm:px-12 lg:px-24  text-center font-[Bungee] text-white bg-black/70 fade-in border-2 border-black`}
              >
                {images[currentIndex]?.description !== ""
                  ? images[currentIndex]?.description
                  : ""}
              </p>
            </div>
            <div
              style={{
                backgroundImage: `url(${images[currentIndex]?.url})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
              className="relative h-full sm:w-full lg:w-[80%] m-auto duration-500 sm-shadow rounded-lg "
            ></div>
            {/* Arrows */}
            <div className="my-16 pb-16 sm:hidden lg:block flex justify-between w-full h-full absolute top-[50%] fade-away delay-150 translate-y-[-50%] left-0 text-2xl text-white">
              <div
                className="absolute h-full w-[100%] left-[-55%] bg-black/30 cursor-pointer rounded-3xl"
                onClick={prevSlide}
              >
                <span className="flex absolute sm:right-[5%] lg:right-[20%] top-[40%]">
                  <NavigateBeforeIcon fontSize="large"></NavigateBeforeIcon>{" "}
                  <p>Previous</p>
                </span>
              </div>
              <div
                className="absolute h-full w-[100%] right-[-55%] bg-black/30 cursor-pointer rounded-3xl "
                onClick={nextSlide}
              >
                <span className="flex absolute sm:left-[5%] lg:left-[20%] top-[40%]">
                  <p>Next</p>{" "}
                  <NavigateNextIcon fontSize="large"></NavigateNextIcon>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {galleryMode && (
        <div className="w-[100vw] h-[90vh] sm:block lg:hidden fixed z-40 mt-[10vh] left-[50%] group translate-x-[-50%] top-[50%] translate-y-[-50%] group fade-in">
          <div className="my-16 pb-16 flex justify-between w-full h-full absolute top-[30%] fade-away delay-150 translate-y-[-50%] left-0 text-2xl text-white">
            <div
              className="absolute h-full w-[100%] left-[-55%] bg-black/30 cursor-pointer rounded-3xl"
              onClick={prevSlide}
            >
              <span className="flex absolute sm:right-[5%]  top-[40%]">
                <NavigateBeforeIcon fontSize="large"></NavigateBeforeIcon>{" "}
                <p>Previous</p>
              </span>
            </div>
            <div
              className="absolute h-full w-[100%] right-[-55%] bg-black/30 cursor-pointer rounded-3xl "
              onClick={nextSlide}
            >
              <span className="flex absolute sm:left-[5%] lg:left-[20%] top-[40%]">
                <p>Next</p>{" "}
                <NavigateNextIcon fontSize="large"></NavigateNextIcon>{" "}
              </span>
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="PHOTOS" />
          </div>
          {loginStatus && (
            <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
              <div className="relative text-center w-fit sm:mb-8 lg:mb-12 text-4xl font-extrabold stats-font p-2">
                Manage Photos
                <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
              </div>
              <div className="flex items-center z-10">
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    value={newAlbum}
                    label="New Album"
                    variant="outlined"
                    onChange={(e) => setNewAlbum(e.target.value)}
                    type="text"
                  ></TextField>
                </FormControl>
                <Fab
                  aria-label="add"
                  color="info"
                  onClick={handlePostAlbum}
                  className="zoom"
                >
                  <AddIcon
                    fontSize="large"
                    sx={{ transition: "100ms" }}
                  ></AddIcon>
                </Fab>
              </div>
              <div className="flex justify-center sm:flex-col ">
                <form
                  onSubmit={submit}
                  className="flex flex-col justify-evenly gap-2"
                >
                  <input
                    onChange={fileSelected}
                    type="file"
                    accept="image/*"
                    multiple
                  ></input>
                  <TextField
                    value={description}
                    label="Description"
                    variant="outlined"
                    onChange={(e) => setDescription(e.target.value)}
                    type="text"
                  ></TextField>
                  <Button
                    component="label"
                    variant="contained"
                    disabled={loading}
                    startIcon={
                      loading ? (
                        <CircularProgress sx={{ color: "white" }} />
                      ) : (
                        <CloudUploadIcon />
                      )
                    }
                  >
                    <button type="submit" className="font-semibold">
                      Upload File(s)
                    </button>
                  </Button>
                </form>
              </div>
            </div>
          )}
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-8 lg:mb-12 text-4xl font-extrabold stats-font p-2">
              Album
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
            </div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Do you really want to delete this album?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  sx={{ fontWeight: 600 }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteAlbum}
                  sx={{ fontWeight: 600 }}
                >
                  Yes, Delete
                </Button>
              </DialogActions>
            </Dialog>
            <div className="flex items-center z-10">
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="album-label">Album</InputLabel>
                <Select
                  labelId="album-label"
                  label={"Album"}
                  onChange={handleAlbumChange}
                  value={selectedAlbum}
                >
                  {albumsData.map((album, index) => (
                    <MenuItem value={album._id} key={index}>
                      {album.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {loginStatus && (
                <Fab
                  aria-label="add"
                  onClick={handleClickOpen}
                  className="zoom"
                >
                  <DeleteIcon
                    fontSize="large"
                    className="text-black hover:text-red-600 "
                    sx={{ transition: "100ms" }}
                  ></DeleteIcon>
                </Fab>
              )}
            </div>

            <div className="relative text-center w-fit sm:my-8 lg:my-12 text-4xl font-extrabold stats-font p-2">
              Gallery
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
            </div>
            <div>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {images?.map(
                  (image, index) =>
                    !list.includes(image._id) && (
                      <div
                        data-content={`${image?.description}`}
                        className={`relative  ${
                          image?.description !== "" ? "photo" : ""
                        }`}
                        key={index}
                      >
                        <div className="w-full h-full absolute ">
                          {loginStatus && (
                            <div className="absolute top-0 right-0 p-2 z-10 transition-all cursor-pointer rounded-lg">
                              {!buttonStates[index] ? (
                                <Fab
                                  aria-label="add"
                                  onClick={() => toggleButtonState(index)}
                                  className="zoom"
                                >
                                  <DeleteIcon
                                    fontSize="large"
                                    className="text-black hover:text-red-600 "
                                    sx={{ transition: "100ms" }}
                                  ></DeleteIcon>
                                </Fab>
                              ) : (
                                <div className="flex gap-2">
                                  <Fab
                                    color="success"
                                    onClick={() => handleDeleteClick(image)}
                                    className="zoom"
                                  >
                                    <CheckIcon fontSize="large" />
                                  </Fab>
                                  <Fab
                                    color="error"
                                    onClick={() => toggleButtonState(index)}
                                    className="zoom"
                                  >
                                    <CloseIcon fontSize="large" />
                                  </Fab>
                                </div>
                              )}
                            </div>
                          )}
                          {loginStatus && (
                            <div className="absolute w-full top-1/2 z-10">
                              <FormControl
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                }}
                                className="gap-2"
                              >
                                <TextField
                                  hiddenLabel
                                  variant="outlined"
                                  placeholder={image?.description}
                                  value={image?.description}
                                  className="rounded-lg bg-black w-1/2"
                                  onChange={(e) => {
                                    handleTextField(index, e);
                                  }}
                                ></TextField>
                                <Fab
                                  color="info"
                                  onClick={() => handleUpdateDesc(index, image)}
                                >
                                  <ModeEditIcon></ModeEditIcon>
                                </Fab>
                              </FormControl>
                            </div>
                          )}
                        </div>

                        <div
                          className="grid-item sm-shadow rounded-lg overflow-hidden flex justify-center"
                          key={image?.name}
                          onClick={() => goToSlide(index)}
                        >
                          <img
                            className="h-auto max-w-full hover:scale-[110%] transition-all duration-500 motion-reduce:transition-none masonry-item  cursor-pointer"
                            src={image?.url}
                            alt=""
                          />
                        </div>
                      </div>
                    )
                )}
              </Masonry>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Photos;
