import { useState, useEffect, useContext } from "react";
import Title from "../component/Title";
import Location from "../component/Location";
import GlobalContext from "../GlobalContext";
import Axios from "axios";
import { useSnackbar } from "notistack";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Select from "@mui/material/Select";

const LocationsAdmin = () => {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [loginStatus, setLoginStatus] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocations] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [embed, setEmbed] = useState("");
  const [address, setAddress] = useState("");
  const [link, setLink] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const handleResponse = (msg, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  Axios.defaults.withCredentials = true;

  const fetchLocations = () => {
    Axios.get(url + "/locations").then((response) => {
      setLocations(response.data);
    });
  };

  const postLocation = async () => {
    await Axios.post(url + "/locations", {
      name: name,
      desc: desc,
      embed: embed,
      address: address,
      link: link,
    })
      .then((response) => {
        fetchLocations();
        handleResponse("Successfully Created Location.", "success");
      })
      .catch((err) => {
        handleResponse("Error Creating Location.", "error");
      });
  };

  const deleteLocation = async () => {
    await Axios.delete(url + "/locations/" + selectedLocation)
      .then((response) => {
        setSelectedLocations("");
        fetchLocations();
        handleResponse("Successfully Deleted Location.", "success");
      })
      .catch((err) => {
        handleResponse("Error Deleting Location.", "error");
      });
  };

  useEffect(() => {
    Axios.get(url + "/users/login", {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setLoginStatus(response.data.LoggedIn ? true : false);
    });
    fetchLocations();
  }, []);

  return (
    <div className="min-h-screen">
      {loginStatus && (
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="Location"></Title>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              Create Location
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div className="flex gap-4 flex-row w-full justify-evenly items-center ">
              <div className="flex w-1/3 flex-col my-4 gap-2">
                <TextField
                  label="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                ></TextField>
                <TextField
                  label="Description"
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                ></TextField>
                <TextField
                  label="Address"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                ></TextField>
                <TextField
                  label="Embed"
                  onChange={(e) => setEmbed(e.target.value)}
                  value={embed}
                ></TextField>
                <TextField
                  label="Link"
                  onChange={(e) => setLink(e.target.value)}
                  value={link}
                ></TextField>
                <Button variant="outlined" onClick={postLocation}>
                  ADD
                </Button>
              </div>
              <div className="flex location  sm:w-[250px] lg:w-[500px] sm:h-[250px] lg:h-[500px] flex-col my-4 gap-2">
                <Location
                  location={{ name, desc, embed, link, address }}
                ></Location>
              </div>
            </div>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              My Locations
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div className="w-full h-full flex justify-center ">
              <div className="sm:max-w-[600px] lg:max-w-[1200px] flex overflow-x-auto gap-10 scrollbar-thin scrollbar-thumb-slate-500">
                {locations.map((location) => (
                  <div className="sm:mx-5 lg:mx-12">
                    <Location location={location}></Location>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              Delete Locations
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div className="w-full h-full flex justify-center ">
              <div className="sm:max-w-[600px] lg:max-w-[1200px] flex overflow-x-auto gap-10 scrollbar-thin scrollbar-thumb-slate-500">
                <div className="flex justify-center items-center">
                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="location-label">Locations</InputLabel>
                    <Select
                      labelId="season-label"
                      label={"Season"}
                      onChange={(e) => setSelectedLocations(e.target.value)}
                      value={selectedLocation}
                    >
                      {locations.map((location, index) => (
                        <MenuItem value={location._id} key={index}>
                          {location.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={deleteLocation}
                    disabled={selectedLocation === ""}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationsAdmin;
