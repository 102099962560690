import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Title from "./component/Title";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import GlobalContext from "./GlobalContext";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TeamGamesList from "./component/TeamGamesList";
import TeamPlayers from "./component/TeamPlayers";

const Team = ({ onData, prop }) => {
  const { id } = useParams();
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [isLoading, setIsLoading] = useState(true);

  const [teamData, setTeamData] = useState(null);
  const [teamSeasonsData, setTeamSeasonsData] = useState([]);
  const [teamPlayersData, setTeamPlayersData] = useState([]);
  const [teamAllTimeData, setTeamAllTimeData] = useState([]);
  const [teamSeasonGamesData, setTeamSeasonGamesData] = useState([]);
  const [teamRankData, setTeamRankData] = useState("");
  const [selectedSeason, setSelectedSeason] = useState("");

  Axios.defaults.withCredentials = true;

  const NumberPosition = ({ rank }) => {
    if (rank.slice(-1) === "1") return <p>st</p>;
    else if (rank.slice(-1) === "2") return <p>nd</p>;
    else if (rank.slice(-1) === "3") return <p>rd</p>;
    else return <p>th</p>;
  };

  const fetchTeamData = async () => {
    await Axios.get(url + "/teams/" + id)
      .then((response) => {
        setTeamData(response.data);
        fetchTeamSeasonsData(response.data._id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchTeamSeasonGamesData = async (teamID, seasonID) => {
    await Axios.get(
      url + "/teamgames/team/" + teamID + "/season/" + seasonID
    ).then((response) => {
      setTeamSeasonGamesData(response.data);
    });
  };

  const fetchTeamSeasonsData = async (teamID) => {
    await Axios.get(url + "/teamSeasons/all/" + teamID)
      .then((response) => {
        setTeamSeasonsData(response.data);
        setSelectedSeason(response.data[0].seasonID._id);

        fetchTeamSeasonGamesData(teamID, response.data[0].seasonID._id);

        const fetchTeamPlayersData = async () => {
          const res = await Axios.get(
            url +
              "/playerTeams/team/" +
              id +
              "/players/season/" +
              response.data[0].seasonID._id
          );
          setTeamPlayersData(res.data);
        };

        fetchTeamPlayersData();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchTeamAllTimeData = async () => {
    await Axios.get(url + "/teamAllTimes/team/" + id)
      .then((response) => {
        setTeamAllTimeData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    await Axios.get(url + "/teamAllTimes/rank")
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (id === response.data[i].teamID._id)
            setTeamRankData((i + 1).toLocaleString());
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchTeamData();
    fetchTeamSeasonsData(id);
    fetchTeamAllTimeData();
    setIsLoading(false);
  }, []);

  const fetchTeamPlayersData = async (seasonID) => {
    const res = await Axios.get(
      url + "/playerTeams/team/" + id + "/players/season/" + seasonID
    );
    setTeamPlayersData(res.data);
  };

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
    fetchTeamSeasonGamesData(event.target.value);
    fetchTeamPlayersData(event.target.value);
  };

  if (isLoading) {
    return (
      <div className="w-[100%] h-[100vh] flex justify-center items-center">
        <CircularProgress sx={{ color: "white" }} />
      </div>
    );
  } else {
    return (
      <div className="w-full min-h-screen flex flex-col items-center h-fit fade-in font-Poppins relative">
        <div className="flex w-[100%] sm:h-[350px] lg:h-[450px] sm-shadow bgBlack justify-center relative items-center overflow-hidden border-b-8 border-[#bf0202]">
          <div className="scale-150 z-20 relative">
            <Title word={teamData?.name}></Title>
            <p className="text-white text-center text-xs">
              {teamData?.divisionID?.titleEn}
            </p>
          </div>
          <div className="absolute pb-8 flex flex-wrap justify-center items-center font-extrabold  text-white bottom-0 left-[50%] translate-x-[-50%] z-20">
            {teamData?.actif ? (
              <p className="p-2 border-green-400 border-2 bg-black rounded-sm">
                ACTIVE
              </p>
            ) : (
              <p className="p-2 bg-black border-2 border-red-600 rounded-sm">
                INACTIVE
              </p>
            )}
          </div>
          <img
            className="absolute sm:h-[150%] opacity-50"
            src={`${process.env.PUBLIC_URL}/assets/img/AW_allblack.png`}
          ></img>
        </div>
        {teamData?.actif ? (
          <div className="bg-black w-[100%] lg:h-[150px] sm:h-[125px] border-b-8 border-[#bf0202] z-20  relative">
            <div className="w-[100%] h-[100%]  flex justify-center flex-col items-center">
              <div className="grid grid-cols-5 text-white sm:w-[90%] lg:w-[70%] xl:w-[60%] h-[40%] stats-font">
                <div className=" border-l-4 border-b-2 flex justify-center items-end p-2 font-bold text-xs lg:text-lg h-[100%] ">
                  GP
                </div>
                <div className="border-b-2 flex justify-center items-end p-2 font-bold sm:text-xs lg:text-lg h-[100%]">
                  PTS
                </div>
                <div className="border-b-2 flex justify-center items-end text-center p-2 font-bold sm:text-xs lg:text-lg h-[100%]">
                  Team Points
                </div>
                <div className="border-b-2 flex justify-center items-end p-2 font-bold sm:text-xs lg:text-lg h-[100%]">
                  Wins
                </div>

                <div className="border-b-2 border-r-4 flex justify-center items-end p-2 font-bold sm:text-xs lg:text-lg h-[100%]">
                  Loses
                </div>
              </div>
              <div className="grid grid-cols-5 text-white sm:w-[90%] lg:w-[70%] xl:w-[60%] h-[40%]">
                <div className=" border-t-2 border-l-4 flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                  {teamSeasonsData.length === 0
                    ? "0"
                    : teamSeasonsData[0].win +
                      teamSeasonsData[0].lose +
                      teamSeasonsData[0].tie}
                </div>
                <div className="border-t-2 flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                  {teamSeasonsData.length === 0 ? "0" : teamSeasonsData[0].pts}
                </div>
                <div className="border-t-2 flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                  {teamSeasonsData.length === 0
                    ? "0"
                    : teamSeasonsData[0].teamPTS}
                </div>
                <div className="border-t-2 flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                  {teamSeasonsData.length === 0 ? "0" : teamSeasonsData[0].win}
                </div>
                <div className="border-t-2 border-r-4 flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                  {teamSeasonsData.length === 0 ? "0" : teamSeasonsData[0].lose}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <div className="flex w-[100%] justify-center ">
            <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
              Season Stats
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
            </div>
          </div>

          <div className="flex items-center sm:w-[100%] md:w-[100%] mb-8">
            <div className="w-[100%]">
              <div className="w-[100%] flex justify-center">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="season-label">Seasons</InputLabel>
                  <Select
                    labelId="season-label"
                    label={"Seasons"}
                    onChange={handleSeasonChange}
                    value={selectedSeason}
                  >
                    {teamSeasonsData.map((season, index) => (
                      <MenuItem value={season?.seasonID._id} key={index}>
                        {season?.seasonID.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className=" w-[100%] p-4 pb-12 h-fit mt-8 rounded-3xl ">
                <div className=" w-[100%] lg:h-[170px] sm:h-[125px] z-20 relative ">
                  <div className="w-[100%] h-[100%]  flex justify-center flex-col items-center">
                    <div className="grid grid-cols-5 sm:w-[90%] lg:w-[70%] xl:w-[60%] h-[40%] stats-font">
                      <div className=" border-l-4 border-b-2 border-white flex justify-center items-end p-2 font-bold text-xs lg:text-lg h-[100%]">
                        GP
                      </div>
                      <div className="border-b-2 border-white flex justify-center items-end p-2 font-bold sm:text-xs lg:text-lg h-[100%]">
                        PTS
                      </div>
                      <div className="border-b-2 border-white flex justify-center items-end text-center pb-2 font-bold sm:text-xs lg:text-lg h-[100%]">
                        Team Points
                      </div>
                      <div className="border-b-2 border-white flex justify-center items-end p-2 font-bold sm:text-xs lg:text-lg h-[100%]">
                        Wins
                      </div>

                      <div className="border-b-2  border-white border-r-4 flex justify-center items-end p-2 font-bold sm:text-xs lg:text-lg h-[100%]">
                        Loses
                      </div>
                    </div>
                    <div className="grid grid-cols-5 sm:w-[90%] lg:w-[70%] xl:w-[60%] h-[40%]">
                      <div className=" border-t-2 border-white border-l-4 flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                        {teamSeasonsData.length === 0
                          ? "0"
                          : teamSeasonsData[0]?.win +
                            teamSeasonsData[0]?.lose +
                            teamSeasonsData[0]?.tie}
                      </div>
                      <div className="border-t-2 border-white flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                        {teamSeasonsData?.length === 0
                          ? "0"
                          : teamSeasonsData[0]?.pts}
                      </div>
                      <div className="border-t-2 border-white flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                        {teamSeasonsData.length === 0
                          ? "0"
                          : teamSeasonsData[0]?.teamPTS}
                      </div>
                      <div className="border-t-2 border-white flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                        {teamSeasonsData.length === 0
                          ? "0"
                          : teamSeasonsData[0]?.win}
                      </div>
                      <div className="border-t-2 border-white border-r-4 flex justify-center items-center font-bold sm:text-lg lg:text-3xl h-[100%]">
                        {teamSeasonsData.length === 0
                          ? "0"
                          : teamSeasonsData[0]?.lose}
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" w-[100%]  z-20 relative mb-8">
                  <div className="flex w-[100%] justify-center ">
                    <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
                      Season Games
                      <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
                    </div>
                  </div>
                  <TeamGamesList games={teamSeasonGamesData} />
                </div>
                <div className=" w-[100%] z-20 relative mb-8">
                  <div className="flex w-[100%] justify-center ">
                    <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
                      Players
                      <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
                    </div>
                  </div>
                  <TeamPlayers
                    playersData={teamPlayersData}
                    onData={onData}
                    prop={prop}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
          <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
            All time
            <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
          </div>
          <div className="flex items-center sm:w-[100%] lg:w-[50%] mb-8">
            <div className="w-[100%] sm:p-4 lg:p-12 aspect-square bgBlack border-8  border-[#bf0202] sm-shadow relative overflow-hidden">
              <div className="w-[100%] h-[100%] flex flex-col items-center">
                <div className="w-[100%] h-[15%] relative text-white flex items-center justify-center sm:text-lg md:text-4xl ">
                  <div className="w-[100%] text-center stats-font z-20">
                    {teamAllTimeData?.teamID?.name}
                  </div>
                  <span className="absolute bottom-0 border-2 border-white rounded-lg w-[100%] z-20"></span>
                </div>
                <div className="w-[100%] h-[85%] sm:mt-4 lg:mt-8 flex flex-col justify-between p-2 z-40 sm:text-[10px] md:text-base">
                  <div className="text-white text-center sm:text-xl md:text-2xl 2xl:text-4xl stats-font flex justify-center">
                    <p>ALL TIME RANK : </p>
                    <p className="2xl:text-[4.5rem] lg:text-[3rem] sm:text-[2.5rem] ml-2 ">
                      {teamRankData}
                    </p>
                    <NumberPosition rank={teamRankData} />
                  </div>
                  <div className="text-white text-center sm:text-xl md:text-2xl 2xl:text-4xl stats-font flex justify-center">
                    <p className="scale-[1.5] sm:mr-2 lg:mr-4">
                      {teamSeasonsData.length}
                    </p>{" "}
                    <p>Seasons Played</p>
                  </div>
                  <div className="text-white text-center sm:text-xl md:text-2xl 2xl:text-4xl stats-font flex justify-center">
                    <p className="scale-[1.5] sm:mr-2 lg:mr-4">
                      {teamAllTimeData.teamPTS}
                    </p>{" "}
                    <p>PTS</p>
                  </div>
                  <div className="text-white text-center sm:text-xl md:text-2xl 2xl:text-4xl stats-font flex justify-center">
                    <p className="scale-[1.5] sm:mr-2 lg:mr-4">
                      {teamAllTimeData.win}
                    </p>{" "}
                    <p>WINS</p>
                  </div>
                  <div className="text-white text-center sm:text-xl md:text-2xl 2xl:text-4xl stats-font flex justify-center">
                    <p className="scale-[1.5] sm:mr-2 lg:mr-4">
                      {teamAllTimeData.lose}
                    </p>{" "}
                    <p>LOSES</p>
                  </div>
                  <div className="text-white text-center sm:text-xl md:text-2xl 2xl:text-4xl stats-font flex justify-center">
                    <p className="scale-[2.5] sm:mr-4 lg:mr-6">
                      {teamAllTimeData.championships}
                    </p>{" "}
                    <p>CHAMPIONSHIPS 🏆</p>
                  </div>
                </div>
              </div>
              <img
                className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] opacity-50 "
                src={`${process.env.PUBLIC_URL}/assets/img/AW_allblack.png`}
              />
            </div>
          </div>
        </div> */}
      </div>
    );
  }
};

export default Team;
