import { useState, useContext } from "react";
import Title from "./component/Title";
import GlobalContext from "./GlobalContext";
import {
  Button,
  CircularProgress,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { render } from "@react-email/render";
import ContactEmail from "./component/emails/contact";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Axios from "axios";
import Confirmation from "./component/Confirmation";

function Contact() {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });
  const [chars, setChars] = useState(0);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Update the corresponding form field in the state
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const handleEmail = async (e) => {
    e.preventDefault();

    setLoading(true);
    const mailOptions = {
      from: "info@afterworkleagues.com",
      to: "info@afterworkleagues.com",
      subject: "CONTACT - " + formData.subject.toUpperCase(),
      html: receiveEmailHtml,
    };
    receiveEmail(mailOptions);
  };

  const receiveEmail = async (mailOptions) => {
    await Axios.post(url + "/emails", { mailOptions }).then((response) => {
      setTimeout(() => {
        setLoading(false);
        setSent(true);
      }, 1500);
    });
  };

  const receiveEmailHtml = render(<ContactEmail formData={formData} />);

  return (
    <div>
      <div className="container">
        <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
          <Title word="Contact" />
        </div>
        {loading ? (
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mt-8 lg:mt-12 text-4xl font-extrabold stats-font p-2">
              Write to us!
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div className="relative text-center w-fit text-xl sm:mb-8 lg:mb-12 stats-font p-2">
              We'll comeback to you shortly
            </div>
            <div className="w-full flex justify-center items-center p-4">
              <CircularProgress sx={{ color: "white" }}></CircularProgress>
            </div>
          </div>
        ) : sent ? (
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mt-8 lg:mt-12 text-4xl font-extrabold stats-font p-2">
              Write to us!
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div className="relative text-center w-fit text-xl sm:mb-8 lg:mb-12 stats-font p-2">
              We'll comeback to you shortly
            </div>
            <Confirmation />
          </div>
        ) : (
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mt-8 lg:mt-12 text-4xl font-extrabold stats-font p-2">
              Write to us!
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <div className="relative text-center w-fit text-xl sm:mb-8 lg:mb-12 stats-font p-2">
              We'll comeback to you shortly
            </div>
            <Box
              component="form"
              onSubmit={handleEmail}
              sx={{
                width: "80%",
                maxWidth: "600px",
                "& .MuiTextField-root": { marginBottom: 1, width: "100%" },
              }}
            >
              <FormControl
                onSubmit={(e) => {
                  handleEmail(formData);
                }}
                className="w-full"
              >
                <TextField
                  label="Name"
                  name="name"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                />
                <TextField
                  label="Phone"
                  name="phone"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required={formData.phone === "" && formData.email === ""}
                />
                <TextField
                  label="Email"
                  name="email"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required={formData.phone === "" && formData.email === ""}
                />
                <TextField
                  autoComplete="off"
                  label="Subject"
                  name="subject"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                />
                <TextField
                  autoComplete="off"
                  id="outlined-multiline-flexible"
                  label="Message"
                  name="message"
                  multiline
                  rows={6}
                  onChange={(e) => {
                    handleChange(e);
                    setChars(e.target.value.length);
                  }}
                  inputProps={{ maxLength: 256 }}
                  required
                />
                <p className="text-end text-gray-300">
                  Max characters : ({256 - chars}/256)
                </p>
                <Button
                  sx={{ width: "fit-content", alignSelf: "center" }}
                  variant="outlined"
                  size="large"
                  type="submit"
                >
                  Send
                </Button>
              </FormControl>
            </Box>
            <div className="relative text-center w-fit sm:my-8 lg:my-12 text-4xl font-extrabold stats-font p-2">
              Or
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white"></span>
            </div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Name</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Position</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Phone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="sm:w-[100px] lg:w-[150px]">
                      <div
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/152548122_10165027922120048_5532932566238864430_n.jpg)`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                        }}
                        className="w-auto aspect-square sm-shadow sm:w-[100px] lg:w-[150px]"
                      ></div>
                    </TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>
                      Donald Maisonneuve
                    </TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Co-Owner</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>
                      514 677-8648
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="sm:w-[100px] lg:w-[150px]">
                      <div
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/255572653_10159432105798609_6138976772273701773_n.jpg)`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                        }}
                        className="w-auto aspect-square sm-shadow sm:w-[100px] lg:w-[150px]"
                      ></div>
                    </TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>
                      Ronald Celestin
                    </TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Co-Owner</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>
                      514 966-8470
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="sm:w-[100px] lg:w-[150px]">
                      <div
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/Shawn.jpg)`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                        }}
                        className="w-auto aspect-square sm-shadow sm:w-[100px] lg:w-[150px]"
                      ></div>
                    </TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>
                      Shawn Maisonneuve
                    </TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Webmaster</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>
                      514 992-1242
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
}

export default Contact;
