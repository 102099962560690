import { useState, useEffect, useContext } from "react";
import Title from "../component/Title";
import GlobalContext from "../GlobalContext";
import Axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function FAQ() {
  const [loginStatus, setLoginStatus] = useState(false);
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;

  Axios.defaults.withCredentials = true;

  useEffect(() => {
    Axios.get(url + "/users/login", {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setLoginStatus(response.data.LoggedIn ? true : false);
    });
  }, []);

  return (
    <div className="min-h-screen">
      {loginStatus && (
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="FAQ"></Title>
          </div>

          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FAQ;
