import React from "react";
import "./tableHover.css";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import Pagination from "@mui/material/Pagination";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ReorderIcon from "@mui/icons-material/Reorder";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import { useEffect, useState, useContext } from "react";
import PlayerProfile from "./PlayerProfile";

import {
  Column,
  Table as ReactTable,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { hover } from "@testing-library/user-event/dist/hover";

type PlayerSeason = {
  playerID: {
    _id: string;
    firstname: string;
    lastname: string;
  };
  ppg: number;
  gamesPlayed: number;
  pts: number;
  threes: number;
  twos: number;
  freeThrows: number;
  freeThrowsAttempts: number;
};

const PlayerStandings = ({ onData, prop, players }) => {
  const data = players;

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const [grid, setGrid] = useState(false);
  const renderGrid = () => {
    setGrid(!grid);
  };

  const columns = React.useMemo<ColumnDef<PlayerSeason>[]>(
    () => [
      {
        header: "Info",
        columns: [
          {
            header: "Rank #",
            id: "rank",
            cell: ({ row }) => {
              return <div className="font-bold text-base">{row.index + 1}</div>;
            },
          },
          {
            header: "Full Name",
            accessorFn: (row) =>
              `${row.playerID.firstname} ${row.playerID.lastname}`,
            cell: (info) => (
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  bgcolor: "white",
                  width: "100%",
                  color: "black",
                  fontWeight: "600",
                  fontFamily:"Poppins",
                  ":hover": { color: "white" },
                }}
                href={`/players/${info.row.original.playerID._id}`}
              >
                {info.getValue()}
              </Button>
            ),
            sortDescFirst: true,
          },
          {
            id: "_id",
            header: "⭐",
            accessorFn: (row) => `${row.playerID._id}`,
            cell: (info) => (
              <button
                onClick={() => {
                  onData(true);
                  prop(info.getValue());
                }} className="hover:text-[#ff0000] transition-all duration-500"
              > 
                <RememberMeIcon />
              </button>
            ),
            enableSorting: false,
            enableColumnFilter: false,
          },
        ],
      },
      {
        header: "Stats",
        columns: [
          {
            header: "GP",
            accessorKey: "gamesPlayed",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "PPG",
            accessorKey: "ppg",
            cell: (info) => Number(info.getValue()).toFixed(1),
            enableColumnFilter: false,
          },
          {
            header: "PTS",
            accessorKey: "pts",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "3PTS",
            accessorKey: "threes",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "2PTS",
            accessorKey: "twos",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "FTM",
            accessorKey: "freeThrows",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "FTA",
            accessorKey: "freeThrowsAttempts",
            cell: (info) => info.getValue(),
            enableColumnFilter: false,
          },
          {
            header: "FT%",
            accessorFn: (row) => `${row.freeThrowsAttempts>0 ? ((row.freeThrows / row.freeThrowsAttempts)*100).toFixed(1) : 0}`,
            cell: (info) => (
              <span>{info.getValue()}%</span>
            ),
            enableColumnFilter: false,
            sortDescFirst: true,
          },
        ],
      },
    ],
    []
  );

  const [num, setNum] = useState(10);

  function LocalTable({
    data,
    columns,
  }: {
    data: PlayerSeason[];
    columns: ColumnDef<PlayerSeason>[];
  }) {
    const table = useReactTable({
      data,
      columns,
      // Pipeline
      state: {
        sorting,
      },
      initialState: {
        pagination: {
          pageIndex: 0,
          pageSize: num,
        },
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    });

    const { pageSize, pageIndex } = table.getState().pagination;
    const { rows } = table.getFilteredRowModel();

    const [ids, setIds] = useState([]);

    const fetchIds = () => {
      const tableBody = document.getElementById("tableBody");
      const rows = tableBody.getElementsByTagName("tr");

      const extractedIds = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const id = row.getAttribute("id");
        extractedIds.push(id);
      }
      setIds(extractedIds);
    };

    useEffect(() => {
      fetchIds();
    }, [pageSize, pageIndex, rows]);

    const totalPages = Math.ceil(
      table.getFilteredRowModel().rows.length / pageSize
    );

    return (
      <Box sx={{ width: "100%" }}>
        <div className="relative pb-4 w-[100%] flex justify-center flex-col items-center">
          <div className="mb-5">
          <Button variant="contained" disabled={!grid} sx={{margin:"4px", bgcolor:"white", color:"black", fontWeight:"600", ":hover":{bgcolor:"#ff0000"}}} onClick={renderGrid} ><ReorderIcon></ReorderIcon></Button>
          <Button variant="contained" disabled={grid} sx={{margin:"4px", bgcolor:"white",color:"black", fontWeight:"600", ":hover":{bgcolor:"#ff0000"}}} onClick={renderGrid}><ViewCompactIcon></ViewCompactIcon></Button>
          
          </div>

          <Pagination
            variant="outlined"
            count={totalPages}
            onChange={(_, value) => {
              table.setPageIndex(value - 1);
            }}
            page={pageIndex + 1}
            size="large"
          ></Pagination>
        </div>

        <TableContainer className="box fade-in">
          <div className="boxbox">
            <Table className="tableHover">
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableCell
                          key={header.id}
                          colSpan={header.colSpan}
                          sx={{
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            borderBottom: `${
                              header.column.id === "ppg" ||
                              header.column.id === "rank"
                                ? "none"
                                : ""
                            }`,
                            backgroundImage: `${
                              header.column.id === "ppg" ||
                              header.column.id === "rank"
                                ? "linear-gradient(to left,  #d30000, #ff0000, #d30000)"
                                : ""
                            }`,
                            backgroundRepeat: "no-repeat",
                          }}
                          className={`${
                            header.column.id === "ppg" ||
                            header.column.id === "rank"
                              ? "border-4 border-white"
                              : ""
                          }`}
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              <div>
                                {header.column.getCanFilter() ? (
                                  <div className="flex justify-center">
                                    <Filter
                                      column={header.column}
                                      table={table}
                                    />
                                  </div>
                                ) : null}
                              </div>
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? "cursor-pointer select-none flex-col flex align-center items-center sortLabel"
                                    : "inline-block flex justify-center ",
                                  onClick:
                                    header.column.getToggleSortingHandler(),
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}{" "}
                                {header.column.getCanSort() &&
                                  header.column.getIsSorted() !== "asc" &&
                                  header.column.getIsSorted() !== "desc" && (
                                    <div className={`inline-block`}>
                                      <ExpandMoreSharpIcon
                                        className={` inline-block sort sortHover`}
                                      />
                                    </div>
                                  )}
                                {(header.column.getIsSorted() === "asc" ||
                                  header.column.getIsSorted() === "desc") && (
                                  <div className={`inline-block`}>
                                    <ExpandMoreSharpIcon
                                      className={` inline-block sort ${
                                        header.column.getIsSorted() === "asc"
                                          ? "sortFlip"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody id="tableBody" sx={{ display: grid ? "none" : "" }}>
                {table.getRowModel().rows.map((row, index) => {
                  return (
                    <TableRow key={row.id} id={row.getValue("_id")}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            sx={{
                              textAlign: "center",
                              fontFamily: "Poppins",
                              padding: "4px",
                              userSelect:"none",
                              fontWeight: `${
                                cell.column.id === "ppg" ? "700" : ""
                              }`,
                              borderBottom: `${
                                (cell.column.id === "ppg" ||
                                  cell.column.id === "rank") &&
                                table.getRowModel().rows.length - 1 === index
                                  ? "solid 4px"
                                  : (cell.column.id === "ppg" ||
                                      cell.column.id === "rank") &&
                                    table.getRowModel().rows.length - 1 !==
                                      index
                                  ? "none"
                                  : ""
                              }`,
                              backgroundImage: `${
                                cell.column.id === "ppg" ||
                                cell.column.id === "rank"
                                  ? "linear-gradient(to left, #d30000, #ff0000, #d30000)"
                                  : ""
                              }`,
                              backgroundRepeat: "no-repeat",
                              "&:hover": {
                                backgroundImage: `${
                                  cell.column.id === "ppg" ||
                                  cell.column.id === "rank"
                                    ? "linear-gradient(to left,  #000, #000)"
                                    : ""
                                }`,
                              },
                            }}
                            className={`${
                              cell.column.id === "ppg" ||
                              cell.column.id === "rank"
                                ? "border-x-4 border-white"
                                : ""
                            } `}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
        {!sorting.length ? (
          <div>
            {pageIndex === 0 &&
            ids.length !== 0 &&
            ids[0] === data[0].playerID._id ? (
              <div
                className={`grid sm:grid-cols-1 sm:gap-2 lg:grid-cols-3 lg:gap-4 md:mb-4 ${
                  grid ? "" : "hidden"
                }`}
              >
                <div className="relative flex justify-center items-center border-black stats-font">
                  <span
                    data-content={`#1`}
                    className="absolute fontRed z-20 font-extrabold sm:text-8xl lg:text-9xl text-white"
                  >
                    {`#1`}
                  </span>
                  <span
                    data-content={`#1`}
                    className="customShadow z-10 font-extrabold sm:text-8xl lg:text-9xl"
                  >
                    {`#1`}
                  </span>
                </div>
                <div className="flex justify-center">{data.map((player, index) => {
                  if (ids[0] === player.playerID._id.toString()) {
                    return (
                      <PlayerProfile
                        key={0}
                        id={data[index].playerID._id}
                        rank={"#" + (index + 1)}
                      />
                    );
                  }
                })}</div>
                
                <div className="relative flex justify-center items-center border-black stats-font">
                  <span
                    data-content={`#1`}
                    className="absolute fontRed z-20 font-extrabold sm:text-8xl lg:text-9xl text-white"
                  >
                    {`#1`}
                  </span>
                  <span
                    data-content={`#1`}
                    className="customShadow z-10 font-extrabold sm:text-8xl lg:text-9xl"
                  >
                    {`#1`}
                  </span>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div
              className={`grid sm:grid-cols-2 md:grid-cols-3 sm:gap-2 xl:grid-cols-4 lg:gap-4 ${
                grid ? "" : "hidden"
              }`}
            >
              {ids.slice(1).map((id, index) =>
                data.map((player, i) => {
                  if (id === player.playerID._id.toString()) {
                    return (
                      <PlayerProfile
                        key={index + 1}
                        id={id}
                        rank={"#" + (i + 1)}
                      />
                    );
                  }
                })
              )}
            </div>
          </div>
        ) : (
          <div
            className={`grid sm:grid-cols-2 sm:gap-2 lg:grid-cols-4 lg:gap-4 ${
              grid ? "" : "hidden"
            }`}
          >
            {ids.map((id, index) =>
              data.map((player, i) => {
                if (id === player.playerID._id.toString()) {
                  return (
                    <PlayerProfile
                      key={index + 1}
                      id={id}
                      rank={"#" + (i + 1)}
                    />
                  );
                }
              })
            )}
          </div>
        )}

        <div className="relative  p-4 mt-6 w-[100%] flex justify-center">
          <div className="absolute page-size  ">
            <Select
              name="page-size"
              id="page-size"
              value={pageSize}
              onChange={(e) => {
                const size = e.target.value ? Number(e.target.value) : 10;
                setNum(size);
                table.setPageSize(size);
              }}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </div>

          <Pagination
            variant="outlined"
            count={totalPages}
            onChange={(_, value) => {
              table.setPageIndex(value - 1);
            }}
            page={pageIndex + 1}
            size="large"
          ></Pagination>
        </div>
      </Box>
    );
  }

  return (
    <>
      <LocalTable {...{ data, columns }} />
      <hr />
    </>
  );
};

export default PlayerStandings;

function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: ReactTable<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <TextField
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder={`Min`}
        size="small"
      />
      <TextField
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        size="small"
      />
    </div>
  ) : (
    <TextField
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={"Search"}
      sx={{fontFamily:"Poppins"}}
      size="small" inputProps={{style: {fontFamily:"Poppins"}}}
    />
  );
}
