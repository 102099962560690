import React from "react";
import Title from "./component/Title";
import PlayersStandings from "./component/PlayersStandings";
import TeamsStandings from "./component/TeamsStandings";
import { useState, useEffect, useContext } from "react";
import GlobalContext from "./GlobalContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";

function Standings({ onData, prop }) {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [isLoading, setIsLoading] = useState(true);
  const [playersData, setPlayersData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [seasonsData, setSeasonsData] = useState([]);
  const [divisionsData, setDivisionsData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");
  const [selectedStandings, setSelectedStandings] = useState("teams");

  Axios.defaults.withCredentials = true;

  const fetchDivisionsData = async (seasonID) => {
    if (seasonID === "All Time") {
      await Axios.get(url + "/divisions")
        .then((response) => {
          setDivisionsData(response.data);
          if (response.data.length !== 0) {
            fetchPlayersData(seasonID, response.data[0]._id);
            fetchTeamsData(seasonID, response.data[0]._id);
            setSelectedDivision(response.data[0]._id);
          } else setSelectedDivision("");
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching data:", error);
        });
    } else {
      await Axios.get(url + "/seasonDivisions/" + seasonID)
        .then((response) => {
          setDivisionsData(response.data);
          if (response.data.length !== 0) {
            setSelectedDivision(response.data[0].divisionID._id);
            fetchPlayersData(seasonID, response.data[0].divisionID._id);
            fetchTeamsData(seasonID, response.data[0].divisionID._id);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching data:", error);
        });
    }
  };

  const fetchPlayersData = async (seasonID, divisionID) => {
    if (seasonID === "All Time") {
      await Axios.get(url + "/playeralltimes/standings/division/" + divisionID)
        .then((res) => {
          setPlayersData(res.data);
        })
        .catch((error) => {
          setPlayersData([]);
          console.error("Error fetching data:", error);
        });
    } else {
      await Axios.get(
        url +
          "/playerSeasons/standings/season/" +
          seasonID +
          "/division/" +
          divisionID
      )
        .then((res) => {
          setPlayersData(res.data);
        })
        .catch((error) => {
          setPlayersData([]);
          console.error("Error fetching data:", error);
        });
    }
  };

  const fetchTeamsData = async (seasonID, divisionID) => {
    if (seasonID === "All Time") {
      await Axios.get(url + "/teamalltimes/standings/division/" + divisionID)
        .then((res) => {
          setTeamsData(res.data);
        })
        .catch((error) => {
          setTeamsData([]);
          console.error("Error fetching data:", error);
        });
    } else {
      await Axios.get(
        url +
          "/teamSeasons/standings/season/" +
          seasonID +
          "/division/" +
          divisionID
      )
        .then((res) => {
          setTeamsData(res.data);
        })
        .catch((error) => {
          setTeamsData([]);
          console.error("Error fetching data:", error);
        });
    }
  };

  const fetchSeasonsData = async () => {
    await Axios.get(url + "/seasons")
      .then((response) => {
        setSeasonsData(response.data);
        if (response.data.length !== 0) {
          setSelectedSeason(response.data[0]._id);
          fetchDivisionsData(response.data[0]._id);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchSeasonsData();
  }, []);

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
    fetchDivisionsData(event.target.value);
  };

  const handleDivisionChange = (event) => {
    setSelectedDivision(event.target.value);
    fetchPlayersData(selectedSeason, event.target.value);
    fetchTeamsData(selectedSeason, event.target.value);
  };

  const handleStandingChange = (event) => {
    setSelectedStandings(event.target.value);
  };
  if (isLoading) {
    return (
      <div className="w-[100%] h-[100vh] flex justify-center items-center">
        <CircularProgress sx={{ color: "white" }} />
      </div>
    );
  } else {
    return (
      <div>
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="STANDINGS" />
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="flex sm:flex-wrap sm:justify-center ">
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="standings-label">Standings</InputLabel>
                <Select
                  labelId="standings-label"
                  label={"Standings"}
                  onChange={handleStandingChange}
                  value={selectedStandings}
                >
                  <MenuItem value={"teams"}>Teams</MenuItem>
                  <MenuItem value={"players"}>Statistic Leaders</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="season-label">Seasons</InputLabel>
                <Select
                  labelId="season-label"
                  label={"Seasons"}
                  onChange={handleSeasonChange}
                  value={selectedSeason}
                >
                  <MenuItem
                    value={"All Time"}
                    sx={{ fontWeight: "600", fontFamily: "Poppins" }}
                  >
                    All Time
                  </MenuItem>
                  {seasonsData.map((season, index) => (
                    <MenuItem value={season._id} key={index + 1}>
                      {season.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="division-label">Divisions</InputLabel>
                {selectedSeason === "All Time" ? (
                  <Select
                    labelId="division-label"
                    label={"Division"}
                    onChange={handleDivisionChange}
                    value={selectedDivision}
                  >
                    {divisionsData.map((division, index) => (
                      <MenuItem value={division._id} key={index}>
                        {division?.titleEn}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Select
                    labelId="division-label"
                    label={"Division"}
                    onChange={handleDivisionChange}
                    value={selectedDivision}
                  >
                    {divisionsData.map((division, index) => (
                      <MenuItem value={division.divisionID?._id} key={index}>
                        {division.divisionID?.titleEn}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </div>
            {selectedStandings === "players" && (
              <div className="w-[100%] flex flex-col justify-center items-center">
                <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2  border-black">
                  Players
                  <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
                </div>
                <PlayersStandings
                  onData={onData}
                  prop={prop}
                  players={playersData}
                />
              </div>
            )}
            {selectedStandings === "teams" && (
              <div className="w-[100%] flex flex-col justify-center items-center">
                <div className="relative text-center w-fit sm:m-8 lg:m-12 text-4xl font-extrabold stats-font p-2">
                  Teams
                  <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
                </div>
                <TeamsStandings teams={teamsData}></TeamsStandings>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Standings;
