import React from "react";
import Title from "./component/Title";
import { useState, useEffect, useContext } from "react";
import GlobalContext from "./GlobalContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";

function Teams() {
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [isLoading, setIsLoading] = useState(true);
  const [seasonsData, setSeasonsData] = useState([]);
  const [divisionsData, setDivisionsData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");

  Axios.defaults.withCredentials = true;

  const fetchDivisionsData = async (seasonID) => {
    await Axios.get(url + "/seasonDivisions/" + seasonID)
      .then((response) => {
        setDivisionsData(response.data);
        if (response.data.length) {
          setSelectedDivision(response.data[0].divisionID._id);
          fetchTeamsData(seasonID, response.data[0].divisionID._id);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchTeamsData = async (seasonID, divisionID) => {
    await Axios.get(
      url + "/teamSeasons/season/" + seasonID + "/division/" + divisionID
    )
      .then((response) => {
        setTeamsData(response.data);
      })
      .catch((error) => {
        setTeamsData([]);
        console.error("Error fetching data:", error);
      });
  };
  const fetchSeasonsData = async () => {
    await Axios.get(url + "/seasons")
      .then((response) => {
        setSeasonsData(response.data);
        setSelectedSeason(response.data[0]._id);
        fetchDivisionsData(response.data[0]._id);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchSeasonsData();
  }, []);

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
    fetchDivisionsData(event.target.value);
  };

  const handleDivisionChange = (event) => {
    setSelectedDivision(event.target.value);
    fetchTeamsData(selectedSeason, event.target.value);
  };

  if (isLoading) {
    return (
      <div className="w-[100%] h-[100vh] flex justify-center items-center">
        <CircularProgress sx={{ color: "white" }} />
      </div>
    );
  } else {
    return (
      <div className="fade-in">
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="TEAMS" />
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="flex flex-wrap justify-center items-center mb-8">
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="season-label">Seasons</InputLabel>
                <Select
                  labelId="season-label"
                  label={"Seasons"}
                  onChange={handleSeasonChange}
                  value={selectedSeason}
                >
                  {seasonsData.map((season, index) => (
                    <MenuItem value={season._id} key={index}>
                      {season.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="division-label">Divisions</InputLabel>
                <Select
                  labelId="division-label"
                  label={"Division"}
                  onChange={handleDivisionChange}
                  value={selectedDivision}
                >
                  {divisionsData.map((division, index) => (
                    <MenuItem value={division.divisionID._id} key={index}>
                      {division.divisionID.titleEn}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="p-4 pt-6 rounded-3xl grid sm:grid-cols-1 lg:grid-cols-2 gap-8">
              {teamsData.map((team, index) => (
                <a
                  href={`/team/${team.teamID._id}`}
                  className="flex justify-center items-center"
                  key={index}
                >
                  <Title word={team.teamID.name}></Title>
                </a>
              ))}
              {teamsData.length === 0 && <Title word={"No Team"}></Title>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Teams;
