import { useState, useEffect, useContext } from "react";
import Title from "../component/Title";
import GlobalContext from "../GlobalContext";
import Axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Fab from "@mui/material/Fab";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";

function TeamsAdmin() {
  const [loginStatus, setLoginStatus] = useState(false);
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [seasonsData, setSeasonData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const [teamsData, setTeamsData] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [newTeam, setNewTeam] = useState("");
  const [divisionsData, setDivisionsData] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamSeasons, setTeamSeasons] = useState([]);
  const [selectedTeamSeason, setSelectedTeamSeason] = useState("");
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [playersList, setPlayersList] = useState([]);
  const [allPlayersList, setAllPlayersList] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  Axios.defaults.withCredentials = true;

  const { enqueueSnackbar } = useSnackbar();

  const handleResponse = (msg, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  const fetchDivisions = async () => {
    await Axios.get(url + "/divisions")
      .then((response) => {
        setDivisionsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchSeasons = async () => {
    await Axios.get(url + "/seasons")
      .then(async (response) => {
        setSeasonData(response.data);
        setSelectedSeason("0");

        await Axios.get(url + "/teams").then((response) => {
          const teams = response.data.map((team) => ({
            label: team.name + " (" + team.divisionID.titleEn + ")",
            value: team._id,
          }));

          setTeamsData(teams);
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchSeasons();
    fetchDivisions();

    Axios.get(url + "/users/login", {
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
    }).then((response) => {
      setLoginStatus(response.data.LoggedIn ? true : false);
    });
  }, []);

  const handleSeasonChange = async (value) => {
    setSelectedSeason(value);
    if (value === "0") {
      await Axios.get(url + "/teams").then((response) => {
        const teams = response.data.map((team) => ({
          label: team.name + " (" + team.divisionID.titleEn + ")",
          value: team._id,
        }));

        setTeamsData(teams);
      });
    } else {
      await Axios.get(url + "/teamSeasons/all/season/" + value).then(
        (response) => {
          const teams = response.data.map((team) => ({
            label:
              team.teamID.name + " (" + team.teamID.divisionID.titleEn + ")",
            value: team.teamID._id,
          }));

          setTeamsData(teams);
        }
      );
    }
  };

  const loadTeam = (team) => {
    setTeamName(team.name);
  };

  const handleTeamSelection = async (value) => {
    let teamTemp = [];
    await Axios.get(url + "/teams/" + value).then((response) => {
      setSelectedTeam(response.data);
      loadTeam(response.data);
      teamTemp = response.data;
    });

    await Axios.get(url + "/teamSeasons/all/" + value).then((response) => {
      const teamSeasonsTemp = response.data.map((teamSeason) => ({
        label: teamSeason.seasonID.name,
        value: teamSeason.seasonID._id,
      }));
      setSelectedTeamSeason(
        response.data.length !== 0 ? teamSeasonsTemp[0].value : ""
      );
      setTeamSeasons(teamSeasonsTemp);
      if (teamSeasonsTemp.length !== 0) {
        setSelectedTeamSeason(teamSeasonsTemp[0].value);
        fetchTeamSeasonRoster(teamSeasonsTemp[0].value, teamTemp);
      }
    });
  };
  const fetchTeamSeasonRoster = async (seasonID, team) => {
    await Axios.get(
      url + "/playerTeams/team/" + team._id + "/players/season/" + seasonID
    ).then(async (response) => {
      const players = response.data
        .filter((player) => {
          return (
            player.actif &&
            player.playerID.divisionID._id === team.divisionID._id
          );
        })
        .map((player) => ({
          _id: player.playerID._id,
          firstname: player.playerID.firstname,
          lastname: player.playerID.lastname,
          gender: player.playerID.gender,
          img: "",
          checked: false,
        }));
      setTeamPlayers(players);

      await Axios.get(url + "/players").then((response) => {
        const allPlayers = response.data
          .filter((player) => {
            return player.divisionID === team.divisionID._id;
          })
          .map((player) => ({
            _id: player._id,
            firstname: player.firstname,
            lastname: player.lastname,
            gender: player.gender,
            img: "",
            checked: false,
          }));
        const teamPlayersIDs = players.map((player) => player._id);
        const filtered = allPlayers.filter(
          (player) => !teamPlayersIDs.includes(player._id)
        );
        const list = filtered.map((player) => ({
          label: player.firstname + " " + player.lastname,
          value: player._id,
        }));
        setAllPlayersList(list);
        setFilteredOptions(filtered.slice(0, 12));
        setPlayersList(filtered);
      });
    });
  };
  const handleTeamSeasonChange = async (value) => {
    setSelectedTeamSeason(value);
    fetchTeamSeasonRoster(value, selectedTeam);
  };
  const handlePostTeam = () => {
    Axios.post(url + "/teams", {
      name: newTeam,
      divisionID: selectedDivision,
    })
      .then((res) => {
        handleResponse("Successfully Created New Team.", "success");
        fetchSeasons();
      })
      .catch((res) => {
        handleResponse("Error Creating New Team.", "error");
      });
  };
  const handleCheckTeamPlayers = (index) => {
    const updatedPlayers = [...teamPlayers];
    updatedPlayers[index].checked = !updatedPlayers[index].checked;
    setTeamPlayers(updatedPlayers);
  };
  const handleCheckPlayersList = (_id) => {
    const updatedPlayers = [...playersList];
    updatedPlayers[playersList.findIndex((item) => item._id === _id)].checked =
      !updatedPlayers[playersList.findIndex((item) => item._id === _id)]
        .checked;
    setPlayersList(updatedPlayers);
  };
  const handleAutocompleteChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    // Filter options based on the input value and set them in filteredOptions
    const filtered = allPlayersList.filter((option) =>
      option.label.toLowerCase().includes(newInputValue.toLowerCase())
    );
    const ids = filtered.map((player) => player.value);
    const fil = playersList.filter((player) => ids.includes(player._id));
    setFilteredOptions(fil.slice(0, 12));
  };
  const handleSubmit = () => {
    // Patch Team
    handlePatchTeam();
    // Add Player
    //Verify if has PlayerSeason
    handlePlayerSeason();
    //Verify if had TeamPlayer
    handleTeamPlayer();
  };
  const handleTeamPlayer = async () => {
    await playersList.map((player, index) => {
      if (player.checked) {
        Axios.patch(url + "/playerTeams/newTeam", {
          playerID: player._id,
          teamID: selectedTeam._id,
          seasonID: selectedTeamSeason,
        })
          .then((res) => {})
          .catch((res) => {});
      }

      fetchTeamSeasonRoster(selectedTeamSeason, selectedTeam);
    });

    await teamPlayers.map((player, index) => {
      if (player.checked) {
        Axios.patch(url + "/playerTeams/removeTeam", {
          playerID: player._id,
          teamID: selectedTeam._id,
          seasonID: selectedTeamSeason,
        })
          .then((res) => {})
          .catch((res) => {});
      }
      fetchTeamSeasonRoster(selectedTeamSeason, selectedTeam);
    });
  };

  const handlePlayerSeason = () => {
    playersList.map((player, index) => {
      if (player.checked) {
        Axios.post(
          url +
            "/playerSeasons/player/" +
            player._id +
            "/season/" +
            selectedTeamSeason
        )
          .then((res) => {})
          .catch((res) => {});
      }
    });
  };
  const handlePatchTeam = async () => {
    await Axios.patch(url + "/teams/" + selectedTeam._id, {
      name: teamName,
    })
      .then(async (res) => {
        handleResponse("Successfully Updated Team.", "success");
      })
      .catch((res) => {
        handleResponse("Error Updating Team.", "error");
      });
  };
  return (
    <div className="min-h-screen">
      {loginStatus && (
        <div className="container">
          <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
            <Title word="Teams"></Title>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:mb-4 lg:mb-8 text-4xl font-extrabold stats-font p-2">
              Create a Team
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
            </div>
            <div className="flex flex-col items-center">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel id="division-label">Division</InputLabel>
                <Select
                  labelId="division-label"
                  label={"Division"}
                  onChange={(e) => setSelectedDivision(e.target.value)}
                  value={selectedDivision}
                >
                  {divisionsData.map((division, index) => (
                    <MenuItem value={division._id} key={index}>
                      {division.titleEn}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="flex items-center">
                <FormControl sx={{ m: 1 }}>
                  <TextField
                    value={newTeam}
                    label="New Team"
                    variant="outlined"
                    onChange={(e) => setNewTeam(e.target.value)}
                    type="text"
                  ></TextField>
                </FormControl>
                <Fab
                  aria-label="add"
                  color="info"
                  onClick={handlePostTeam}
                  className="zoom z-0"
                  disabled={selectedDivision == "" || newTeam == ""}
                  sx={{ zIndex: 0 }}
                >
                  <AddIcon
                    fontSize="large"
                    sx={{ transition: "100ms" }}
                  ></AddIcon>
                </Fab>
              </div>
            </div>
          </div>
          <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
            <div className="relative text-center w-fit sm:my-4 lg:my-8 text-4xl font-extrabold stats-font p-2">
              Choose a team
              <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-white "></span>
            </div>
            <div className="flex justify-center items-center flex-col">
              <FormControl sx={{ m: 1, width: 200 }}>
                <InputLabel id="season-label">Season</InputLabel>
                <Select
                  labelId="season-label"
                  label={"Season"}
                  onChange={(e) => handleSeasonChange(e.target.value)}
                  value={selectedSeason}
                >
                  <MenuItem value={"0"} key={0}>
                    All Teams
                  </MenuItem>
                  {seasonsData.map((season, index) => (
                    <MenuItem value={season._id} key={index + 1}>
                      {season.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="flex justify-center items-center flex-col ">
                <h2 className="font-medium pb-2">Select a Team</h2>
                <Autocomplete
                  id="tags-standard"
                  options={teamsData}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(event, value) =>
                    value && handleTeamSelection(value.value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        width: "200px",
                      }}
                    />
                  )}
                />
              </div>
              {selectedTeam && (
                <div className="w-full flex justify-center items-center flex-col">
                  <div className="relative text-center w-fit sm:my-4 lg:my-8 text-4xl font-extrabold stats-font p-2">
                    Team
                    <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-black "></span>
                  </div>
                  <div className="fade-in flex items-center flex-col gap-6">
                    <Title word={selectedTeam.name}></Title>
                    <FormControl sx={{ marginTop: "24px" }}>
                      <p className="text-center">Team Name</p>
                      <TextField
                        value={teamName}
                        variant="outlined"
                        onChange={(e) => setTeamName(e.target.value)}
                        type="text"
                      ></TextField>
                    </FormControl>
                    <div className="relative text-center w-fit sm:my-4 lg:my-8 text-4xl font-extrabold stats-font p-2">
                      roster
                      <span className="absolute w-[100%] h-1 bottom-0 left-[-50%] translate-x-[50%] rounded-full bg-black "></span>
                    </div>
                    {teamSeasons?.length !== 0 ? (
                      <h2 className="font-medium pb-2">Select a Team Season</h2>
                    ) : (
                      <h2 className="font-medium pb-2">No Team Season</h2>
                    )}
                    {selectedTeamSeason.length !== 0 && (
                      <FormControl>
                        <InputLabel id="teamSeason-label">
                          Team Season
                        </InputLabel>
                        <Select
                          labelId="teamSeason-label"
                          label={"Team Season"}
                          value={selectedTeamSeason}
                          onChange={(e) =>
                            handleTeamSeasonChange(e.target.value)
                          }
                        >
                          {teamSeasons.map((season, index) => (
                            <MenuItem value={season.value} key={index}>
                              {season.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                </div>
              )}
            </div>
            {teamSeasons?.length !== 0 && (
              <div className="flex flex-col items-center">
                <p className=" mt-8 text-2xl font-bold">Team Roster</p>
                <div className="m-4 min-w-[500px] w-auto h-auto fade-in bgRed border-8 border-black rounded-lg grid grid-cols-3 gap-4 p-6">
                  {teamPlayers.map((player, index) => (
                    <div
                      className={`w-80 h-36 checkable-div hover:scale-105 transition-all cursor-pointer sm-shadow rounded-lg border-8 ${
                        player.checked ? " border-white" : " border-transparent"
                      }`}
                      onClick={() => handleCheckTeamPlayers(index)}
                      key={index}
                    >
                      <RosterPlayer player={player}></RosterPlayer>
                    </div>
                  ))}
                </div>
                <p className=" mt-8 text-2xl font-bold">Select Players</p>
                <Autocomplete
                  id="tags-standard"
                  options={allPlayersList}
                  getOptionLabel={(option) => option.label}
                  onInputChange={handleAutocompleteChange}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        width: "200px",
                      }}
                    />
                  )}
                />
                <div className="border-8 m-4 flex border-black w-[500px] min-h-[200px] h-auto p-4">
                  <ul className="w-1/2 min-h-full border-r-4 border-black text-center">
                    <p className="font-extrabold text-center">Add List</p>
                    {playersList.map((player, index) => (
                      <li className="font-light" key={index}>
                        {player.checked &&
                          player.firstname + " " + player.lastname}
                      </li>
                    ))}
                  </ul>
                  <ul className="w-1/2 min-h-full border-l-4 border-black text-center">
                    <p className="font-extrabold text-center">Remove List</p>
                    {teamPlayers.map((player, index) => (
                      <li className="font-light" key={index}>
                        {player.checked &&
                          player.firstname + " " + player.lastname}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="bgBlack border-8 border-black rounded-lg fade-in flex flex-col items-center">
                  <div className="text-white text-2xl font-bold pt-8">
                    Division {selectedTeam.divisionID.titleEn}
                  </div>
                  <div className="m-4 w-auto h-auto grid grid-cols-3 gap-4 p-6">
                    {filteredOptions.map((player, index) => (
                      <div
                        key={player._id}
                        className={`w-80 h-36 checkable-div hover:scale-105 transition-all cursor-pointer sm-shadow rounded-lg border-8 ${
                          player.checked
                            ? " border-white"
                            : " border-transparent"
                        }`}
                        onClick={() => handleCheckPlayersList(player._id)}
                      >
                        <RosterPlayer player={player}></RosterPlayer>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {selectedTeam && (
              <div className="flex justify-center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  aria-label="Submit"
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
const RosterPlayer = ({ player }) => {
  return (
    <div className="w-full h-full bgBlack  flex rounded-md">
      <div className="w-1/2 h-full  relative overflow-hidden">
        <img
          src={`${process.env.PUBLIC_URL}/assets/img/2544.png`}
          className="w-full absolute bottom-0 select-none sm-shadow"
        ></img>
      </div>
      <div className="w-1/2 h-full flex flex-col  justify-center items-center select-none text-lg text-white">
        <p>{player.lastname}</p>
        <p>{player.firstname}</p>
        <p
          className={`font-bold text-2xl ${
            player.gender === "Male" ? "text-cyan-400" : "text-pink-400"
          }  `}
        >
          {player.gender === "Male" ? "M" : "F"}
        </p>
      </div>
    </div>
  );
};
export default TeamsAdmin;
