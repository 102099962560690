import React from "react";
import Title from "./component/Title";
import TeamGame from "./component/TeamGame";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import GlobalContext from "./GlobalContext";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";

function Game({ onData, prop }) {
  const { id } = useParams();
  const { globalURL } = useContext(GlobalContext);
  const url = globalURL;
  const [gameData, setGameData] = useState(null);
  const [date, setDate] = useState("");
  const [localTeamData, setLocalTeamData] = useState([]);
  const [guessTeamData, setGuessTeamData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  Axios.defaults.withCredentials = true;

  const fetchGameData = async () => {
    await Axios.get(url + "/games/" + id)
      .then((response) => {
        setGameData(response.data);

        const inputDate = new Date(response.data.date);

        const year = inputDate.getUTCFullYear();
        const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0");
        const day = String(inputDate.getUTCDate()).padStart(2, "0");

        setDate(`${day}-${month}-${year}`);
        fetchGuessTeam();
        fetchLocalTeam();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchGuessTeam = async () => {
    await Axios.get(url + "/playerGames/guessTeam/gameID/" + id)
      .then((response) => {
        setGuessTeamData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchLocalTeam = async () => {
    await Axios.get(url + "/playerGames/localTeam/gameID/" + id)
      .then((response) => {
        setLocalTeamData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchGameData();
  }, []);
  if (isLoading) {
    return (
      <div className="w-[100%] h-[100vh] flex justify-center items-center">
        <CircularProgress sx={{ color: "white" }} />
      </div>
    );
  } else {
    return (
      <div className="fade-in">
        <div>
          <div className="container">
            <div className="bgRed sm:w-[85%] lg:w-[60%] relative flex justify-center flex-col items-center p-4 border-black border-8 rounded-3xl my-8 sm-shadow">
              <Title word="Game" />
            </div>
            <div className="content my-8 justify-start items-center sm:w-[95%] lg:w-[80%] sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
              <div className="flex flex-col flex-wrap items-center">
                <Title word={gameData?.localTeamID.name}></Title>
                <div className="relative text-4xl font-extrabold stats-font p-2">
                  Vs
                </div>
                <Title word={gameData?.guessTeamID.name}></Title>
              </div>
              <div className="w-[60%] h-[14px] bg-white border-4 border-black rounded-md underLine-shadow mt-4 mb-2"></div>
              <div className="relative text-2xl font-extrabold font-Poppins ">
                {date}
              </div>
              <div className="mt-12 w-[100%]">
                <div className="text-center text-4xl font-extrabold stats-font mt-4">
                  Local
                </div>
                <div className="my-4">
                  <Title word={gameData?.localTeamID?.name}></Title>
                </div>
                <TeamGame
                  playerGames={localTeamData}
                  onData={onData}
                  prop={prop}
                />
              </div>

              <div className="mt-12  w-[100%]">
                <div className="text-center text-4xl font-extrabold stats-font mt-4">
                  Guest
                </div>
                <div className="my-4">
                  <Title word={gameData?.guessTeamID?.name}></Title>
                </div>
                <TeamGame
                  playerGames={guessTeamData}
                  onData={onData}
                  prop={prop}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Game;
