import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Checkbox, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  height: "80vh",
  fontFamily: "Poppins",
  paddingBottom: "100px",
};

function TermsAndConditions({ agreed }) {
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    !checked && setScrolledToBottom(false);
  };

  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const boxElement = document.getElementById("yourBoxId");
      if (boxElement) {
        const handleScroll = () => {
          // Calculate the scroll position relative to the box element
          const { scrollTop, scrollHeight, clientHeight } = boxElement;

          // Check if scrolled to the bottom (considering a small buffer)
          const isBottom = scrollTop + clientHeight >= scrollHeight - 30;
          if (isBottom) {
            setScrolledToBottom(true);
          }
        };

        // Attach the scroll event listener to the box element
        boxElement.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component is unmounted
        return () => {
          boxElement.removeEventListener("scroll", handleScroll);
        };
      }
    }, 500);
  }, [open]);

  useEffect(() => {}, [scrolledToBottom]);

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="contained"
        sx={{
          background: "white",
          color: "black",
          "&:hover": { backgroundColor: "#e0e0e0" },
        }}
        color="error"
        size="large"
        className="sm-shadow"
      >
        <FormControlLabel
          required
          control={<Checkbox sx={{ color: "black" }} checked={checked} />}
          label={
            <Typography style={{ fontFamily: "Poppins", fontWeight: 800 }}>
              Agree Terms and Conditions
            </Typography>
          }
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute text-black top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  h-[80vh] sm:w-[80vw] border-6 bg-white">
          <div
            id="yourBoxId"
            className=" sm:p-8 lg:p-24 select-none"
            // @ts-ignore
            style={style}
          >
            <h1 className="font-bold text-xl">Terms and Conditions</h1>

            <ul className=" sm:p-2 lg:p-6 pb-12 flex flex-col gap-4">
              <li>
                <h2 className="my-8 font-semibold">
                  RELEASE, INDEMNIFICATION, AUTHORIZATION, AND ALL-RISK
                  INSURANCE
                </h2>
                <p>
                  In consideration of my registration acceptance to the AW
                  leagues, I hereby release, discharge, and forever acquit, and
                  consent to indemnify and hold harmless the AW leagues, the
                  organizers of this league, their leaders, officials,
                  directors, agents, office employees, and respective
                  representatives (hereinafter referred to as "the released
                  parties") from all claims, legal actions, costs, expenses, and
                  demands whatsoever, whether for my death, injury, or damage to
                  my person or property, arising in any manner, or due to my
                  participation in this league, regardless of whether caused,
                  related to, or occasioned by negligence, breach of contract,
                  failure to fulfill responsibilities as an occupant of the
                  premises, or otherwise, by the released parties or any of
                  them. I consent to assume all risks, known and unknown, and
                  all consequences arising or related to my participation in
                  this event. I agree to abide by all rules, regulations, and
                  conditions of the AW leagues.
                </p>
              </li>
              <li>
                <h2>I certify that:</h2>
                <ul className=" sm:p-2 lg:p-6 pb-12 flex flex-col gap-4 list-decimal">
                  <li>
                    I am in good physical condition and not suffering from any
                    injury, illness, or disability, and I have not ingested or
                    injected any substance that may impair my performance or
                    physical condition or increase the likelihood of injury
                    while participating in this league.
                  </li>
                  <li>
                    No doctor, nurse, therapist, instructor, coach, manager, or
                    other person has advised against my participation in a sport
                    involving physical contact or in this league.
                  </li>
                  <li>
                    I am familiar with basketball and the nature of basketball
                    games. I am aware that there are risks of injury due to the
                    inherent nature of the sport. I, along with my heirs,
                    executors, trustees, assignees, and personal
                    representatives, will be bound by this document.
                  </li>
                  <li>
                    I have read and understood the rules and regulations of the
                    AW leagues.
                  </li>
                  <li>
                    I willingly agree to participate in the AW leagues knowing
                    the risks involved.
                  </li>
                  <li>
                    I have read this document, and I understand that by checking
                    the "I agree" box, I waive significant rights. Therefore,
                    with full knowledge, I check the "I agree" box of this
                    document.{" "}
                  </li>
                  <li>I agree that membership fees are non-refundable.</li>
                  <li>
                    I agree to be added to the league's email distribution list.
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="my-8 font-semibold">
                  RELEASE FOR THE USE OF PHOTOS/VIDEOS/COMMENTS TAKEN DURING AW
                  LEAGUE ACTIVITIES
                </h2>
                <p>
                  I hereby authorize AW leagues to use my name, photos of me,
                  videos of me, and/or any statements related to AW leagues for
                  promotional purposes without additional compensation. These
                  photos/videos or comments may be used for promotion during
                  various events (website, Facebook page, and other media and/or
                  mediums). AW leagues commit not to use this information and/or
                  images for commercial purposes that would require
                  compensation. If such use is contemplated, AW leagues will
                  inform me of the intended usage of the information and/or
                  images.
                </p>
              </li>
            </ul>
          </div>
          <div className="fixed bottom-0 bg-white w-full sm:py-4 lg:py-8 border-black border-2 flex justify-center items-center">
            <img
              className="absolute fade-in
               sm:w-14 lg:w-24 translate-y-[-110%] right-0 top-0 p-2 z-50 xs-shadow"
              src={`${process.env.PUBLIC_URL}/assets/img/AW_allblack.png`}
              alt="AW"
            />
            <FormControlLabel
              disabled={!scrolledToBottom && !checked}
              required
              control={
                <Checkbox
                  onClick={(e) => {
                    setChecked(!checked);
                    agreed(e.target.checked);
                    handleClose();
                  }}
                  checked={checked}
                  sx={{ color: "black" }}
                />
              }
              label={
                <Typography style={{ fontFamily: "Poppins", fontWeight: 800 }}>
                  {!scrolledToBottom && !checked
                    ? "Scroll down to agree"
                    : "I agree"}
                </Typography>
              }
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TermsAndConditions;
